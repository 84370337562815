import buildEnv from '../env.json';
import MeetingRoomOne from './Assets/Rooms/MeetingRoom1.jpg';
import AttendeeGuide from './Assets/Assets/VirtualEnvironment-AttendeeUserGuide.pdf';
import moment from 'moment';
// Unprotected Data

const colors = {
  // set default colors for proper render before initial event is set in redux
  main: '#122857',
  light: '#3b60ad',
  chatAccent: '#122857',
  iconGray: '#757575',
  disabledGray: '#e0e0e0',
  disbledText: '#a6a6a6',
  backgroundText: '#5A5A5A',
  backgroundGray: '#F2F2F2',
};

// Configure Default Fallbacks
const faviconLocation =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/pci-icon.png'; // set fallback favicon

const landingPage = {
  // set fallback landing page content
  banner: 'https://pci-admin-kfc-portal.s3.amazonaws.com/Demo_Banner_v1a.png',
  content:
    'This is the Virtual Environment for the PlanetConnect Demo Environment!',
};

const title = 'Demo'; // fallback webpage title

const posterSessionTopicImages = {
  Default: 'https://pci-site-assets.s3.amazonaws.com/DemoEvent/Default.jpg', // set fallback poster card image
};

// Configure Lobby Image

const lobbyImageMap = {
  name: 'my-map',
  areas: [
    {
      name: 'welcomeVideo',
      formalName: 'Welcome Video',
      shape: 'poly',
      coords: [
        12,
        658, //top left
        418,
        650, //top right
        420,
        836, //bottom right
        12,
        862, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
    },
    {
      name: 'agenda',
      formalName: 'Agenda',
      shape: 'poly',
      coords: [
        432,
        585, //top left
        584,
        594, //top right
        580,
        714, //bottom right
        428,
        714, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/agenda',
    },
    {
      name: 'discussionforum',
      formalName: 'Networking',
      shape: 'poly',
      coords: [
        596,
        595, //top left
        726,
        604, //top right
        724,
        714, //bottom right
        592,
        714, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/discussionforum',
    },
    {
      name: 'posterhall',
      formalName: 'Poster Hall',
      shape: 'poly',
      coords: [
        736,
        602, //top left
        844,
        610, //top right
        844,
        714, //bottom right
        734,
        714, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/posterhall',
    },
    {
      name: 'exhibithall',
      formalName: 'Exhibit Hall',
      shape: 'poly',
      coords: [
        1100,
        602, //top left
        1218,
        590, //top right
        1218,
        722, //bottom right
        1100,
        718, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/exhibithall',
    },
    {
      name: 'auditorium',
      formalName: 'Conference Center',
      shape: 'poly',
      coords: [
        1230,
        586, //top left
        1384,
        572, //top right
        1384,
        728, //bottom right
        1230,
        724, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/auditorium',
    },
    // {
    //   name: 'caricatures',
    //   formalName: 'Caricatures',
    //   shape: 'poly',
    //   coords: [
    //     1398,
    //     570, //top left
    //     1604,
    //     552, //top right
    //     1610,
    //     738, //bottom right
    //     1398,
    //     732, //bottom left
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    //   // href: '/scienceasart',
    // },
    {
      name: 'mmiposterhall',
      formalName: 'Merck Missions Imaging Poster Hall',
      shape: 'poly',
      coords: [
        1464,
        1026, //top left
        1800,
        1026, //top right
        1800,
        1120, //bottom right
        1464,
        1120, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      // href: '/scienceasart',
    },
    // {
    //   name: 'archivesactivities',
    //   formalName: 'Archives Activities',
    //   shape: 'poly',
    //   coords: [
    //     1630,
    //     550, //top left
    //     1924,
    //     524, //top right
    //     1930,
    //     622, //bottom right
    //     1632,
    //     634, //bottom left
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    //   href: '/archivesactivities',
    // },
    // {
    //   name: 'caricatures',
    //   formalName: 'Caricatures',
    //   shape: 'poly',
    //   coords: [
    //     1634,
    //     648, //top left
    //     1928,
    //     636, //top right
    //     1932,
    //     738, //bottom right
    //     1636,
    //     732, //bottom left
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    //   href: '/caricatures',
    // },
    {
      name: 'signature',
      formalName: 'Signature',
      shape: 'poly',
      coords: [
        1375,
        270, //top left
        1705,
        155, //top right
        1715,
        310, //bottom right
        1380,
        390, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/signature',
    },
  ],
};

const lobbyImage =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/MTS2022/Lobby-MTS-2022-V4.jpg'; // location source of lobby image

const aiEnabled = true;

// Configure Custom Signature Sponsor Exhibitor

const signatureSponsorId = '1691992000108210127'; //id used when user navigates when clicking on signature sponsor banner lobby image
const signatureSponsorName = 'PerkinElmer'; //name used when user navigates when clicking on signature sponsor banner lobby image

const signatureSponsorLogo =
  'https://pci-site-assets.s3.amazonaws.com/ExhibitorLogos/PerkinElmer.png'; // location source of signature logo
const signatureSponsorUrl = 'exhibithall/?exhibition_id=1691992000108210127'; // Used to set where user navigates when clicking on signature sponsor banner lobby image

const perkinBoothMTS2021 =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/PerkinBooth-MTS2021.png'; // location source of signature sponsor booth background

// True Configuration
const eventId = import.meta.env.VITE_EVENT_ID; // Sets the event id of the event
const accountId = import.meta.env.VITE_ACCOUNT_ID; // Sets the account id of the event

// Twilio Config

// Default Conversations Service
// const conversationsServiceSid=IS63492420ac934168a8e313a8cc1e61a8

const landingpageOnly = false;

const MerckSSOEnabled = true;
const OktaSSOEnabled = false;

const demoEnabled = false;
const conversationsServiceSid = 'IS416efd39a4024a56ad7499ae7c09c4af'; // MTS22
// Default Sync Service
const syncServiceSid = 'IS7f4d349a954efba84a9243c1a9e269d6'; // MTS22

const allowedRegistrationDomains = ['@merck.', '@planetconnect.com', '@msd.']; // comma separated array of substrings of emails allowed to register for the event as the accountId account
const allowedSigninDomains = ['@']; // comma separated array of substrings of emails allowed to enter the event

const endpoint =
  import.meta.env.VITE_BACKEND_ENDPOINT ||
  'https://core-api.planetconnect.com/';

// eslint-disable-next-line no-console
console.log('Backend URL:', endpoint);

const registrationPage = 'https://planetconnect.com/'; // depricated -  used as the place where users could go to register when authorized but not registered

const drawerWidth = 240; // left side open navigation bar width
const rightDrawerWidth = 340; // right side chat width

const fallbackImages = {
  // fallback images for exhibitor and agenda cards
  exhibitCard: MeetingRoomOne,
  agendaCard: MeetingRoomOne,
};

const attendeeGuide = AttendeeGuide;
// const sponsorBanner = SponsorBanner

const DEFAULT_CACHE_TTL = import.meta.env.VITE_DEFAULT_CACHE_TTL
  ? // VITE_DEFAULT_CACHE_TTL was sent
    typeof import.meta.env.VITE_DEFAULT_CACHE_TTL === 'string'
    ? // it's a string, so parse to a number
      parseInt(import.meta.env.VITE_DEFAULT_CACHE_TTL, 10)
    : // it's a number, use as is
      import.meta.env.VITE_DEFAULT_CACHE_TTL
  : // no value sent, use one hour
    60 * 60;

// Change this to a date in the future or now to force a cache clear
export const CLEAR_CACHE_AFTER = moment('2022-09-12T00:20:00.000Z').valueOf();

const COMMIT_DATE = buildEnv.COMMIT_DATE;
const BUILD_DATE = buildEnv.BUILD_DATE;
const COMMIT = buildEnv.COMMIT;
const REF = buildEnv.REF;

// eslint-disable-next-line no-console
console.log(`Commit Date: ${COMMIT_DATE}`);
// eslint-disable-next-line no-console
console.log(`Build Date: ${BUILD_DATE}`);
// eslint-disable-next-line no-console
console.log(`Commit Sha: ${COMMIT}`);
// eslint-disable-next-line no-console
console.log(`Build Ref: ${REF}`);
// eslint-disable-next-line no-console
console.log(`Cache Time (Seconds): ${DEFAULT_CACHE_TTL}`);

export {
  // fallback protection
  posterSessionTopicImages, //used as fallback
  fallbackImages, // used as fallback
  landingPage, //used as fallback
  title, //used as fallback
  colors, //used as fallback
  faviconLocation, //used as fallback
  // true configuration
  endpoint,
  drawerWidth,
  rightDrawerWidth,
  lobbyImageMap,
  lobbyImage,
  eventId,
  accountId,
  conversationsServiceSid,
  syncServiceSid,
  signatureSponsorLogo,
  signatureSponsorUrl,
  signatureSponsorId,
  signatureSponsorName,
  // staged for remote configuration
  registrationPage,
  attendeeGuide,
  allowedRegistrationDomains,
  allowedSigninDomains,
  // perkinSpecific
  perkinBoothMTS2021,
  // SSO Attributes
  MerckSSOEnabled,
  OktaSSOEnabled,
  demoEnabled,
  // landing page specific
  landingpageOnly,
  DEFAULT_CACHE_TTL,
  COMMIT_DATE,
  BUILD_DATE,
  COMMIT,
  REF,
  aiEnabled,
};
