import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import ExhibitBoothImage1 from '../../Components/Images/ExhibitBoothImage1-PerkinElmer';
import ExhibitBoothImage2 from '../../Components/Images/ExhibitBoothImage2';
import ExhibitBoothImage3 from '../../Components/Images/ExhibitBoothImage3';
import ExhibitBoothImage4 from '../../Components/Images/ExhibitBoothImage4';
import ExhibitBoothImage5 from '../../Components/Images/ExhibitBoothImage5';
import GroupConversation from '../../Components/GroupConversation';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExhibitProductItem from './ExhibitProductItem';
// import ExhibitResourceItem from './ExhibitResourceItem';
import Container from '@material-ui/core/Container';
import trackAction from '../../Store/Actions/tracker';
import BoothChat from './BoothChat';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
// import BoothStaff from './BoothStaff';
import BoothAbstracts from './BoothAbstracts';
// import PerkinElmerResources from './PerkinElmerResources';
import getValidUrl from '../../Utilities/getValidUrl';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { eventId } from '../../config';
import getTwilioGroupConversationUniqueName from '../../Utilities/getTwilioGroupConversationUniqueName';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

import { getExhibits, setSelectedExhibit } from '../../Store/Reducers/exhibit';
import { queryApi } from '../../Services/queryApi';

import { fallbackImages } from '../../config';
import isOnline from '../../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = (theme) => ({
  header: {
    margin: 'auto',
  },
  image: {
    maxHeight: '80vh',
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    borderRadius: '0.5%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mainPictureImage: {
    maxWidth: '100%',
  },
  gridContainer: {
    // marginTop: theme.spacing(3),
    scrollBehavior: 'smooth',
  },
  sash: {
    marginTop: '-60px',
    marginRight: '-70px',
    width: '300px',
    position: 'sticky',
    top: '50px',
    left: 'auto',
    textAlign: 'center',
    lineHeight: '2.5em',
    color: 'white',
    transform: 'rotate(45deg)',
    webkitTransform: 'rotate(45deg)',
    boxShadow: '0 0 3px rgba(0,0,0,.3)',
    textShadow: '0px 5px 20px #000000CC',
    float: 'right',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  tabSingle: {
    minWidth: 'auto',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  mobileTabButton: {
    width: '100%',
    // minHeight: '3rem',
    // backgroundColor: 'red',
  },
  mobileTabButtonSelected: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

class ExhibitionBooth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      perkinTabValue: 0,
      perkinProductId: null,
      visual: true,
      exhibit: null,
      products: [],
      boothStaff: [],
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleTabChangeImage = (event, newValue) => {
    this.setState({ tabValue: newValue + 1 });
  };

  handleTabChangePerkinElmer = (event, newValue, perkinNewValue, productId) => {
    this.setState({
      tabValue: newValue,
      perkinTabValue: perkinNewValue,
      perkinProductId: productId,
    });
  };

  recordEvent = (data) => {
    const event = {
      action: 'Clicked',
      resource_type: 'Exhibit',
      resource_id: data.exhibition_id,
      url_visited: '/exhibithall',
      resource_account_id: data.account_id,
      resource_account_name: data.account_alias,
      resource_name: data.account_alias ? data.account_alias : data.title,
    };

    this.props.trackAction(event);
  };

  async componentDidMount() {
    try {
      this.props.getExhibit();
      this.props.getBoothStaff();
      this.props.getProducts();

      this.recordEvent(this.props.exhibit?.data?.[0]);
    } catch (error) {
      this.setState({
        exhibit: null,
      });
      if (this.props.debug.debug) {
        alert(`Error: Could not display Exhibit Booth. ${error}`);
      }
    }
  }

  handleVisualToggle = () => {
    this.setState({ visual: !this.state.visual });
  };

  briefcaseSelect(exhibit) {
    this.setState({
      tabValue: this.state.tabValue,
    });
    if (this.props.online) {
      let favorited = false;
      if (this.props.briefcase.data) {
        for (let n = 0; n < this.props.briefcase.data.length; n++) {
          if (
            this.props.briefcase.data[n].resource_id === exhibit.exhibition_id
          ) {
            favorited = true;
            break;
          }
        }
      }

      if (favorited) {
        this.props.deleteBriefcaseElement(exhibit.exhibition_id);
      } else {
        this.props.addBriefcaseElement(
          'Exhibit',
          exhibit.exhibition_id,
          exhibit.account_alias,
          exhibit.account_id,
          this.props.user.user.account_id
        );
      }
    }
  }

  render() {
    console.log('Render Exhibit Booth');
    const { classes } = this.props;
    const exhibit = this.props.exhibit?.data?.[0];
    const boothStaff = this.props.boothStaff?.data?.data;
    const products = this.props.products?.data?.data;
    const online = this.props.online;

    if (
      exhibit === undefined ||
      products === undefined ||
      boothStaff === undefined
    ) {
      return <CircularProgress color="inherit" />;
    }

    let meetMeButton;

    const boothStaffPresent = boothStaff?.filter(
      (e) => e.email === this.props.user.user.email
    );

    // TODO: add  meet me track action
    if (boothStaffPresent?.length && exhibit.meeting_room_host_link) {
      meetMeButton = (
        <>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            disableElevation
            target="_blank"
            href={getValidUrl(exhibit.meeting_room_host_link)}
            style={{ marginTop: '16px', marginBottom: '16px' }}
            onClick={() => {
              const event = {
                action: 'Clicked',
                resource_type: 'Meet Me / Exhibit',
                resource_id: exhibit.exhibition_id,
                url_visited: '/exhibithall',
                resource_account_id: exhibit.account_id,
                resource_account_name: exhibit.name,
                resource_name: exhibit.account_alias
                  ? exhibit.account_alias
                  : exhibit.title,
              };

              this.props.trackAction(event);
            }}
          >
            Meet Me
          </Button>
          <br />
        </>
      );
    } else if (exhibit.meeting_room) {
      meetMeButton = (
        <>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            disableElevation
            target="_blank"
            href={getValidUrl(exhibit.meeting_room)}
            style={{ marginTop: '16px', marginBottom: '16px' }}
            onClick={() => {
              const event = {
                action: 'Clicked',
                resource_type: 'Meet Me / Exhibit',
                resource_id: exhibit.exhibition_id,
                url_visited: '/exhibithall',
                resource_account_id: exhibit.account_id,
                resource_account_name: exhibit.name,
                resource_name: exhibit.account_alias
                  ? exhibit.account_alias
                  : exhibit.title,
              };

              this.props.trackAction(event);
            }}
          >
            Meet Me
          </Button>
          <br />
        </>
      );
    }

    let exhibitBoothContent;
    switch (this.state.tabValue) {
      case 1: // overview
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Container>
              <Typography
                color="textPrimary"
                variant="h4"
                gutterBottom
                align="center"
              >
                {exhibit.account_alias}
              </Typography>
              <Button
                variant="contained"
                size="large"
                disableElevation
                target="_blank"
                href={getValidUrl(exhibit.url)}
                style={{ marginTop: '16px', marginBottom: '16px' }}
              >
                {
                  exhibit.url ? exhibit.url : exhibit.account.website //no account in exhibit
                }
              </Button>
              <br />
              {meetMeButton}
              <pre
                style={{
                  maxWidth: '70vw',
                  whiteSpace: 'pre-line',
                  wordWrap: 'break-word',
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                  gutterBottom
                  align="justify"
                >
                  {exhibit.main_text}
                </Typography>
              </pre>
              <div className={classes.chips}>
                {exhibit.linked_in_tags ? (
                  exhibit.linked_in_tags.split(',').map((tag, index) => {
                    let characterCount = 0;
                    let splitTag = tag.split(' ');
                    let midifiedTag = '';
                    let characterLimit = 36;
                    if (tag.length > 30) {
                      splitTag.map((string) => {
                        characterCount = characterCount + string.length;

                        if (characterCount <= characterLimit) {
                          midifiedTag = midifiedTag + ' ' + string;
                        }

                        return string;
                      });

                      if (characterCount > characterLimit) {
                        midifiedTag = midifiedTag + '...';
                      }
                    } else {
                      midifiedTag = tag;
                    }
                    return (
                      <React.Fragment key={index}>
                        <Hidden xsDown>
                          <Chip key={index} label={tag} color="primary" />
                        </Hidden>
                        <Hidden smUp>
                          <Chip
                            key={index}
                            label={midifiedTag}
                            color="primary"
                          />
                        </Hidden>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </Container>
          </Grid>
        );
        break;
      case 2: // products & services
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom align="center">
              {products?.map((product, index) => {
                return (
                  <ExhibitProductItem
                    key={index}
                    product={product}
                    perkinProductId={this.state.perkinProductId}
                  />
                );
              })}
            </Typography>
          </Grid>
        );
        break;
      case 3: // event activities
        exhibitBoothContent = (
          <Grid item xs={12}>
            <BoothAbstracts exhibit={exhibit} />
          </Grid>
        );
        break;
      default:
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom align="center">
              {exhibit.title}
            </Typography>
          </Grid>
        );
        break;
    }

    let favorited = false;
    if (this.props.briefcase.data) {
      for (let n = 0; n < this.props.briefcase.data.length; n++) {
        if (
          this.props.briefcase.data[n].resource_id === exhibit.exhibition_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let tabRow = (
      <>
        <Hidden xsDown>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tooltip
              title={
                !online && (
                  <Typography variant="subtitle2">
                    User is Offline. Cannot perform action.
                  </Typography>
                )
              }
            >
              {favorited ? (
                <Tab
                  className={classes.tabSingle}
                  onClick={() => {
                    this.briefcaseSelect(exhibit);
                  }}
                  icon={<WorkIcon />}
                />
              ) : (
                <Tab
                  className={classes.tabSingle}
                  onClick={() => {
                    this.briefcaseSelect(exhibit);
                  }}
                  icon={<WorkOutlineIcon />}
                />
              )}
            </Tooltip>
            <Tab className={classes.tabSingle} label={'Overview'} />
            <Tab className={classes.tabSingle} label={'Products & Services'} />
            <Tab className={classes.tabSingle} label={'Event Activities'} />
          </Tabs>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Tooltip
                title={
                  !online && (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
              >
                <Button
                  className={classes.mobileTabButton}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    this.briefcaseSelect(exhibit);
                  }}
                >
                  {favorited ? <WorkIcon /> : <WorkOutlineIcon />}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={this.state.tabValue === 1 ? 'primary' : 'default'}
                className={classes.mobileTabButton}
                variant="contained"
                disableElevation
                onClick={() => this.handleTabChange(null, 1)}
              >
                Overview
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={this.state.tabValue === 2 ? 'primary' : 'default'}
                className={classes.mobileTabButton}
                variant="contained"
                disableElevation
                onClick={() => this.handleTabChange(null, 2)}
              >
                Products &<br /> Services
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={this.state.tabValue === 3 ? 'primary' : 'default'}
                className={classes.mobileTabButton}
                variant="contained"
                disableElevation
                onClick={() => this.handleTabChange(null, 3)}
              >
                Event <br />
                Activities
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );

    let boothHeader = (
      <React.Fragment>
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid container item xs={12} sm={8} justify="center">
          {tabRow}
        </Grid>
        <Hidden xsDown>
          <Grid item xs={2} align="right">
            <Button onClick={this.handleVisualToggle}>
              Display Booth
              <Switch
                checked={this.state.visual}
                onChange={this.handleVisualToggle}
                color="primary"
                name="Visual"
                inputProps={{ 'aria-label': 'Visual' }}
              />
            </Button>
          </Grid>
        </Hidden>
      </React.Fragment>
    );
    let level;
    let color;
    let lightColor;
    switch (exhibit.booth_type) {
      case '1':
        color = '#0600b1';
        lightColor = '#554fff';
        level = 'Signature Sponsor';
        break;
      case '2':
        color = '#996ae6';
        lightColor = '#c2a7f0';
        level = 'Platinum Sponsor';
        break;
      case '3':
        color = '#ff9a0d';
        lightColor = '#fcb044';
        level = 'Gold Sponsor';
        break;
      case '4':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Silver Sponsor';
        break;
      case '7':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Media Partner';
        break;
      case '8':
        color = '#f0a82b';
        lightColor = '#edb85c';
        level = 'Event Manager';
        break;
      case '9':
        color = '#990000';
        lightColor = '#ab2b2b';
        level = 'University';
        break;
      case '10':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Information';
        break;
      case '11':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Host';
        break;
      default:
        color = '#ffffff00';
        lightColor = '#ffffff00';
        level = '';
        break;
    }

    let sash;
    if (
      exhibit.type === 'Internal' ||
      exhibit.type === 'Digital Lab' ||
      exhibit.name === 'PlanetConnect, Event Manager'
    ) {
      sash = null;
    } else {
      sash = (
        <Typography
          variant="h5"
          className={classes.sash}
          color="textPrimary"
          style={{
            backgroundImage: `linear-gradient(${color}, ${lightColor})`,
          }}
        >
          {level}
        </Typography>
      );
    }

    let ExhibitBoothImageContainer;
    let booth_type = parseInt(exhibit.booth_type);
    switch (booth_type) {
      case 1:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            {products && (
              <ExhibitBoothImage1
                handleTabChange={this.handleTabChangeImage.bind(this)}
                handleTabChangePerkinElmer={this.handleTabChangePerkinElmer.bind(
                  this
                )}
                exhibit={exhibit}
                products={products}
                tabValue={this.state.tabValue}
                perkinTabValue={this.state.perkinTabValue}
              />
            )}
          </div>
        );
        break;
      case 2:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage2
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              products={products}
            />
          </div>
        );
        break;
      case 3:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage3
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              products={products}
            />
          </div>
        );
        break;
      case 4:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage4
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              products={products}
            />
          </div>
        );
        break;
      case 8:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage2
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              products={products}
            />
          </div>
        );
        break;
      case 9:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage3
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              products={products}
            />
          </div>
        );
        break;
      default:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            <ExhibitBoothImage5
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={exhibit}
              tabValue={this.state.tabValue}
              style={{ padding: '200px' }}
              products={products}
            />
          </div>
        );
        break;
    }

    let boothLayout;

    const uniqueName = getTwilioGroupConversationUniqueName(exhibit);
    const friendlyName = exhibit.account_alias || exhibit.account_name;
    const logo = exhibit.logo_address
      ? exhibit.logo_address
      : fallbackImages.exhibitCard;

    let chatBox = (
      <GroupConversation
        type="exhibit"
        eventId={eventId}
        exhibitionId={exhibit.exhibition_id}
        friendlyName={friendlyName}
        uniqueName={uniqueName}
        logo={logo}
      />
    );
    // chatBox = (
    //   <Grid item xs={12} lg={12} align="center">
    //     <div className={classes.paper} id={booth_type <= 2 ? 'goTo' : ''}>
    //       <BoothChat
    //         exhibit={exhibit}
    //         channel={exhibit.exhibition_id}
    //       />
    //     </div>
    //   </Grid>
    // );

    if (this.state.visual) {
      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={booth_type > 2 ? 6 : 12} align="center">
            <Hidden xsDown>
              <div className={classes.paper}>{ExhibitBoothImageContainer}</div>
            </Hidden>
            {booth_type > 2 ? (
              <div className={classes.paper} style={{ marginTop: '16px' }}>
                {exhibitBoothContent}
              </div>
            ) : (
              <React.Fragment />
            )}
          </Grid>
          {booth_type <= 2 ? (
            <React.Fragment>
              <Hidden xsDown>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  justify="center"
                  style={{ textAlign: 'center' }}
                >
                  {tabRow}
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <div className={classes.paper}>{exhibitBoothContent}</div>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12} md={6} align="center">
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    } else {
      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={6}>
            <div className={classes.paper}>{exhibitBoothContent}</div>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        {boothHeader}
        {boothLayout}
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.user,
    debug: state.debug,
    online: isOnline(state),
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    allExhibits: getExhibits(state),
    // exhibit: queryApi.endpoints.getExhibit.select({
    //   eventId,
    //   boothId: props.boothId,
    // })(state),
    exhibit:
      state.queryApi.queries?.[
        `getExhibit({"boothId":"${props.boothId}","eventId":"${eventId}"})`
      ],
    // boothStaff: queryApi.endpoints.getBoothStaff.select({
    //   eventId,
    //   boothId: props.boothId,
    // })(state),
    boothStaff:
      state.queryApi.queries?.[
        `getBoothStaff({"boothId":"${props.boothId}","eventId":"${eventId}"})`
      ],
    // products: queryApi.endpoints.getProducts.select({
    //   eventId,
    //   boothId: props.boothId,
    // })(state),
    products:
      state.queryApi.queries?.[
        `getProducts({"boothId":"${props.boothId}","eventId":"${eventId}"})`
      ],
    // agendas: state.queryApi.queries?.[`getProducts({"eventId":"${eventId}"})`],
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getBriefcase: () => queryApi.endpoints.getBriefcase.initiate(eventId),
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      getExhibit: () =>
        queryApi.endpoints.getExhibit.initiate({
          eventId: eventId,
          boothId: props.boothId,
        }),
      getBoothStaff: () =>
        queryApi.endpoints.getBoothStaff.initiate({
          eventId: eventId,
          boothId: props.boothId,
        }),
      getProducts: () =>
        queryApi.endpoints.getProducts.initiate({
          eventId: eventId,
          boothId: props.boothId,
        }),
      trackAction: trackAction,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ExhibitionBooth));
