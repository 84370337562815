import React, { useEffect } from 'react';
import twilioInitialize from '../Store/Actions/twilioInitialize';
import { getInitilized } from '../Store/Reducers/twilio';
import { useDispatch, useSelector } from 'react-redux';
import Router from '../router';
import { getUser } from '../Store/Reducers/user';
import startupAsync from '../Thunks/StartupAsync';
import { useGetEventQuery } from '../Services/queryApi';
import { eventId } from '../config';
import { useAppDispatch } from '../Store/store';
import TaskQueueSubmit from '../Components/TaskQueueSubmit';

export default function RootScreen() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const { data: event } = useGetEventQuery(eventId);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startupAsync(event));
        if (user !== 'unregistered') {
          await dispatch(twilioInitialize(user));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [user]);

  return (
    <>
      <Router />
      <TaskQueueSubmit />
    </>
  );
}
