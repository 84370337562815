import axios from 'axios';
import { Auth } from 'aws-amplify';
import { conversationsServiceSid } from '../../config';
import { getConversationsClient } from '../Reducers/twilio';

export default function twilioUnBlockUser(identity) {
  return async (dispatch, getState) => {
    console.log('unblocking user');
    const user = getState().user.user;
    const userTwilio = getState().twilio.user?.client;
    const client = getConversationsClient(getState());

    // let newBlockList = userTwilio.attributes?.blockList
    //   ? { ...userTwilio.attributes.blockList }
    //   : {};
    let newBlockList = userTwilio.attributes?.blockList
      ? { ...userTwilio.attributes.blockList }
      : {};
    // newBlockList = {};

    if (newBlockList[identity]) {
      const conversationSid = newBlockList[identity];
      console.log(
        '🚀 ~ file: twilioUnBlockUser.js ~ line 33 ~ return ~ conversationSid',
        conversationSid
      );
      delete newBlockList[identity];

      const newAttributes = {
        ...userTwilio.attributes,
        blockList: newBlockList,
      };
      await userTwilio.updateAttributes(newAttributes);

      // console.log(
      //   '🚀 ~ file: twilioUnBlockUser.js ~ line 40 ~ return ~ newConversation',
      //   newConversation
      // );
      // try {
      //   await newConversation.join();
      // } catch (err) {
      //   console.log(
      //     '🚀 ~ file: twilioUnBlockUser.js ~ line 43 ~ return ~ err',
      //     err
      //   );
      // }

      // Ask the backend to join the conversation
      const data = await Auth.currentSession();
      const response = await axios({
        method: 'post',
        url: `/twilio/join`,
        headers: { idtoken: data.idToken.jwtToken },
        data: {
          conversationsServiceSid,
          friendlyName: conversationSid,
          uniqueName: conversationSid,
        },
      });
      console.log(`joined ${response.data.sid} via backend`);

      const newConversation = await client.getConversationBySid(
        conversationSid
      );
      console.log(
        '🚀 ~ file: twilioUnBlockUser.js ~ line 45 ~ return ~ newConversation.sid',
        newConversation.sid
      );

      // console.log(
      //   '🚀 ~ file: twilioUnBlockUser.js ~ line 79 ~ return ~ response',
      //   response
      // );

      // dispatch(setOpenProfile(null));
      // dispatch(setEngagement('chat'));
      // dispatch(
      //   setOpenConversation(storeConversation(newConversation, userTwilio))
      // );
      console.log('twilio user ', userTwilio.attributes);
    }
    // else {
    //   newBlockList = newBlockList.filter(
    //     (identity) => identity !== otherParticipant.identity
    //   );
    // }
  };
}
