import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, TextField, Paper, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NavBar from '../../Components/Navbar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';
import Privacy from '../Privacy';
import DialogWrapper from '../../Components/DialogWrapper';
import {
  landingPage,
  eventId,
  accountId,
  allowedRegistrationDomains,
  allowedSigninDomains,
  MerckSSOEnabled,
  OktaSSOEnabled,
  landingpageOnly,
} from '../../config';
import {
  Link,
  // Redirect,
  withRouter,
} from 'react-router-dom';
import trackAction from '../../Store/Actions/tracker';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuthenticatedUser from '../../Store/Actions/setUser';
import setBriefcase from '../../Store/Actions/setBriefcase';
import setEventDetails from '../../Store/Actions/setEventDetails';

const countryList = [
  'United States',
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, The Democratic Republic of The',
  'Cook Islands',
  'Costa Rica',
  "Cote D'ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and The Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and The South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  gridBody: {
    marginTop: '60px',
  },
  bannerImage: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    position: 'relative',
    padding: 0,
    margin: 0,
    top: '-4px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '80px',
  },
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '60%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  toggleButton: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  acceptButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  privacyButton: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class SignUpForm extends Component {
  constructor(props) {
    // Hub.listen('auth', (data) => {
    //     const { payload } = data;
    //     // this.onAuthEvent(payload);
    //     // console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);

    //     // this.setState({
    //     //     ssoSignedIn: true
    //     // })

    //     // window.location.reload();
    //     // this.props.history.push(`/lobby`);

    // })
    super(props);
    this.state = {
      ssoError: false,
      ssoSignedIn: false,
      ssoEmailChecked: false,
      code: null,
      // global state
      formSubmitted: false,
      globalError: false,
      submissionSuccess: false,
      submissionFailure: false,
      mustConfirmEmail: false,
      justSignedUp: false,
      federatedId: false,
      emailChecked: false,
      existingZohoRegistrant: false,
      existingCognitoUser: false,

      // registration fields
      registrationFields: [],

      // login
      newPasswordRequired: false,
      newPassword: '',

      // password
      password: '',
      passwordError: null,
      passwordErrorMessage: '',

      // privacy
      privacySelected: false,
      privacyOpen: false,
      privacyAgree: false,

      // first name
      firstName: '',
      firstNameError: null,
      firstNameErrorMessage: '',

      // last name
      lastName: '',
      lastNameError: null,
      lastNameErrorMessage: '',

      // email
      email: '',
      emailError: null,
      emailErrorMessage: '',

      // department
      department: '',
      departmentError: null,
      departmentErrorMessage: '',

      // job title
      jobTitle: '',
      jobTitleError: null,
      jobTitleErrorMessage: '',

      // company
      company: '',
      companyError: null,
      companyErrorMessage: '',

      // site
      site: '',
      siteError: null,
      siteErrorMessage: '',

      // phone
      phone: '',
      phoneError: null,
      phoneErrorMessage: '',

      // business address
      businessAddress: '',
      businessAddressError: null,
      businessAddressErrorMessage: '',

      // city
      city: '',
      cityError: null,
      cityErrorMessage: '',

      // state
      state: '',
      stateError: null,
      stateErrorMessage: '',

      // postal code
      postalCode: '',
      postalCodeError: null,
      postalCodeErrorMessage: '',

      // country
      country: '',
      countryError: null,
      countryErrorMessage: '',

      // custom1
      custom1: '',
      custom1Error: null,
      custom1ErrorMessage: '',

      // custom2
      custom2: '',
      custom2Error: null,
      custom2ErrorMessage: '',

      // custom3
      custom3: '',
      custom3Error: null,
      custom3ErrorMessage: '',

      // custom4
      custom4: '',
      custom4Error: null,
      custom4ErrorMessage: '',

      // custom5
      custom5: '',
      custom5Error: null,
      custom5ErrorMessage: '',

      // custom6
      custom6: '',
      custom6Error: null,
      custom6ErrorMessage: '',

      // question1
      question1: '',
      question1Error: null,
      question1ErrorMessage: '',

      // question2
      question2: '',
      question2Error: null,
      question2ErrorMessage: '',

      // question3
      question3: '',
      question3Error: null,
      question3ErrorMessage: '',

      // question4
      question4: '',
      question4Error: null,
      question4ErrorMessage: '',

      // find out
      findOut: '',
      findOutError: null,
      findOutErrorMessage: '',

      // get out
      getOut: '',
      getOutError: null,
      getOutErrorMessage: '',
    };
  }

  async componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'error') {
          this.setState({
            ssoError: true,
          });
        }
      }
    }

    let user = await Auth.currentSession();

    if (user) {
      await this.ssoCheckRegistrationStatus(user.idToken.payload.email);

      //  await this.trackSignIn()
      //  await this.props.setEventDetails()
      //  await this.props.setAuthenticatedUser()
      //  await this.props.setBriefcase()

      // //  window.open('/lobby')
    }
  }

  trackSignIn() {
    const event = {
      action: 'Entered',
      resource_type: 'VirtualEnvironment',
      resource_id: null,
      url_visited: '/registration',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleZohoRegistration = async () => {
    // check to see the user entered everything required

    this.setState({
      privacyAgree: false,
      globalError: false,
      emailError: null,
      emailErrorMessage: '',
      firstNameError: null,
      firstNameErrorMessage: '',
      lastNameError: null,
      lastNameErrorMessage: '',
      jobTitleError: null,
      jobTitleErrorMessage: '',
      companyError: null,
      companyErrorMessage: '',
      departmentError: null,
      departmentErrorMessage: '',
      siteError: null,
      siteErrorMessage: '',
      businessAddressError: null,
      businessAddressErrorMessage: '',
      cityError: null,
      cityErrorMessage: '',
      stateError: null,
      stateErrorMessage: '',
      postalCodeError: null,
      postalCodeErrorMessage: '',
      countryError: null,
      countryErrorMessage: '',
      phoneError: null,
      phoneErrorMessage: '',
      custom1Error: null,
      custom1ErrorMessage: '',
      custom2Error: null,
      custom2ErrorMessage: '',
      custom3Error: null,
      custom3ErrorMessage: '',
      custom4Error: null,
      custom4ErrorMessage: '',
      custom5Error: null,
      custom5ErrorMessage: '',
      custom6Error: null,
      custom6ErrorMessage: '',
      question1Error: null,
      question1ErrorMessage: '',
      question2Error: null,
      question2ErrorMessage: '',
      question3Error: null,
      question3ErrorMessage: '',
      question4Error: null,
      question4ErrorMessage: '',
    });

    let validation = true;

    if (!this.state.privacySelected) {
      validation = false;

      this.setState({
        privacyAgree: true,
        globalError: true,
      });
    }

    if (this.state.email.indexOf(' ') > 0) {
      validation = false;

      this.setState({
        emailError: true,
        emailErrorMessage: 'Email cannot contain spaces',
        globalError: true,
      });
    }

    if (!this.state.email) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: 'Email is required',
        globalError: true,
      });
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: 'Email must contain:  @ and .',
        globalError: true,
      });
    }

    if (!this.state.firstName) {
      validation = false;
      this.setState({
        firstNameError: true,
        firstNameErrorMessage: 'First name is required',
        globalError: true,
      });
    }

    if (!this.state.lastName) {
      validation = false;
      this.setState({
        lastNameError: true,
        lastNameErrorMessage: 'Last name is required',
        globalError: true,
      });
    }

    if (!this.state.department) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.name === 'department'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          departmentError: true,
          departmentErrorMessage: 'Department is required',
          globalError: true,
        });
      }
    }

    if (!this.state.jobTitle) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.name === 'job_title'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          jobTitleError: true,
          jobTitleErrorMessage: 'Job Title is required',
          globalError: true,
        });
      }
    }

    if (!this.state.company) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.name === 'company'
        ).length > 0
      ) {
        let emailHasRequiredDomain = false;

        for (let i = 0; i < allowedRegistrationDomains.length; i++) {
          if (
            this.state.email
              .toLowerCase()
              .includes(allowedRegistrationDomains[i])
          ) {
            emailHasRequiredDomain = true;
          }
        }

        if (!emailHasRequiredDomain) {
          validation = false;
          this.setState({
            companyError: true,
            companyErrorMessage: 'Company is required',
            globalError: true,
          });
        }
      }

      if (!this.state.site) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'site'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            siteError: true,
            siteErrorMessage: 'Site is required',
            globalError: true,
          });
        }
      }

      if (!this.state.businessAddress) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'addressStreet'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            businessAddressError: true,
            businessAddressErrorMessage: 'Address is required',
            globalError: true,
          });
        }
      }

      if (!this.state.city) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'addressCity'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            cityError: true,
            cityErrorMessage: 'City is required',
            globalError: true,
          });
        }
      }

      if (!this.state.state) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'addressState'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            stateError: true,
            stateErrorMessage: 'State is required',
            globalError: true,
          });
        }
      }

      if (!this.state.postalCode) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'addressZip'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            postalCodeError: true,
            postalCodeErrorMessage: 'Postal Code is required',
            globalError: true,
          });
        }
      }

      if (!this.state.country) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'addressCountry'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            countryError: true,
            countryErrorMessage: 'Country is required',
            globalError: true,
          });
        }
      }

      if (!this.state.phone) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.name === 'phoneNumber'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            phoneError: true,
            phoneErrorMessage: 'Phone Number is required',
            globalError: true,
          });
        }
      }

      if (!this.state.custom1) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 1'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom1Error: true,
            custom1ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 1'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }
      if (!this.state.custom2) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 2'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom2Error: true,
            custom2ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 2'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }
      if (!this.state.custom3) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 3'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom3Error: true,
            custom3ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 3'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }
      if (!this.state.custom4) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 4'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom4Error: true,
            custom4ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 4'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }
      if (!this.state.custom5) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 5'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom5Error: true,
            custom5ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 5'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }
      if (!this.state.custom6) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Custom 6'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            custom6Error: true,
            custom6ErrorMessage: `${
              this.state.registrationFields[3].filter(
                (field) => field.label === 'Custom 6'
              )[0].name
            } is required`,
            globalError: true,
          });
        }
      }

      if (!this.state.question1) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Question 1'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            question1Error: true,
            question1ErrorMessage: 'Answer is required',
            globalError: true,
          });
        }
      }
      if (!this.state.question2) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Question 2'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            question2Error: true,
            question2ErrorMessage: 'Answer is required',
            globalError: true,
          });
        }
      }
      if (!this.state.question3) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Question 3'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            question3Error: true,
            question3ErrorMessage: 'Answer is required',
            globalError: true,
          });
        }
      }
      if (!this.state.question4) {
        if (
          this.state.registrationFields[3].filter(
            (field) => field.label === 'Question 4'
          ).length > 0
        ) {
          validation = false;
          this.setState({
            question4Error: true,
            question4ErrorMessage: 'Answer is required',
            globalError: true,
          });
        }
      }
    }
    if (validation) {
      this.setState({
        formSubmitted: true,
        emailError: false,
        emailErrorMessage: '',
        globalError: false,
      });

      let postedAttendeeId = null;

      let merckAccountId = '1691992000015906075';

      await axios({
        method: 'POST',
        url: '/zoho/show-attendees/',
        data: {
          email: this.state.email.toLowerCase(),
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          department: this.state.department,
          jobTitle: this.state.jobTitle,
          company: this.state.company,
          site: this.state.site,
          businessAddress: this.state.businessAddress,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
          country: this.state.country,
          phone: this.state.phone,
          custom1: this.state.custom1,
          custom2: this.state.custom2,
          custom3: this.state.custom3,
          custom4: this.state.custom4,
          custom5: this.state.custom5,
          custom6: this.state.custom6,
          question1: this.state.question1,
          question2: this.state.question2,
          question3: this.state.question3,
          question4: this.state.question4,
          // notes: `${this.state.findOut},${this.state.getOut}`,
          showId: eventId,
          accountId:
            this.state.ssoSignedIn && MerckSSOEnabled
              ? merckAccountId
              : accountId,
        },
      })
        .then((response) => {
          postedAttendeeId = response.data.details.id;
          this.setState({
            existingZohoRegistrant: true,
            formSubmitted: false,
          });
        })
        .catch((error) => {
          this.setState({
            existingZohoRegistrant: false,
            formSubmitted: false,
            globalError: true,
            emailError: true,
            emailErrorMessage:
              'Email is not valid for registration. Please contact support.',
          });
        });

      let customFieldAnswers = [
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom1 ? this.state.custom1 : null,
          attribute: this.state.registrationFields[1][0]
            ? this.state.registrationFields[1][0].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom2 ? this.state.custom2 : null,
          attribute: this.state.registrationFields[1][1]
            ? this.state.registrationFields[1][1].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom3 ? this.state.custom3 : null,
          attribute: this.state.registrationFields[1][2]
            ? this.state.registrationFields[1][2].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom4 ? this.state.custom4 : null,
          attribute: this.state.registrationFields[1][3]
            ? this.state.registrationFields[1][3].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom5 ? this.state.custom5 : null,
          attribute: this.state.registrationFields[1][4]
            ? this.state.registrationFields[1][4].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom6 ? this.state.custom6 : null,
          attribute: this.state.registrationFields[1][5]
            ? this.state.registrationFields[1][5].name
            : null,
        },
      ];
      let customQuestionAnswers = [
        {
          attendee_id: postedAttendeeId,
          value: this.state.question1 ? this.state.question1 : null,
          attribute: this.state.registrationFields[2][0]
            ? this.state.registrationFields[2][0].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question2 ? this.state.question2 : null,
          attribute: this.state.registrationFields[2][1]
            ? this.state.registrationFields[2][1].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question3 ? this.state.question3 : null,
          attribute: this.state.registrationFields[2][2]
            ? this.state.registrationFields[2][2].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question4 ? this.state.question4 : null,
          attribute: this.state.registrationFields[2][3]
            ? this.state.registrationFields[2][3].name
            : null,
        },
      ];

      for await (let customField of customFieldAnswers) {
        if (customField.attribute) {
          await axios({
            method: 'POST',
            url: `/registration-custom/${postedAttendeeId}`,
            data: customField,
          });
        }
      }

      for await (let customQuestion of customQuestionAnswers) {
        if (customQuestion.attribute) {
          await axios({
            method: 'POST',
            url: `/registration-custom/${postedAttendeeId}`,
            data: customQuestion,
          });
        }
      }
    }
  };

  handleValidation = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.confirmSignUp(
      this.state.email.toLowerCase(),
      this.state.confirmationCode
    )
      .then((response) => {
        this.setState({
          userConfirmed: true,
          formSubmitted: false,
        });
      })
      .catch((error) => {
        this.setState({
          confirmationCodeError: true,
          confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
          formSubmitted: false,
        });
      });
  };

  handleModal(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  checkEmail = async () => {
    this.setState({
      formSubmitted: true,
      emailError: false,
      emailErrorMessage: '',
      globalError: false,
    });

    if (!this.state.email) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Please enter a valid email',
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes('+')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Email cannot contain a '+'",
        globalError: true,
      });

      return;
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email must contain: @ and .',
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes(' ')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email cannot contain a space',
        globalError: true,
      });

      return;
    }

    //logic to be used if we need to check the domain of the user's email on enterance
    let emailHasRequiredDomain = false;

    for (let i = 0; i < allowedSigninDomains.length; i++) {
      if (this.state.email.toLowerCase().includes(allowedSigninDomains[i])) {
        emailHasRequiredDomain = true;
      }
    }

    if (!emailHasRequiredDomain) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage:
          'Your user is not allowed at this event. Please contact support.',
        globalError: true,
      });

      return;
    }

    let existingZohoRegistrant = false;
    let existingCognitoUser = false;
    // check to see if they are already a cognito user

    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${this.state.email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() ===
        this.state.email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    const registrationFields = await axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    })
      .then((response) => {
        let formObject = response.data.data;
        let registration = formObject.filter(
          (item) => item.type === 'registration'
        )[0];
        registration.fields = registration.fields
          .sort(function (a, b) {
            return a.field_id - b.field_id;
          })
          .filter((field) => field.is_enabled === true);
        let standardFields = registration.fields.filter(
          (field) => field.section === 'fieldsStandard'
        );
        let customFields = registration.fields.filter(
          (field) => field.section === 'fieldsCustom'
        );
        let questions = registration.fields.filter(
          (field) => field.section === 'question'
        );
        let requiredFields = registration.fields.filter(
          (field) => field.is_required === true
        );

        return [standardFields, customFields, questions, requiredFields];
      })
      .catch((error) => console.log(error));

    const code = '000000';

    await Auth.confirmSignUp(this.state.email.toLowerCase(), code, {
      forceAliasCreation: false,
    })
      .then((data) => console.log(data))
      .catch((err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            existingCognitoUser = false;
            break;
          case 'NotAuthorizedException':
            existingCognitoUser = true;
            break;
          case 'AliasExistsException':
            existingCognitoUser = true;
            break;
          case 'CodeMismatchException':
            existingCognitoUser = true;
            break;
          case 'ExpiredCodeException':
            existingCognitoUser = true;
            break;
          default:
            existingCognitoUser = false;
            break;
        }
      });

    this.setState({
      formSubmitted: false,
      emailChecked: true,
      existingCognitoUser: existingCognitoUser,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
    });
  };

  signIn = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            newPasswordRequired: true,
            formSubmitted: false,
          });
        } else {
          this.trackSignIn();
          this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          this.props.setBriefcase();
          this.props.history.push(`/lobby`);
        }
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.',
          });
        } else {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
          });
        }
      });
  };

  setPermanentPassword = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, this.state.newPassword)
            .then((user) => {
              this.props.setEventDetails();
              this.props.setAuthenticatedUser();
              this.props.history.push(`/lobby`);
            })
            .catch((e) => {
              this.setState({
                emailError: true,
                emailErrorMessage: 'Unable to set permanent password',
                formSubmitted: false,
              });
            });
        } else {
          this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          this.props.history.push(`/lobby`);
        }
      })
      .catch((e) => {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Unable to set permanent password',
          formSubmitted: false,
        });
      });
  };

  handleCognitoSignup = () => {
    this.setState({
      formSubmitted: true,
      passwordError: false,
      passwordErrorMessage: '',
    });

    Auth.signUp({
      username: this.state.email.toLowerCase(),
      password: this.state.password,
      attributes: {
        email: this.state.email.toLowerCase(),
      },
    })
      .then((response) => {
        this.setState({
          mustConfirmEmail: true,
          existingCognitoUser: true,
          formSubmitted: false,
          justSignedUp: true,
        });
      })
      .catch((error) => {
        switch (error.code) {
          case 'UsernameExistsException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'User already exists',
              formSubmitted: false,
            });
            break;
          case 'InvalidParameterException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'Please ensure you are using a valid email',
              formSubmitted: false,
            });
            break;
          default:
            this.setState({
              passwordError: true,
              passwordErrorMessage:
                'Password must be at least 8 characters long.',
              formSubmitted: false,
            });
        }
      });
  };

  handleCountrySelect = (event) => {
    this.setState({
      country: event.target.value,
    });
  };

  handleStateSelect = (event) => {
    this.setState({
      state: event.target.value,
    });
  };

  federateSignIn() {
    Auth.federatedSignIn()
      .then((cred) => {
        // If success, you will get the AWS credentials
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        // If success, the user object you passed in Auth.federatedSignIn
      })
      .catch((e) => {
        console.log(e);
      });
  }

  ssoSignedInNavigation = async () => {
    await this.trackSignIn();
    await this.props.setEventDetails();
    await this.props.setAuthenticatedUser();
    await this.props.setBriefcase();
    // window.location.reload();
    // window.open('/lobby')
    landingpageOnly
      ? this.props.history.push('/')
      : this.props.history.push('/lobby');
  };

  ssoCheckRegistrationStatus = async (email) => {
    let existingZohoRegistrant = false;

    const userInfo = await Auth.currentUserInfo();
    // SSONLY
    // console.log('identities:', JSON.parse(userInfo.attributes.identities)[0]);
    // potentially check for SAML and use that rather than landingpageOnly
    if (landingpageOnly) {
      // redirect
      this.props.history.push('/authentication');
      return;
    }
    // SSONLY
    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() === email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    let registrationFields;

    if (!existingZohoRegistrant) {
      registrationFields = await axios({
        method: 'get',
        url: 'forms/' + eventId,
        // headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId },
      })
        .then((response) => {
          let formObject = response.data.data;
          let registration = formObject.filter(
            (item) => item.type === 'registration'
          )[0];
          registration.fields = registration.fields
            .sort(function (a, b) {
              return a.field_id - b.field_id;
            })
            .filter((field) => field.is_enabled === true);
          let standardFields = registration.fields.filter(
            (field) => field.section === 'fieldsStandard'
          );
          let customFields = registration.fields.filter(
            (field) => field.section === 'fieldsCustom'
          );
          let questions = registration.fields.filter(
            (field) => field.section === 'question'
          );
          let requiredFields = registration.fields.filter(
            (field) => field.is_required === true
          );

          return [standardFields, customFields, questions, requiredFields];
        })
        .catch((error) => console.log(error));
    }

    this.setState({
      // user attributes
      firstName: userInfo.attributes.given_name,
      lastName: userInfo.attributes.family_name,
      department: userInfo.attributes.zoneinfo,
      country: userInfo.attributes.locale,
      jobTitle: userInfo.attributes.profile,
      site: userInfo.attributes.address,

      //form attributes
      formSubmitted: false,
      ssoEmailChecked: true,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
      ssoSignedIn: true,
      email: email,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.ssoError) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.event.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5">
                    There's been an error logging you in.
                  </Typography>
                  <Typography variant="body1">
                    Please ensure you are on your company's VPN. If the problem
                    persists, please contact support.
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ ssoError: false })}
                      className={classes.button}
                    >
                      Return to Login
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.ssoSignedIn) {
      if (this.state.existingZohoRegistrant) {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={'row'}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src={
                      this.props.event
                        ? this.props.event.event.banner_location
                        : landingPage.banner
                    }
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    <Typography variant="h5">You're Signed In!</Typography>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={() => this.ssoSignedInNavigation()}
                        className={classes.button}
                      >
                        {landingpageOnly
                          ? 'View Event Details'
                          : 'Enter Virtual Event'}
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      }
      // else {
      //   return (
      //     <div>
      //       <NavBar />
      //       <Grid
      //         style={{ paddingTop: '85px' }}
      //         container
      //         layout={'row'}
      //         justifyContent="center"
      //         spacing={0}
      //       >
      //         <Grid item xs={12} sm={8} md={6}>
      //           <img
      //             src={
      //               this.props.event
      //                 ? this.props.event.event.banner_location
      //                 : landingPage.banner
      //             }
      //             alt="Lobby"
      //             className={classes.bannerImage}
      //           />
      //           <Paper className={classes.paper}>
      //             <br />
      //             <Typography variant="h5">
      //               Alright! Let's get you set up.
      //             </Typography>
      //             <br />
      //             <Typography variant="body1">
      //               Please fill out all fields below and confirm you have
      //               reviewed the Privacy Policy, then click Register
      //             </Typography>
      //             <br />
      //             <FormControl
      //               className={classes.formControl}
      //               onKeyUp={(event) => {
      //                 if (event.key === 'Enter') this.handleZohoRegistration();
      //               }}
      //             >
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'first_name'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'first_name'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'firstName')
      //                     }
      //                     value={this.state.firstName}
      //                     label="First Name"
      //                     error={this.state.firstNameError}
      //                     helperText={this.state.firstNameErrorMessage}
      //                     autoFocus
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'last_name'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'last_name'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'lastName')
      //                     }
      //                     value={this.state.lastName}
      //                     label="Last Name"
      //                     error={this.state.lastNameError}
      //                     helperText={this.state.lastNameErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'department'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'department'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'department')
      //                     }
      //                     value={this.state.department}
      //                     label="Department"
      //                     error={this.state.departmentError}
      //                     helperText={this.state.departmentErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'job_title'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'job_title'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'jobTitle')
      //                     }
      //                     value={this.state.jobTitle}
      //                     label="Job Title"
      //                     error={this.state.jobTitleError}
      //                     helperText={this.state.jobTitleErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'company'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'company'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'company')
      //                     }
      //                     value={this.state.company}
      //                     label="Company"
      //                     error={this.state.companyError}
      //                     helperText={this.state.companyErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'site'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'site'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) => this.handleChange(event, 'site')}
      //                     value={this.state.site}
      //                     label="Site"
      //                     error={this.state.siteError}
      //                     helperText={this.state.siteErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'addressStreet'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'addressStreet'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'businessAddress')
      //                     }
      //                     value={this.state.businessAddress}
      //                     label="Business Address"
      //                     error={this.state.businessAddressError}
      //                     helperText={this.state.businessAddressErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'addressCity'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'addressCity'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) => this.handleChange(event, 'city')}
      //                     value={this.state.city}
      //                     label="City/Locality"
      //                     error={this.state.cityError}
      //                     helperText={this.state.cityErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'addressState'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'addressState'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'state')
      //                     }
      //                     value={this.state.state}
      //                     label="State/Province"
      //                     error={this.state.stateError}
      //                     helperText={this.state.stateErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'addressZip'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'addressZip'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'postalCode')
      //                     }
      //                     value={this.state.postalCode}
      //                     label="Postal Code"
      //                     error={this.state.postalCodeError}
      //                     helperText={this.state.postalCodeErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'addressCountry'
      //               ).length > 0 ? (
      //                 <FormControl className={classes.formControl}>
      //                   <InputLabel id="demo-simple-select-label">
      //                     Country
      //                   </InputLabel>
      //                   <Select
      //                     labelId="demo-simple-select-label"
      //                     id="demo-simple-select"
      //                     value={this.state.country}
      //                     error={this.state.countryError}
      //                     onChange={this.handleCountrySelect}
      //                   >
      //                     {countryList.map((country, index) => {
      //                       return (
      //                         <MenuItem key={index} value={country}>
      //                           {country}
      //                         </MenuItem>
      //                       );
      //                     })}
      //                   </Select>
      //                   {this.state.countryErrorMessage ? (
      //                     <FormHelperText>
      //                       {this.state.countryErrorMessage}
      //                     </FormHelperText>
      //                   ) : null}
      //                   <br />
      //                 </FormControl>
      //               ) : null}
      //               {this.state.registrationFields[0].filter(
      //                 (field) => field.name === 'phoneNumber'
      //               ).length > 0 ? (
      //                 <>
      //                   <TextField
      //                     required={
      //                       this.state.registrationFields[0].filter(
      //                         (field) => field.name === 'phoneNumber'
      //                       )[0].is_required
      //                     }
      //                     onChange={(event) =>
      //                       this.handleChange(event, 'phone')
      //                     }
      //                     value={this.state.phone}
      //                     label="Phone Number"
      //                     error={this.state.phoneError}
      //                     helperText={this.state.phoneErrorMessage}
      //                   />
      //                   <br />
      //                 </>
      //               ) : null}
      //               {this.state.registrationFields[1].map((field, index) => {
      //                 let newIndex = index + 1;
      //                 let state1 = `custom${newIndex}`;
      //                 let state2 = `custom${newIndex}Error`;
      //                 let state3 = `custom${newIndex}ErrorMessage`;
      //                 return (
      //                   <React.Fragment key={field.field_id}>
      //                     <TextField
      //                       required={field.is_required}
      //                       onChange={(event) =>
      //                         this.handleChange(event, `custom${newIndex}`)
      //                       }
      //                       value={this.state[state1]}
      //                       label={field.name}
      //                       error={this.state[state2]}
      //                       helperText={this.state[state3]}
      //                     />
      //                     <br />
      //                   </React.Fragment>
      //                 );
      //               })}
      //               {this.state.registrationFields[2].map((field, index) => {
      //                 let newIndex = index + 1;
      //                 let state1 = `question${newIndex}`;
      //                 let state2 = `question${newIndex}Error`;
      //                 let state3 = `question${newIndex}ErrorMessage`;
      //                 return (
      //                   <React.Fragment key={field.field_id}>
      //                     <TextField
      //                       required={field.is_required}
      //                       onChange={(event) =>
      //                         this.handleChange(event, `question${newIndex}`)
      //                       }
      //                       value={this.state[state1]}
      //                       error={this.state[state2]}
      //                       helperText={this.state[state3]}
      //                       label={field.name}
      //                       fullWidth
      //                       multiline
      //                       rowsMax={4}
      //                     />
      //                     <br />
      //                   </React.Fragment>
      //                 );
      //               })}
      //               <TextField
      //                 required
      //                 onChange={(event) => this.handleChange(event, 'email')}
      //                 value={this.state.email}
      //                 label="Email"
      //                 disabled
      //                 error={this.state.emailError}
      //                 helperText={this.state.emailErrorMessage}
      //               />
      //             </FormControl>
      //             {/* <FormControl className={classes.formControl}>
      //                           <TextField
      //                               type="password"
      //                               onChange={(event) => this.handleChange(event, "password")}
      //                               value={this.state.password}
      //                               label="Password"
      //                               error = {this.state.parameterError}
      //                               helperText={this.state.parameterErrorMessage}
      //                           />
      //                       </FormControl> */}
      //             <FormControl style={{ margin: '24px 0px', width: '85%' }}>
      //               <Grid
      //                 container
      //                 alignItems="center"
      //                 justifyContent="space-between"
      //               >
      //                 <Grid item xs={2} className={classes.toggleButton}>
      //                   <ToggleButton
      //                     value="check"
      //                     selected={this.state.privacySelected}
      //                     onChange={() => {
      //                       this.setState({
      //                         privacySelected: !this.state.privacySelected,
      //                       });
      //                     }}
      //                   >
      //                     <CheckIcon style={{ color: 'white' }} />
      //                   </ToggleButton>
      //                 </Grid>
      //                 <Grid item xs={6} style={{ padding: '0px 16px' }}>
      //                   <Typography variant="body2" color="textPrimary">
      //                     I have read and understand the Privacy Policy
      //                   </Typography>
      //                 </Grid>
      //                 <Grid item xs={4}>
      //                   <Button
      //                     variant="contained"
      //                     onClick={() => this.handleModal(true, 'privacyOpen')}
      //                     className={classes.button}
      //                     style={{ height: '100%', width: '80%' }}
      //                   >
      //                     Privacy
      //                     <br />
      //                     Policy
      //                   </Button>
      //                 </Grid>
      //               </Grid>
      //             </FormControl>
      //             <FormControl className={classes.formControlButton}>
      //               <Button
      //                 variant="contained"
      //                 onClick={this.handleZohoRegistration}
      //                 className={classes.button}
      //               >
      //                 {this.state.formSubmitted ? (
      //                   <CircularProgress color="inherit" />
      //                 ) : (
      //                   <div>Register</div>
      //                 )}
      //               </Button>
      //               <FormHelperText id="component-helper-text" error>
      //                 {this.state.privacyAgree
      //                   ? 'Please agree to the privacy policy to continue'
      //                   : ''}
      //               </FormHelperText>
      //               <FormHelperText id="component-helper-text" error>
      //                 {this.state.globalError
      //                   ? 'Please rectify errors above'
      //                   : ''}
      //               </FormHelperText>
      //             </FormControl>
      //             <DialogWrapper
      //               open={this.state.privacyOpen}
      //               handleClose={(e) => this.setState({ privacyOpen: false })}
      //             >
      //               <Privacy />
      //               <Button
      //                 className={classes.acceptButton}
      //                 variant="contained"
      //                 onClick={(e) =>
      //                   this.setState({
      //                     privacyOpen: false,
      //                     privacySelected: true,
      //                   })
      //                 }
      //               >
      //                 I Accept
      //               </Button>
      //               <Button
      //                 className={classes.closeButton}
      //                 variant="contained"
      //                 onClick={() => this.handleModal(false, 'privacyOpen')}
      //               >
      //                 Close
      //               </Button>
      //             </DialogWrapper>
      //           </Paper>
      //         </Grid>
      //       </Grid>
      //       <Button
      //         className={classes.privacyButton}
      //         variant="contained"
      //         onClick={() => this.handleModal(true, 'privacyOpen')}
      //       >
      //         View{' '}
      //         <Hidden smUp>
      //           <br />
      //         </Hidden>
      //         Privacy{' '}
      //         <Hidden smUp>
      //           <br />
      //         </Hidden>
      //         Policy
      //       </Button>
      //     </div>
      //   );
      // }
    }

    if (this.state.newPasswordRequired) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.event.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5">
                    Create Permanent Password
                  </Typography>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      error={this.state.emailError}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) =>
                        this.handleChange(event, 'newPassword')
                      }
                      type="password"
                      value={this.state.newPassword}
                      label="Password"
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.setPermanentPassword}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.emailChecked) {
      if (!this.state.existingZohoRegistrant) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.event.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5">
                    Alright! Let's get you set up.
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Please fill out all fields below and confirm you have
                    reviewed the Privacy Policy, then click Register
                  </Typography>
                  <br />
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') this.handleZohoRegistration();
                    }}
                  >
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'first_name'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'first_name'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'firstName')
                          }
                          value={this.state.firstName}
                          label="First Name"
                          error={this.state.firstNameError}
                          helperText={this.state.firstNameErrorMessage}
                          autoFocus
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'last_name'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'last_name'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'lastName')
                          }
                          value={this.state.lastName}
                          label="Last Name"
                          error={this.state.lastNameError}
                          helperText={this.state.lastNameErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'department'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'department'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'department')
                          }
                          value={this.state.department}
                          label="Department"
                          error={this.state.departmentError}
                          helperText={this.state.departmentErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'job_title'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'job_title'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'jobTitle')
                          }
                          value={this.state.jobTitle}
                          label="Job Title"
                          error={this.state.jobTitleError}
                          helperText={this.state.jobTitleErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'company'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'company'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'company')
                          }
                          value={this.state.company}
                          label="Company"
                          error={this.state.companyError}
                          helperText={this.state.companyErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'site'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'site'
                            )[0].is_required
                          }
                          onChange={(event) => this.handleChange(event, 'site')}
                          value={this.state.site}
                          label="Site"
                          error={this.state.siteError}
                          helperText={this.state.siteErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressStreet'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressStreet'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'businessAddress')
                          }
                          value={this.state.businessAddress}
                          label="Business Address"
                          error={this.state.businessAddressError}
                          helperText={this.state.businessAddressErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressCity'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressCity'
                            )[0].is_required
                          }
                          onChange={(event) => this.handleChange(event, 'city')}
                          value={this.state.city}
                          label="City/Locality"
                          error={this.state.cityError}
                          helperText={this.state.cityErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressState'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressState'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'state')
                          }
                          value={this.state.state}
                          label="State/Province"
                          error={this.state.stateError}
                          helperText={this.state.stateErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressZip'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressZip'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'postalCode')
                          }
                          value={this.state.postalCode}
                          label="Postal Code"
                          error={this.state.postalCodeError}
                          helperText={this.state.postalCodeErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressZip'
                    ).length > 0 ? (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.country}
                          error={this.state.countryError}
                          onChange={this.handleCountrySelect}
                        >
                          {countryList.map((country, index) => {
                            return (
                              <MenuItem key={index} value={country}>
                                {country}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.state.countryErrorMessage ? (
                          <FormHelperText>
                            {this.state.countryErrorMessage}
                          </FormHelperText>
                        ) : null}
                        <br />
                      </FormControl>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'phoneNumber'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'phoneNumber'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'phone')
                          }
                          value={this.state.phone}
                          label="Phone Number"
                          error={this.state.phoneError}
                          helperText={this.state.phoneErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[1].map((field, index) => {
                      let newIndex = index + 1;
                      let state1 = `custom${newIndex}`;
                      let state2 = `custom${newIndex}Error`;
                      let state3 = `custom${newIndex}ErrorMessage`;
                      return (
                        <React.Fragment key={field.field_id}>
                          <TextField
                            required={field.is_required}
                            onChange={(event) =>
                              this.handleChange(event, `custom${newIndex}`)
                            }
                            value={this.state[state1]}
                            label={field.name}
                            error={this.state[state2]}
                            helperText={this.state[state3]}
                          />
                          <br />
                        </React.Fragment>
                      );
                    })}
                    {this.state.registrationFields[2].map((field, index) => {
                      let newIndex = index + 1;
                      let state1 = `question${newIndex}`;
                      let state2 = `question${newIndex}Error`;
                      let state3 = `question${newIndex}ErrorMessage`;
                      return (
                        <React.Fragment key={field.field_id}>
                          <TextField
                            required={field.is_required}
                            onChange={(event) =>
                              this.handleChange(event, `question${newIndex}`)
                            }
                            value={this.state[state1]}
                            error={this.state[state2]}
                            helperText={this.state[state3]}
                            label={field.name}
                            fullWidth
                            multiline
                            rowsMax={4}
                          />
                          <br />
                        </React.Fragment>
                      );
                    })}
                    <TextField
                      required
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  {/* <FormControl className={classes.formControl}>
                                <TextField
                                    type="password"
                                    onChange={(event) => this.handleChange(event, "password")}
                                    value={this.state.password}
                                    label="Password"
                                    error = {this.state.parameterError}
                                    helperText={this.state.parameterErrorMessage}
                                />
                            </FormControl> */}
                  <FormControl style={{ margin: '24px 0px', width: '85%' }}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={2} className={classes.toggleButton}>
                        <ToggleButton
                          value="check"
                          selected={this.state.privacySelected}
                          onChange={() => {
                            this.setState({
                              privacySelected: !this.state.privacySelected,
                            });
                          }}
                        >
                          <CheckIcon style={{ color: 'white' }} />
                        </ToggleButton>
                      </Grid>
                      <Grid item xs={6} style={{ padding: '0px 16px' }}>
                        <Typography variant="body2" color="textPrimary">
                          I have read and understand the Privacy Policy
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          onClick={() => this.handleModal(true, 'privacyOpen')}
                          className={classes.button}
                          style={{ height: '100%', width: '80%' }}
                        >
                          Privacy
                          <br />
                          Policy
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleZohoRegistration}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Register</div>
                      )}
                    </Button>
                    <FormHelperText id="component-helper-text" error>
                      {this.state.privacyAgree
                        ? 'Please agree to the privacy policy to continue'
                        : ''}
                    </FormHelperText>
                    <FormHelperText id="component-helper-text" error>
                      {this.state.globalError
                        ? 'Please rectify errors above'
                        : ''}
                    </FormHelperText>
                  </FormControl>
                  <DialogWrapper
                    open={this.state.privacyOpen}
                    handleClose={(e) => this.setState({ privacyOpen: false })}
                  >
                    <Privacy />
                    <Button
                      className={classes.acceptButton}
                      variant="contained"
                      onClick={(e) =>
                        this.setState({
                          privacyOpen: false,
                          privacySelected: true,
                        })
                      }
                    >
                      I Accept
                    </Button>
                    <Button
                      className={classes.closeButton}
                      variant="contained"
                      onClick={() => this.handleModal(false, 'privacyOpen')}
                    >
                      Close
                    </Button>
                  </DialogWrapper>
                </Paper>
              </Grid>
            </Grid>
            <Button
              className={classes.privacyButton}
              variant="contained"
              onClick={() => this.handleModal(true, 'privacyOpen')}
            >
              View{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Privacy{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Policy
            </Button>
          </div>
        );
      } else if (!this.state.existingCognitoUser) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.event.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5">
                    Perfect! Now let's make sure you can securely login
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Please create a password using the form below
                  </Typography>
                  <br />
                  {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') this.handleCognitoSignup();
                    }}
                  >
                    <TextField
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                    <br />
                    <TextField
                      type="password"
                      onChange={(event) => this.handleChange(event, 'password')}
                      value={this.state.password}
                      label="Password"
                      error={this.state.passwordError}
                      helperText={
                        this.state.passwordErrorMessage
                          ? this.state.passwordErrorMessage
                          : 'Password must be at least 8 characters long'
                      }
                      autoFocus
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleCognitoSignup}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Next</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
                <DialogWrapper
                  open={this.state.mustConfirmEmail}
                  handleClose={() =>
                    this.handleModal(false, 'mustConfirmEmail')
                  }
                >
                  <Grid
                    style={{ paddingTop: '60px' }}
                    container
                    layout={'row'}
                    justifyContent="center"
                    spacing={0}
                  >
                    <Grid item xs={12} sm={8} md={6}>
                      <Typography variant="h5">
                        Please check your inbox to confirm your email
                      </Typography>
                      <Typography variant="body1">
                        If you cannot find the confirmation email, wait a few
                        minutes and check your spam folder. If the problem
                        persists, please reach out for chat support in the
                        bottom right of your screen.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.closeButton}
                    variant="contained"
                    onClick={() => this.handleModal(false, 'mustConfirmEmail')}
                  >
                    Next
                  </Button>
                </DialogWrapper>
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={'row'}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={8} md={6}>
                  <img
                    src={
                      this.props.event
                        ? this.props.event.event.banner_location
                        : landingPage.banner
                    }
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    {this.state.justSignedUp ? (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5">
                          Beautiful! One last step.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                          Check your inbox for an email from
                          eregistration@planetconnect.com for your verification
                          link, then return here to sign in. The verification
                          link will only be valid for 1 hour.
                        </Typography>
                        <br />
                        {/* <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={() => this.resendVerificationCode()} className={classes.button}>
                                            Resend Verification
                                        </Button>
                                    </FormControl> */}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5">
                          Please input your password below to sign in.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                          If you have forgotten your password, you may reset it
                          below.
                        </Typography>
                        <br />
                      </React.Fragment>
                    )}

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') this.signIn();
                      }}
                    >
                      <TextField
                        onChange={(event) => this.handleChange(event, 'email')}
                        value={this.state.email}
                        label="Email"
                        error={this.state.emailError}
                        disabled
                      />
                      <br />
                      <TextField
                        onChange={(event) =>
                          this.handleChange(event, 'password')
                        }
                        type="password"
                        value={this.state.password}
                        label="Password"
                        error={this.state.passwordError}
                        helperText={this.state.passwordErrorMessage}
                        autoFocus
                      />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={this.signIn}
                        className={classes.button}
                      >
                        {this.state.formSubmitted ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <div>Enter</div>
                        )}
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        color="inherit"
                        component={Link}
                        to={'/forgotpassword'}
                      >
                        Reset Password
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
            <DialogWrapper
              open={this.state.privacyOpen}
              handleClose={() => this.handleModal(false, 'privacyOpen')}
            >
              <Privacy />
              <Button
                className={classes.closeButton}
                variant="contained"
                onClick={() => this.handleModal(false, 'privacyOpen')}
              >
                Close
              </Button>
            </DialogWrapper>
            <Button
              className={classes.privacyButton}
              variant="contained"
              onClick={() => this.handleModal(true, 'privacyOpen')}
            >
              View{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Privacy{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Policy
            </Button>
          </div>
        );
      }
    }

    // let FirstName;
    // let LastName;

    // if (this.state.federatedId) {
    //   return (
    //     <div>
    //       <NavBar />
    //       <Grid
    //         style={{ paddingTop: '85px' }}
    //         container
    //         layout={'row'}
    //         justifyContent="center"
    //         spacing={0}
    //       >
    //         <Grid item xs={12} sm={8} md={6}>
    //           <img
    //             src={
    //               this.props.event
    //                 ? this.props.event.event.banner_location
    //                 : landingPage.banner
    //             }
    //             alt="Lobby"
    //             className={classes.bannerImage}
    //           />
    //           <Paper className={classes.paper}>
    //             <br />
    //             <Typography variant="h5">
    //               Welcome to the {this.props.event.event.title}!
    //             </Typography>
    //             <br />
    //             <Typography variant="body1">
    //               Enter your Merck email address to continue
    //             </Typography>
    //             <br />
    //             <FormControl
    //               className={classes.formControl}
    //               onKeyUp={(event) => {
    //                 if (event.key === 'Enter') this.checkEmail();
    //               }}
    //             >
    //               <TextField
    //                 required
    //                 onChange={(event) => this.handleChange(event, 'email')}
    //                 value={this.state.email}
    //                 label="Email"
    //                 error={this.state.emailError}
    //                 helperText={this.state.emailErrorMessage}
    //                 autoFocus
    //               />
    //             </FormControl>
    //             <FormControl className={classes.formControl} fullWidth>
    //               <TextField
    //                 onChange={(event) =>
    //                   this.handleChange(event, 'newPassword')
    //                 }
    //                 type="password"
    //                 value={this.state.newPassword}
    //                 label="Password"
    //                 error={this.state.emailError}
    //                 helperText={this.state.emailErrorMessage}
    //               />
    //             </FormControl>
    //             <FormControl className={classes.formControlButton}>
    //               <br />
    //               <Button
    //                 type="submit"
    //                 variant="contained"
    //                 onClick={() =>
    //                   Auth.federatedSignIn(
    //                     {
    //                       provider: 'Okta',
    //                     },
    //                     {
    //                       // the user object, you can put whatever property you get from the Auth0
    //                       // for example:
    //                       // the user name
    //                       FirstName, // Optional, the email address
    //                       LastName, // Optional, the phone number
    //                     }
    //                   )
    //                 }
    //                 className={classes.button}
    //               >
    //                 {this.state.formSubmitted ? (
    //                   <CircularProgress color="inherit" />
    //                 ) : (
    //                   <div>Check Merck Email</div>
    //                 )}
    //               </Button>
    //               <br />
    //               <Button
    //                 type="submit"
    //                 variant="contained"
    //                 onClick={() => this.setState({ federatedId: true })}
    //                 className={classes.button}
    //                 style={{ backgroundColor: '#008080' }}
    //               >
    //                 Use Merck Login
    //               </Button>
    //               <br />
    //             </FormControl>
    //             <DialogWrapper
    //               open={this.state.privacyOpen}
    //               handleClose={(e) => this.setState({ privacyOpen: false })}
    //             >
    //               <Privacy />
    //               <Button
    //                 className={classes.acceptButton}
    //                 variant="contained"
    //                 onClick={(e) =>
    //                   this.setState({
    //                     privacyOpen: false,
    //                     privacySelected: true,
    //                   })
    //                 }
    //               >
    //                 I Accept
    //               </Button>
    //               <Button
    //                 className={classes.closeButton}
    //                 variant="contained"
    //                 onClick={() => this.handleModal(false, 'privacyOpen')}
    //               >
    //                 Close
    //               </Button>
    //             </DialogWrapper>
    //           </Paper>
    //         </Grid>
    //       </Grid>
    //       <Button
    //         className={classes.privacyButton}
    //         variant="contained"
    //         onClick={() => this.handleModal(true, 'privacyOpen')}
    //       >
    //         View{' '}
    //         <Hidden smUp>
    //           <br />
    //         </Hidden>
    //         Privacy{' '}
    //         <Hidden smUp>
    //           <br />
    //         </Hidden>
    //         Policy
    //       </Button>
    //     </div>
    //   );
    // }

    return (
      <div>
        <NavBar />
        <Grid
          style={{ paddingTop: '85px' }}
          container
          layout={'row'}
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={
                this.props.event
                  ? this.props.event.event.banner_location
                  : landingPage.banner
              }
              alt="Lobby"
              className={classes.bannerImage}
            />
            <Paper className={classes.paper}>
              <br />
              <Typography variant="h5">Loading credentials...</Typography>
              <br />
              {/* <br />
              <Typography variant="h5">
                Welcome to the {this.props.event.event.title}!
              </Typography>
              <br />
              <Typography variant="body1">
                Let's get started! Please provide your email address below and
                click next.
              </Typography>
              {/* <Typography variant="body1">
                                Please click the button below to test PlanetConnect and Merck's SSO connection
                            </Typography> */}
              {/* <FormControl
                className={classes.formControl}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') this.checkEmail();
                }}
              >
                <TextField
                  required
                  onChange={(event) => this.handleChange(event, 'email')}
                  value={this.state.email}
                  label="Email"
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                  autoFocus
                />
              </FormControl> */}
              {/* <FormControl className={classes.formControlButton}> */}
              {/* <br />
                <Button
                  type="submit"
                  variant="contained"
                  onClick={this.checkEmail}
                  className={classes.button}
                >
                  {this.state.formSubmitted ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <div>Next</div>
                  )}
                </Button>
                <br /> */}
              {/* {OktaSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => Auth.federatedSignIn({ provider: 'Okta' })}
                      className={classes.button}
                    >
                      Login with Okta
                    </Button>
                    <br />
                  </>
                ) : null} */}
              {/* {MerckSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Merck' })
                      }
                      className={classes.button}
                    >
                      Login with Merck SSO
                    </Button>
                    <br />
                  </>
                ) : null} */}
              {/* </FormControl> */}
              <DialogWrapper
                open={this.state.privacyOpen}
                handleClose={(e) => this.setState({ privacyOpen: false })}
              >
                <Privacy />
                <Button
                  className={classes.acceptButton}
                  variant="contained"
                  onClick={(e) =>
                    this.setState({ privacyOpen: false, privacySelected: true })
                  }
                >
                  I Accept
                </Button>
                <Button
                  className={classes.closeButton}
                  variant="contained"
                  onClick={() => this.handleModal(false, 'privacyOpen')}
                >
                  Close
                </Button>
              </DialogWrapper>
            </Paper>
          </Grid>
        </Grid>
        <Button
          className={classes.privacyButton}
          variant="contained"
          onClick={() => this.handleModal(true, 'privacyOpen')}
        >
          View{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Privacy{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Policy
        </Button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
      setBriefcase: setBriefcase,
      setEventDetails: setEventDetails,
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(SignUpForm)));
