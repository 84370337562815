function EmailTemplate(body) {
  return `<html><h3>${body.title}</h3><p>${body.body}</p></html>`;
}

function FormatObject(object) {
  let output = '';
  for (const [key, value] of Object.entries(object)) {
    output = output + `<p><b>${capitalizeFirstLetter(key)}:</b> ${value}</p>`;
  }
  return output;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { EmailTemplate, FormatObject };
