import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuditoriumCard from './AuditoriumCard';
import { withRouter } from 'react-router';
import Auditorium from './Auditorium.jsx';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';
import trackAction from '../../Store/Actions/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  gridContainer: {},
  gridCard: {
    width: '100%',
  },
});

class ConferenceCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      rooms: [],
      auditoriumSelected: false,
      auditorium: null,
      topicMap: null,
      conferenceLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'roomName') {
          this.setState({
            auditoriumSelected: true,
            auditorium: param[1],
          });
        }
      }
    }

    this.getPresentations();
    this.props.setBriefcase();
    this.setState({
      topicMap: JSON.parse(this.props.event.event.topic_map),
      conferenceLoaded: true,
    });

    console.log('topic map', this.state.topicMap);

    const event = {
      action: 'Viewed',
      resource_type: 'ConferenceCenter',
      resource_id: null,
      url_visited: '/auditorium',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  getPresentations() {
    this.props.getAgendas();

    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);

      for (let param of query.entries()) {
        if (param[0] === 'presentation_id') {
          const searchedPresentation = this.props.agendas?.data?.data.filter(
            (abstract) => abstract.abstract_id === param[1]
          );
          if (searchedPresentation.length) {
            this.setState({
              auditoriumSelected: true,
              auditorium: searchedPresentation[0].room,
            });
          }
        }
      }
    }
  }

  toggleAuditoriumFullscreenOn = (event, roomName) => {
    this.setState({
      auditoriumSelected: true,
      auditorium: roomName,
    });
  };

  toggleAuditoriumFullscreenOff = () => {
    this.setState({
      auditoriumSelected: false,
      auditorium: null,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      // this.toggleAuditoriumFullscreenOff();
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    let authorizedPresentations = [];
    const agendas = this.props.agendas?.data?.data.map((agenda) => ({
      ...agenda,
    }));

    for (let x = 0; x < agendas?.length; x++) {
      let abstract = agendas[x];

      if (abstract.cleared_for_public_view === 'Yes - Approved') {
        authorizedPresentations.push(abstract);
      } else {
        if (
          this.props.user.user.roles.includes('Attendee') ||
          this.props.user.user.roles.includes('Pharma Attendee')
        ) {
          authorizedPresentations.push(abstract);
        }
      }
    }

    let roomsArray = [];

    for (let n = 0; n < authorizedPresentations.length; n++) {
      if (
        !roomsArray.includes(authorizedPresentations[n].room) &&
        authorizedPresentations[n].room !== 'Break'
      ) {
        roomsArray.push(authorizedPresentations[n].room);
      }
    }

    roomsArray = roomsArray.filter((e) => e);
    roomsArray.sort();

    let pageBody = null;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/auditorium') {
        pageTitle = route.title;
      }
    });

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );

    let loadCompleteNoneFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h6" color="textPrimary">
          No items available.
        </Typography>
      </Grid>
    );

    if (this.state.auditoriumSelected) {
      pageBody = (
        <Grid>
          <Auditorium
            roomName={this.state.auditorium}
            closeAuditorium={this.toggleAuditoriumFullscreenOff.bind(this)}
          />
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          {roomsArray.length <= 0 && !this.state.conferenceLoaded
            ? loadingGraphic
            : null}
          {roomsArray.length <= 0 && this.state.conferenceLoaded
            ? loadCompleteNoneFound
            : null}
          {roomsArray.map((room, index) => {
            return (
              <Grid item xs={6} md={3} key={index} className={classes.gridCard}>
                <AuditoriumCard
                  roomPicture={this.state.topicMap?.[room]}
                  roomName={room}
                  roomCategory="Breakout"
                  toggleFullscreenHandlerOn={this.toggleAuditoriumFullscreenOn.bind(
                    this
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    return <React.Fragment>{pageBody}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    debug: state.debug,
    event: state.event,
    // agendas: queryApi.endpoints.getAgendas.select({ eventId: eventId })(state),
    agendas: state.queryApi.queries?.[`getAgendas({"eventId":"${eventId}"})`],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
      getAgendas: () =>
        queryApi.endpoints.getAgendas.initiate({ eventId: eventId }),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ConferenceCenter)));
