import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import trackAction from '../../Store/Actions/tracker';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { SHARE_SITES } from 'react-add-to-calendar-hoc';
import DialogWrapper from '../../Components/DialogWrapper';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import { queryApi } from '../../Services/queryApi';
import { eventId } from '../../config';

const useStyles = (theme) => ({
  rowEventRoot: {
    // cursor: "pointer",
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 0, 1, 0),
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  centerColumn: {
    paddingLeft: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
  },
  time: {
    // padding: theme.spacing(0, 1, 0, 0),
    fontWeight: 'bold',
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: '5px 0px 15px 0px',
    opacity: 0.85,
  },
  linePhoto: {
    borderRadius: '50%',
    width: '3rem',
    height: '3rem',
  },
  lineTrack: {
    fontSize: '0.8rem',
    fontWeight: 'italic',
    textAlign: 'left',
    // paddingTop: theme.spacing(2),
  },
  lineTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineSpeakerName: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'initial',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
    textTransform: 'initial',
  },
  lineSpeakerCompany: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'initial',
  },
  linkStyles: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1),
    color: 'black',
    fontSize: '1.25em',
  },
  presentationButton: {
    width: '80%',
    borderRadius: '5px',
    margin: theme.spacing(1),
  },
  detailsButton: {
    width: '100%',
    borderRadius: '5px',
    // margin: theme.spacing(1),
  },
  calendarContainer: {
    display: 'flex',
  },
  calendarButton: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
  },
  access: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  aboutMeBox: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    justifyContent: 'center',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    fontSize: '150%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: '4px 4px 0px 0px',
  },
});

class AgendaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      // buttonHover: false,
      contactOpen: false,
      currentContact: {},
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  handleContactOpen(presenter) {
    this.setState({ contactOpen: true, currentContact: presenter });
  }
  handleContactClose() {
    this.setState({ contactOpen: false, currentContact: {} });
    // this.setState({buttonHover: true, contactOpen: false, currentContact: {}})
  }

  navigate() {
    // if (!this.state.buttonHover) {
    switch (this.props.abstract.accepted_type) {
      case 'Discussion Forum':
        this.props.history.push(
          `/discussionforum?presentation_id=${this.props.abstract.abstract_id}`
        );
        break;
      case 'ExhibitHall':
        this.props.history.push(`/exhibithall`);
        break;
      case 'PosterHall':
        this.props.history.push(`/posterhall`);
        break;
      case 'WorkshopHall':
        this.props.history.push(`/workshop`);
        break;
      default:
        if (this.props.abstract.archive_link) {
          const event = {
            action: 'Clicked',
            resource_type: 'Archive',
            resource_id: this.props.abstract.abstract_id,
            url_visited: '/agenda',
            // user_account_id: this.props.user.user.account_id,
            // new fields
            resource_account_id: this.props.abstract.account_id,
            resource_account_name: this.props.abstract.account_name,
            resource_name: this.props.abstract.title,
            //old fields
            // user_account_name: this.props.user.user.account_name,
            // user_email: this.props.user.user.email,
            // user_first_name: this.props.user.user.first_name,
            // user_last_name: this.props.user.user.last_name,
            // user_title: this.props.user.user.title,
            // user_department: this.props.user.user.department,
            // user_site: this.props.user.user.site,
          };

          this.props.trackAction(event);

          window.open(this.props.abstract.archive_link, '_blank');
        } else {
          if (this.props.abstract.presentation_link) {
            const event = {
              action: 'Clicked',
              resource_type: 'Presentation',
              resource_id: this.props.abstract.abstract_id,
              url_visited: '/agenda',
              // user_account_id: this.props.user.user.account_id,
              // new fields
              resource_account_id: this.props.abstract.account_id,
              resource_account_name: this.props.abstract.account_name,
              resource_name: this.props.abstract.title,
              //old fields
              // user_account_name: this.props.user.user.account_name,
              // user_email: this.props.user.user.email,
              // user_first_name: this.props.user.user.first_name,
              // user_last_name: this.props.user.user.last_name,
              // user_title: this.props.user.user.title,
              // user_department: this.props.user.user.department,
              // user_site: this.props.user.user.site,
            };

            this.props.trackAction(event);

            window.open(this.props.abstract.presentation_link, '_blank');
          }
        }
    }
    // }
    // this.setState({buttonHover: false})
  }
  trackCalendarClick(e) {
    const event = {
      action: 'Added',
      resource_type: 'PersonalCalendar',
      resource_id: this.props.abstract.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.abstract.account_id,
      resource_account_name: this.props.abstract.account_name,
      resource_name: this.props.abstract.title,
    };

    this.props.trackAction(event);
  }

  navigateBio(site) {
    window.open(`${site}`, '_blank');
  }

  render() {
    const { classes } = this.props;

    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    // used in contact modal
    const keyValue = (key, value) => {
      if (value) {
        return (
          <>
            <b>{key}:</b> {value}
          </>
        );
      }
    };
    // Logic for briefcase. Currently not displaying on card in mobile view.
    // let favorited = false;

    let fallbackProfileImage = (
      <PersonIcon
        className={classes.linePhoto}
        style={{ width: '3rem', height: '3rem', background: '#CCCCCC' }}
      />
    );

    let characterCount = 0;
    let splitTitle = this.props.abstract.content.split(' ');
    let modifiedContent = '';
    let characterLimit = 200;

    splitTitle.map((string) => {
      characterCount = characterCount + string.length;

      if (characterCount <= characterLimit) {
        modifiedContent = modifiedContent + ' ' + string;
      }

      return string;
    });

    if (characterCount > characterLimit) {
      modifiedContent = modifiedContent + '...';
    }

    if (this.props.briefcase.data) {
      for (let n = 0; n < this.props.briefcase.data.length; n++) {
        if (
          this.props.briefcase.data[n].resource_id ===
          this.props.abstract.abstract_id
        ) {
          // favorited = true;
          break;
        }
      }
    }

    let level;
    let color;
    let lightColor;
    if (this.props.abstract.type !== 'Internal') {
      switch (this.props.abstract.sponsor_level) {
        case 'Signature Sponsor':
          color = '#0600b1';
          lightColor = '#554fff';
          level = 'Signature Sponsor';
          break;
        case 'Platinum Sponsor':
          color = '#996ae6';
          lightColor = '#c2a7f0';
          level = 'Platinum Sponsor';
          break;
        case 'Gold Sponsor':
          color = '#ff9a0d';
          lightColor = '#fcb044';
          level = 'Gold Sponsor';
          break;
        case 'Silver Sponsor':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Silver Sponsor';
          break;
        default:
          color = '#ffffff00';
          lightColor = '#ffffff00';
          level = '';
          break;
      }
    }

    let noPresenters = 0;
    return (
      <Paper elevation={this.state.isHovering ? 8 : 1}>
        <Grid
          container
          className={classes.rowEventRoot}
          onMouseEnter={() => {
            this.handleChange(true, 'isHovering');
            // this.handleChange(false, "buttonHover");
          }}
          onMouseLeave={() => this.handleChange(false, 'isHovering')}
          // onClick={this.state.contactOpen ? null : () => this.navigate()}
        >
          <Grid item xs={12}>
            {this.props.abstract.sponsor_level ? (
              <Typography
                variant="h5"
                className={classes.sponsorLevel}
                align="center"
                color="textPrimary"
                style={{
                  backgroundImage: `linear-gradient(${color}, ${lightColor})`,
                }}
              >
                {level}
              </Typography>
            ) : (
              <div style={{ marginTop: '4px' }} />
            )}
          </Grid>
          <Grid item container style={{ padding: '0px 8px' }}>
            <Grid item xs={9} align="center" className={classes.leftColumn}>
              {this.props.abstract.track ? (
                <Typography className={classes.lineTrack}>
                  TRACK: {this.props.abstract.track}
                </Typography>
              ) : (
                <React.Fragment />
              )}
              {this.props.abstract.title ? (
                <Typography className={classes.lineTitle}>
                  {this.props.abstract.title}
                </Typography>
              ) : (
                <React.Fragment />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.time}
                variant="body1"
                align="right"
                color="textPrimary"
              >
                {moment(this.props.abstract.start_time).format('h:mm a')}
              </Typography>
              <Typography
                className={classes.time}
                variant="body2"
                align="right"
                color="textSecondary"
                style={{ paddingTop: '0px' }}
              >
                - {moment(this.props.abstract.end_time).format('h:mm a')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} variant="fullWidth" />
              {this.props.abstract.topic === 'TBD' ? (
                <></>
              ) : (
                <Typography className={classes.lineSpeakerCompany}>
                  Topic:{' '}
                  {this.props.abstract.topic ? this.props.abstract.topic : ''}
                </Typography>
              )}
              {this.props.abstract.content === 'TBD' ? (
                <></>
              ) : (
                <Typography className={classes.lineSpeakerTitle}>
                  {this.props.abstract.content ? modifiedContent : ''}
                </Typography>
              )}
              <Divider className={classes.divider} variant="fullWidth" />
            </Grid>
            <Grid item container justify="space-evenly" xs={12}>
              {this.props.abstract.contacts
                ? this.props.abstract.contacts.map((presenter, index) => {
                    if (
                      presenter.roles.includes('presenter') ||
                      presenter.roles.includes('Presenter')
                    ) {
                      noPresenters = noPresenters + 1;
                      return (
                        <Grid item key={index} xs={3}>
                          <Button
                            onClick={() => this.handleContactOpen(presenter)}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                {presenter.photo_url ? (
                                  <img
                                    className={classes.linePhoto}
                                    src={presenter.photo_url}
                                    alt={'Presenter'}
                                  />
                                ) : (
                                  fallbackProfileImage
                                )}
                                <Typography
                                  variant="body1"
                                  className={classes.lineSpeakerName}
                                >
                                  {`${presenter.first_name} ${presenter.last_name}`}
                                </Typography>
                                {/* <Typography variant='body1' className={classes.lineSpeakerTitle}>
                              {presenter.title ? presenter.title : ''}
                            </Typography>
                            <Typography variant='body1' className={classes.lineSpeakerTitle}>
                              {presenter.department ? presenter.department : ''}
                            </Typography>
                            <Typography variant='body1' className={classes.lineSpeakerCompany}>
                              {presenter.account_name ? presenter.account_name : ''}
                            </Typography> */}
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                      );
                    }

                    return null;
                  })
                : null}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '4px' }}>
              <Button
                className={classes.detailsButton}
                variant="outlined"
                color="primary"
                onClick={(event) =>
                  this.props.toggleFullscreenHandlerOn(
                    event,
                    this.props.abstract
                  )
                } //() => this.navigate()
              >
                View All Details
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '4px' }}>
              <AddToCalendarButton
                className={classes.calendarContainer}
                event={this.props.calendarAbstract}
                items={[
                  SHARE_SITES.GOOGLE,
                  SHARE_SITES.ICAL,
                  SHARE_SITES.OUTLOOK,
                ]}
                dropdownProps={{
                  // onMouseLeave: () => this.handleChange(false, "buttonHover"),
                  header: (
                    <Typography
                      className={classes.linkStyles}
                      variant="h6"
                      color="textPrimary"
                    >
                      Select an option
                    </Typography>
                  ),
                  addAction: (e) => {
                    this.trackCalendarClick(e);
                  },
                }}
                buttonProps={{
                  variant: 'contained',
                  size: 'large',
                  color: 'primary',
                  className: classes.calendarButton,
                  startIcon: <AddIcon />,
                }}
                linkProps={{ className: classes.linkStyles }}
              />
            </Grid>
          </Grid>
          <DialogWrapper
            open={this.state.contactOpen}
            handleClose={() => this.handleContactClose()}
          >
            <Grid container justify="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                {this.state.currentContact.photo_url ? (
                  <img
                    className={classes.linePhoto}
                    src={this.state.currentContact.photo_url}
                    alt={'Presenter'}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.lineSpeakerName} variant="h6">
                  {this.state.currentContact.first_name
                    ? `${this.state.currentContact.first_name} ${this.state.currentContact.last_name}`
                    : null}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Title', this.state.currentContact.title)}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Account', this.state.currentContact.account_name)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue('Department', this.state.currentContact.department)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue(
                    'Expertise',
                    this.state.currentContact.expertise_area
                  )}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Email', this.state.currentContact.email)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue('Biography', this.state.currentContact.bio)}
                </Typography>
                <Divider className={classes.divider} variant="fullWidth" />
                <Button
                  size="large"
                  style={{ width: '40%', marginLeft: '30%', marginTop: '8px' }}
                  onClick={() => this.handleContactClose()}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogWrapper>
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(AgendaCard)));
