import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import WorkshopRowItem from './WorkshopRowItem';
import WorkshopRowItemMobile from './WorkshopRowItemMobile';
import { Typography, Grid, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '../../Components/DialogWrapper';
import WorkshopFull from './WorkshopFull';
import moment from 'moment';
import momentTimezone from 'moment-timezone'; //eslint-disable-line
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import { fallbackImages, eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';
import trackAction from '../../Store/Actions/tracker';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      2
    )}px ${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {},
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  card: {
    maxWidth: 345,
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class Workshop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      days: [],
      rooms: [],
      selectedDate: '',
      topicMap: null,
      currentAgendaItem: null,
      agendaSelected: false,
    };
  }

  currentItem = React.createRef();

  scrollToCurrent = () => {
    if (this.currentItem.current) {
      this.currentItem.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  componentDidMount() {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
            // filter_column: 'accepted_type',
            // filter_value: "Workshop",
          },
        })
          .then((response) => {
            let abstracts = [];
            let days = [];
            let rooms = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

              if (abstract.accepted_type === 'Workshop') {
                if (days.includes(abstract.date)) {
                  // do nothing
                } else {
                  days.push(abstract.date);
                }
                abstracts.push(abstract);
              }

              if (
                abstract.room &&
                !rooms.includes(abstract.room) &&
                abstract.start_time
              ) {
                if (abstract.room === 'FEATURED') {
                  rooms.unshift(abstract.room);
                } else {
                  rooms.push(abstract.room);
                }
              }
            }

            abstracts.sort((a, b) => {
              return (
                moment.duration(a.start_time).asMinutes() -
                moment.duration(b.start_time).asMinutes()
              );
            });

            days.sort();

            const currentDayIndex = days.findIndex(
              (day) => day.substring(0, 10) === moment().format('YYYY-MM-DD')
            );
            let currentDay;
            if (currentDayIndex > -1) {
              currentDay = days[currentDayIndex];
            } else {
              currentDay = days[0];
            }

            let currentAgendaItem = null;
            let agendaSelected = false;

            if (this.props.location.search) {
              const query = new URLSearchParams(this.props.location.search);
              for (let param of query.entries()) {
                if (param[0] === 'workshop_id') {
                  currentAgendaItem = abstracts.filter(
                    (abstract) => abstract.abstract_id === param[1]
                  )[0];
                  agendaSelected = true;
                }
              }
            }

            this.setState({
              abstracts,
              days,
              rooms,
              selectedDate: currentDay,
              topicMap: JSON.parse(this.props.event.event.topic_map),
              currentAgendaItem,
              agendaSelected,
            });

            // this.scrollToCurrent(); //potential
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Agenda. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Agenda. ${error}`);
        }
      });

    const event = {
      action: 'Viewed',
      resource_type: 'Workshop',
      resource_id: null,
      url_visited: '/workshop',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  async handleChange(event, inputIdentifier) {
    await this.setState({ [inputIdentifier]: event });
    if (moment().format('YYYY-MM-DD') === this.state.selectedDate) {
      await this.scrollToCurrent();
    }
  }

  toggleWorkshopFullscreenOn = (event, id) => {
    this.setState({
      agendaSelected: true,
      currentAgendaItem: id,
    });
  };

  toggleWorkshopFullscreenOff = () => {
    this.setState({
      agendaSelected: false,
      currentAgendaItem: null,
    });
  };

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/workshop') {
        pageTitle = route.title;
      }
    });

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );

    let referenceFound = false;

    let agendaItems = this.state.abstracts.map((abstract, index) => {
      if (this.state.selectedDate === abstract.date) {
        let requiredFields = [
          'description',
          'duration',
          'endDatetime',
          'location',
          'startDatetime',
          'title',
        ];
        // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
        let calendarAbstract = prepareObject(
          abstract,
          requiredFields,
          'presentation'
        );

        let agendaListImage;
        if (abstract.supporting_file_url) {
          agendaListImage = abstract.supporting_file_url;
        } else if (this.state.topicMap[abstract.room]) {
          agendaListImage = this.state.topicMap[abstract.room];
        } else if (this.state.topicMap[abstract.topic]) {
          agendaListImage = this.state.topicMap[abstract.topic];
        } else {
          agendaListImage = fallbackImages.agendaCard;
        }

        // let fakeNow = moment('October 29 2022 10:55').format('HH:mm'); // testing purposes
        let currentTime = moment().format('HH:mm');
        let timeIsNow = false;
        if (
          currentTime > moment(abstract.start_time).format('HH:mm') &&
          currentTime < moment(abstract.end_time).format('HH:mm') &&
          !referenceFound
        ) {
          timeIsNow = true;
          referenceFound = true;
        }

        return (
          <div key={index} id={`abstract${index}`}>
            {timeIsNow ? (
              <span
                ref={this.currentItem}
                style={{ position: 'relative', top: '-84px' }}
              />
            ) : null}
            <Hidden xsDown>
              <WorkshopRowItem
                key={index}
                abstract={abstract}
                topicMap={this.state.topicMap}
                calendarAbstract={calendarAbstract} // object needed to create calendar .ics
                roomImage={agendaListImage}
                selectedDate={this.state.selectedDate}
                toggleFullscreenHandlerOn={this.toggleWorkshopFullscreenOn.bind(
                  this
                )}
              />
            </Hidden>
            <Hidden smUp>
              <WorkshopRowItemMobile
                key={index}
                abstract={abstract}
                topicMap={this.state.topicMap}
                calendarAbstract={calendarAbstract} // object needed to create calendar .ics
                roomImage={agendaListImage}
                selectedDate={this.state.selectedDate}
                toggleFullscreenHandlerOn={this.toggleWorkshopFullscreenOn.bind(
                  this
                )}
              />
            </Hidden>
          </div>
        );
      } else {
        return null;
      }
    });

    let timezoneGuess = (
      <Typography
        variant="h6"
        color="textSecondary"
        align="center"
        style={{ padding: '16px 0px' }}
      >
        All times displayed are in your local time zone. ({moment.tz.guess()})
      </Typography>
    );

    return (
      <React.Fragment>
        <Grid
          container
          layout={'row'}
          spacing={2}
          justify="center"
          className={classes.header}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          {this.state.days.map((day, index) => {
            if (day === this.state.selectedDate) {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper
                    className={classes.paper + ' ' + classes.day}
                    variant="elevation"
                    elevation={10}
                  >
                    <Typography variant="h5">
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper className={classes.paper}>
                    <Typography variant="h5">
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
        {timezoneGuess}
        {moment().format('YYYY-MM-DD') === this.state.selectedDate ? (
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => this.scrollToCurrent()}
            >
              <Typography
                variant="button"
                // color="textSecondary"
                align="center"
                style={{ padding: '8px 64px', fontSize: '1.2rem' }}
              >
                Scroll to current time
              </Typography>
            </Button>
          </div>
        ) : null}
        {this.state.abstracts.length <= 0 ? loadingGraphic : null}
        {agendaItems}
        <Dialog
          open={this.state.agendaSelected}
          handleClose={this.toggleWorkshopFullscreenOff.bind(this)}
          scroll="body"
        >
          <WorkshopFull
            agenda={this.state.currentAgendaItem}
            closeAgenda={this.toggleWorkshopFullscreenOff.bind(this)}
            // handleUpvote={() => this.handleUpvotePosterFull(this.state.agendaId)}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    event: state.event,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Workshop));
