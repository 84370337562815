import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DataTable } from '../../Utilities/DataTable';
import { attendeeGridOptions } from './AttendeeGridOptions';
import clsx from 'clsx';
import axios from 'axios';
import { demoEnabled, eventId, syncServiceSid } from '../../config';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function AttendeeList(props) {
  const classes = useStyles();
  const [attendeeList, setAttendeeList] = useState([]);
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item

  useEffect(() => {
    const initialize = async () => {
      let data = await Auth.currentSession();

      await axios({
        method: 'get',
        url: `/show-attendees/directory?exhibitionId=${eventId}&syncServiceSid=${syncServiceSid}`,
        // url: 'attendees/',
        headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId, order_by: 'last_name', order_dir: 'asc' },
      })
        .then((response) => {
          setAttendeeList(response.data);
        })
        .catch((error) => console.log(error));
    };
    initialize().catch(console.error);
  }, []);

  //   return <div>Hello Attendee List</div>;
  return (
    <DataTable
      initialGridOptions={attendeeGridOptions}
      initialRowData={attendeeList}
      //   actionButtons={StaffGridActionButtons}
      type="attendee"
      setDeleting={(e) => this.handleDeletingChange(e, 'physical')}
      setEditing={(e) => this.handleEditingChange(e)}
      setCurrentPage={(e) => this.setcurrentStaff(e)}
    />
  );
}
