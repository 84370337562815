import React, { Component, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import trackAction from '../../Store/Actions/tracker';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { SHARE_SITES } from 'react-add-to-calendar-hoc';
import DialogWrapper from '../../Components/DialogWrapper';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';
import getValidUrl from '../../Utilities/getValidUrl';
import { posterSessionTopicImages } from '../../config';
import isOnline from '../../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';

import ChatProfile from '../../Components/ChatProfile';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';
import { eventId } from '../../config';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  rowEventRoot: {
    // cursor: "pointer",
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  media: {
    maxWidth: '80%',
    padding: theme.spacing(2),
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  centerColumn: {
    paddingLeft: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  time: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(
      0
    )}px ${theme.spacing(0)}px`,
    fontWeight: 'bold',
  },
  titleTrack: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    fontSize: 10,
  },
  track: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: '5px 0px 15px 0px',
    opacity: 0.85,
  },
  linePhoto: {
    borderRadius: '50%',
    width: '4rem',
    height: '4rem',
  },
  avatarPhoto: {
    width: '4rem',
    height: '4rem',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    borderRadius: '50%',
  },
  lineTrack: {
    fontSize: '0.8rem',
    fontWeight: 'italic',
    textAlign: 'left',
    // paddingTop: theme.spacing(2),
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'initial',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
    textTransform: 'initial',
  },
  lineSpeakerCompany: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'initial',
  },
  linkStyles: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1),
    color: 'black',
    fontSize: '1.25em',
  },
  presentationButton: {
    width: '80%',
    borderRadius: '5px',
    margin: theme.spacing(1),
  },
  detailsButton: {
    width: '96%',
    borderRadius: '5px',
    margin: theme.spacing(1),
  },
  calendarContainer: {
    display: 'flex',
  },
  calendarButton: {
    width: '100%',
    height: '100%',
    borderRadius: '0px 15px 0px 5px',
  },
  access: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  aboutMeBox: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    justifyContent: 'center',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    fontSize: '150%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
});

class AgendaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      // buttonHover: false,
      contactOpen: false,
      currentContact: {},
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  handleContactOpen(presenter) {
    this.setState({ contactOpen: true, currentContact: presenter });
  }
  handleContactClose() {
    this.setState({ contactOpen: false, currentContact: {} });
    // this.setState({buttonHover: true, contactOpen: false, currentContact: {}})
  }

  trackCalendarClick(e) {
    const event = {
      action: 'Added',
      resource_type: 'PersonalCalendar',
      resource_id: this.props.abstract.abstract_id,
      url_visited: '/agenda',
      resource_account_id: this.props.abstract.account_id,
      resource_account_name: this.props.abstract.account_name,
      resource_name: this.props.abstract.title,
    };

    this.props.trackAction(event);
  }

  navigate() {
    switch (this.props.abstract.accepted_type) {
      case 'Discussion Forum':
        this.props.history.push(
          `/discussionforum?presentation_id=${this.props.abstract.abstract_id}`
        );
        break;
      case 'ExhibitHall':
        this.props.history.push(`/exhibithall`);
        break;
      case 'PosterHall':
        this.props.history.push(`/posterhall`);
        break;
      case 'WorkshopHall':
        this.props.history.push(`/workshop`);
        break;
      default:
        if (this.props.abstract.archive_link) {
          const event = {
            action: 'Clicked',
            resource_type: 'Archive',
            resource_id: this.props.abstract.abstract_id,
            url_visited: '/agenda',
            // user_account_id: this.props.user.user.account_id,
            // new fields
            resource_account_id: this.props.abstract.account_id,
            resource_account_name: this.props.abstract.account_name,
            resource_name: this.props.abstract.title,
            //old fields
            // user_account_name: this.props.user.user.account_name,
            // user_email: this.props.user.user.email,
            // user_first_name: this.props.user.user.first_name,
            // user_last_name: this.props.user.user.last_name,
            // user_title: this.props.user.user.title,
            // user_department: this.props.user.user.department,
            // user_site: this.props.user.user.site,
          };

          this.props.trackAction(event);

          window.open(this.props.abstract.archive_link, '_blank');
        } else {
          if (this.props.abstract.presentation_link) {
            const event = {
              action: 'Clicked',
              resource_type: 'Presentation',
              resource_id: this.props.abstract.abstract_id,
              url_visited: '/agenda',
              // user_account_id: this.props.user.user.account_id,
              // new fields
              resource_account_id: this.props.abstract.account_id,
              resource_account_name: this.props.abstract.account_name,
              resource_name: this.props.abstract.title,
              //old fields
              // user_account_name: this.props.user.user.account_name,
              // user_email: this.props.user.user.email,
              // user_first_name: this.props.user.user.first_name,
              // user_last_name: this.props.user.user.last_name,
              // user_title: this.props.user.user.title,
              // user_department: this.props.user.user.department,
              // user_site: this.props.user.user.site,
            };

            this.props.trackAction(event);

            window.open(this.props.abstract.presentation_link, '_blank');
          }
        }
    }
    // }
    // this.setState({buttonHover: false})
  }

  navigateBio(site) {
    window.open(`${site}`, '_blank');
  }

  render() {
    const { classes } = this.props;
    const online = this.props.online;

    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    // used in contact modal
    const keyValue = (key, value) => {
      if (value) {
        return (
          <>
            <b>{key}:</b> {value}
          </>
        );
      }
    };

    let favorited = false;

    let fallbackProfileImage = (
      <PersonIcon
        className={classes.linePhoto}
        style={{ width: '4rem', height: '4rem', background: '#CCCCCC' }}
      />
    );

    let characterCount = 0;
    let splitTitle = this.props.abstract.content.split(' ');
    let modifiedContent = '';
    let characterLimit = 500;

    splitTitle.map((string) => {
      characterCount = characterCount + string.length;

      if (characterCount <= characterLimit) {
        modifiedContent = modifiedContent + ' ' + string;
      }

      return string;
    });

    if (characterCount > characterLimit) {
      modifiedContent = modifiedContent + '...';
    }

    if (this.props.briefcase.data) {
      for (let n = 0; n < this.props.briefcase.data.length; n++) {
        if (
          this.props.briefcase.data[n].resource_id ===
          this.props.abstract.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let level;
    let color;
    let lightColor;
    if (this.props.abstract.type !== 'Internal') {
      switch (this.props.abstract.sponsor_level) {
        case 'Signature Sponsor':
          color = '#0600b1';
          lightColor = '#554fff';
          level = 'Signature Sponsor';
          break;
        case 'Platinum Sponsor':
          color = '#996ae6';
          lightColor = '#c2a7f0';
          level = 'Platinum Sponsor';
          break;
        case 'Gold Sponsor':
          color = '#ff9a0d';
          lightColor = '#fcb044';
          level = 'Gold Sponsor';
          break;
        case 'Silver Sponsor':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Silver Sponsor';
          break;
        default:
          color = '#ffffff00';
          lightColor = '#ffffff00';
          level = '';
          break;
      }
    }

    let date = this.props.abstract.date.split('T')[0];
    let time = this.props.abstract.end_time;
    time = moment(this.props.abstract.end_time)
      .add(15, 'minutes')
      .format('HH:mm:ss');
    let timeNow = moment().format('HH:mm:ss');
    let dateNow = moment().format('YYYY-MM-DD');
    let isInThePast = false;

    switch (true) {
      case date < dateNow:
        // date is in the past
        isInThePast = true;
        break;
      case date === dateNow:
        // date is today
        if (time < timeNow) {
          // time is in the past
          isInThePast = true;
        } else {
          // time is in the future
        }
        break;
      default:
        // time is in the future
        break;
    }

    let archiveButton;
    if (this.props.abstract.archive_link) {
      archiveButton = (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.abstract.archive_link}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Button
            className={classes.presentationButton}
            variant="outlined"
            color="primary"
          >
            View Archived Presentation
          </Button>
        </a>
      );
    } else {
      archiveButton = (
        <Button
          disabled
          className={classes.presentationButton}
          variant="outlined"
          color="primary"
        >
          Archive Not Available
        </Button>
      );
    }

    return (
      <Paper elevation={this.state.isHovering ? 8 : 1}>
        <Grid
          container
          className={classes.rowEventRoot}
          onMouseEnter={() => {
            this.handleChange(true, 'isHovering');
            // this.handleChange(false, "buttonHover");
          }}
          onMouseLeave={() => this.handleChange(false, 'isHovering')}
        >
          <Grid item xs={2} align="center" className={classes.leftColumn}>
            <div>
              <CardMedia
                className={classes.cardMedia}
                image={
                  this.props.roomImage
                    ? this.props.roomImage
                    : posterSessionTopicImages['Default']
                }
              />
              <Typography className={classes.access}>
                {this.props.abstract.cleared_for_public_view ===
                'Yes - Approved'
                  ? 'Access: Public'
                  : 'Access: Private'}
              </Typography>
              {this.props.abstract.sponsor_level ? (
                <Typography
                  variant="h5"
                  className={classes.sponsorLevel}
                  align="center"
                  color="textPrimary"
                  style={{
                    backgroundImage: `linear-gradient(${color}, ${lightColor})`,
                  }}
                >
                  {level}
                </Typography>
              ) : (
                <React.Fragment />
              )}
              {this.props.abstract.presenter_linked_in_url ? (
                <Button
                  color="primary"
                  onClick={() =>
                    this.navigateBio(
                      getValidUrl(this.props.abstract.presenter_linked_in_url)
                    )
                  }
                  startIcon={<LinkedInIcon />}
                  // onMouseEnter={() => this.handleChange(true, "buttonHover")}
                  // onMouseLeave={() => this.handleChange(false, "buttonHover")}
                ></Button>
              ) : (
                <React.Fragment />
              )}
              <Tooltip
                title={
                  !online && (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
              >
                {favorited ? (
                  <IconButton
                    aria-label="remove from briefcase"
                    onClick={() => {
                      if (online) {
                        this.props.deleteBriefcaseElement(
                          this.props.abstract.abstract_id
                        );
                      }
                    }}
                    // onMouseEnter={() => this.handleChange(true, "buttonHover")}
                    // onMouseLeave={() => this.handleChange(false, "buttonHover")}
                  >
                    <WorkIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="add to briefcase"
                    onClick={() => {
                      if (online) {
                        this.props.addBriefcaseElement(
                          'Presentation',
                          this.props.abstract.abstract_id,
                          this.props.abstract.title
                        );
                      }
                    }}
                    // onMouseEnter={() => this.handleChange(true, "buttonHover")}
                    // onMouseLeave={() => this.handleChange(false, "buttonHover")}
                  >
                    <WorkOutlineIcon />
                  </IconButton>
                )}
              </Tooltip>
              <br />
              <div onClick={() => this.navigate()}>
                {isInThePast ? (
                  archiveButton
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.abstract.presentation_link}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Button
                      className={classes.presentationButton}
                      variant="outlined"
                      color="primary"
                    >
                      Launch Presentation
                    </Button>
                  </a>
                )}
              </div>
            </div>
            <div>
              <AddToCalendarButton
                className={classes.calendarContainer}
                event={this.props.calendarAbstract}
                items={[
                  SHARE_SITES.GOOGLE,
                  SHARE_SITES.ICAL,
                  SHARE_SITES.OUTLOOK,
                ]}
                dropdownProps={{
                  header: (
                    <Typography
                      className={classes.linkStyles}
                      variant="h6"
                      color="textPrimary"
                    >
                      Select an option
                    </Typography>
                  ),
                  addAction: (e) => {
                    this.trackCalendarClick(e);
                  },
                }}
                buttonProps={{
                  variant: 'contained',
                  size: 'large',
                  color: 'primary',
                  className: classes.calendarButton,
                  startIcon: <AddIcon />,
                }}
                linkProps={{ className: classes.linkStyles }}
              ></AddToCalendarButton>
            </div>
          </Grid>

          <Grid container item xs={10} className={classes.centerColumn}>
            <Grid item xs={9} style={{ padding: '16px 0px 0px 0px' }}>
              {this.props.abstract.track ? (
                <Typography className={classes.lineTrack}>
                  TRACK: {this.props.abstract.track}
                </Typography>
              ) : (
                <React.Fragment />
              )}
              {this.props.abstract.title ? (
                <Typography className={classes.lineTitle}>
                  {this.props.abstract.title}
                </Typography>
              ) : (
                <React.Fragment />
              )}
            </Grid>
            <Grid item xs={3} style={{ padding: '16px 0px 0px 0px' }}>
              <Typography
                className={classes.time}
                variant="h5"
                align="right"
                color="textPrimary"
              >
                {moment(this.props.abstract.start_time).format('h:mm a')}
              </Typography>
              <Typography
                className={classes.time}
                variant="h6"
                align="right"
                color="textSecondary"
                style={{ paddingTop: '0px' }}
              >
                - {moment(this.props.abstract.end_time).format('h:mm a')}
              </Typography>
            </Grid>
            <Grid className={classes.aboutMeBox} container item xs={4}>
              {this.props.abstract.contacts
                ? this.props.abstract.contacts
                    // contacts.order == null and sorting is throwing an error
                    // .sort((a, b) => {
                    //   if (a.order > b.order) {
                    //     return 1;
                    //   }
                    //   if (a.order < b.order) {
                    //     return -1;
                    //   }
                    //   if (a.order === b.order) {
                    //     return 0;
                    //   }
                    //   return null;
                    // })
                    .map((presenter, index) => {
                      if (
                        presenter.roles.includes('presenter') ||
                        presenter.roles.includes('Presenter')
                      ) {
                        return (
                          <Grid item key={index} xs={6}>
                            <Button
                              onClick={async () => {
                                this.handleContactOpen(presenter);

                                const me = this.props.user.user;

                                const isMe =
                                  getTwilioUserIdentity(me) ===
                                  presenter?.email;

                                if (!isMe && presenter?.email) {
                                  this.props
                                    .twilioCreateJoinOneOnOneConversation(
                                      presenter?.email
                                    )
                                    .catch((error) => {
                                      console.log(
                                        '🚀 ~ file: AgendaRowItem.js ~ line 622 ~ onClick={ ~ User not registered',
                                        error.message
                                      );
                                    });
                                }
                              }}
                              // onMouseEnter={() => this.handleChange(true, "buttonHover")}
                              // onMouseLeave={() => this.handleChange(false, "buttonHover")}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  {/* {presenter.photo_url ? ( */}
                                  {/* <img
                                  className={classes.linePhoto}
                                  src={presenter.photo_url}
                                  alt={'Presenter'}
                                /> */}
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <ChatProfile
                                      identity={presenter?.email}
                                      size={'4rem'}
                                      photo_url={presenter?.photo_url}
                                      directory={undefined}
                                      onClick={undefined}
                                    />
                                  </div>
                                  {/* ) : (
                                fallbackProfileImage
                              )} */}
                                  <Typography
                                    variant="body1"
                                    className={classes.lineSpeakerName}
                                  >
                                    {`${presenter.first_name} ${presenter.last_name}`}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className={classes.lineSpeakerTitle}
                                  >
                                    {presenter.title ? presenter.title : ''}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className={classes.lineSpeakerTitle}
                                  >
                                    {presenter.department
                                      ? presenter.department
                                      : ''}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className={classes.lineSpeakerCompany}
                                  >
                                    {presenter.account_name
                                      ? presenter.account_name
                                      : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Button>
                          </Grid>
                        );
                      }

                      return null;
                    })
                : null}
            </Grid>
            <Grid item xs={8} style={{ padding: '0px 16px 0px 32px' }}>
              {this.props.abstract.topic === 'TBD' ? (
                <></>
              ) : (
                <Typography className={classes.lineSpeakerCompany}>
                  Topic:{' '}
                  {this.props.abstract.topic ? this.props.abstract.topic : ''}
                </Typography>
              )}
              {this.props.abstract.content === 'TBD' ? (
                <></>
              ) : (
                <pre style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  <Typography className={classes.lineSpeakerTitle}>
                    {this.props.abstract.content ? modifiedContent : ''}
                  </Typography>
                </pre>
              )}
            </Grid>
            <Grid item xs={4} />
            <Grid
              item
              xs={8}
              style={{ alignSelf: 'flex-end', paddingLeft: '16px' }}
            >
              <Button
                className={classes.detailsButton}
                variant="outlined"
                color="primary"
                onClick={(event) => {
                  console.log(
                    '🚀 ~ file: AgendaRowItem.js ~ line 768 ~ render ~ this.props.abstract',
                    this.props.abstract
                  );

                  this.props.toggleFullscreenHandlerOn(
                    event,
                    this.props.abstract
                  );
                }}
              >
                View All Details
              </Button>
            </Grid>
          </Grid>
          <DialogWrapper
            open={this.state.contactOpen}
            handleClose={() => this.handleContactClose()}
            header={undefined}
            children={undefined}
            footer={undefined}
          >
            <Grid container justify="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                {/* {this.state.currentContact.photo_url && ( */}
                <div
                  alt={'Presenter'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <ChatProfile
                    identity={this.state?.currentContact?.email}
                    size={'4rem'}
                    photo_url={this.state?.currentContact?.photo_url}
                    directory={undefined}
                    onClick={undefined}
                  />
                  {/* // </div> */}
                  {/* <img
                      className={classes.linePhoto}
                      src={this.state.currentContact.photo_url}
                      alt={'Presenter'}
                    /> */}
                </div>
                {/* // )} */}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.lineSpeakerName} variant="h6">
                  {this.state.currentContact.first_name
                    ? `${this.state.currentContact.first_name} ${this.state.currentContact.last_name}`
                    : null}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Title', this.state.currentContact.title)}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Account', this.state.currentContact.account_name)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue('Department', this.state.currentContact.department)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue(
                    'Expertise',
                    this.state.currentContact.expertise_area
                  )}
                </Typography>
                <Typography className={classes.lineSpeakerInfo}>
                  {keyValue('Email', this.state.currentContact.email)}
                </Typography>
                <Typography variant="body1" className={classes.lineSpeakerInfo}>
                  {keyValue('Biography', this.state.currentContact.bio)}
                </Typography>
                <Divider className={classes.divider} variant="fullWidth" />
                <Button
                  size="large"
                  style={{ width: '40%', marginLeft: '30%', marginTop: '8px' }}
                  onClick={() => this.handleContactClose()}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogWrapper>
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    user: state.user,
    online: isOnline(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      twilioCreateJoinOneOnOneConversation:
        twilioCreateJoinOneOnOneConversation,
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(AgendaCard)));
