import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversationsInitialized: false,
  contactsInitialized: false,
  hereNowInitialized: false,
  conversationsClient: null,
  syncClient: null,
  contactsClient: null,
  hereNowClient: null,
  token: null,
  user: null,
  users: {},
  contacts: {},
  unread: {},
  conversations: {},
  conversationsMessages: {},
  herenow: {},
  joinedConversations: [],
  openConversationSid: null, // ChatConversation is watching this and will populate openConversation
  // openConversation: {
  //   // sidebar: {
  //   //   sid: null, // the conversation sid or uniqueName to load
  //   //   conversation: null, // (twilio conversation)
  //   //   messages: null, // (twilio messages)
  //   //   participants: null, // (array twilio particiapants)
  //   //   loading: false,
  //   //   creating: false,
  //   //   myParticipant: null, // (this user's participant - attributes => joined true / false)
  //   // },
  // },
  // openConversationMessages: [],
  // what conversations are open in windows
  openConversations: {},
  creatingConversation: false,
  chatWindow: false,
  openProfile: null,
  engagement: '',
  notification: 'default',
  triggerNotification: 0,
};

export const getUnreadCount = (lastMessageIndex, lastReadMessageIndex) =>
  // There is 1 message, but the user hasn't read any
  lastMessageIndex >= 0 && lastReadMessageIndex === null
    ? lastMessageIndex + 1
    : lastMessageIndex - lastReadMessageIndex;

// Pull attributes and other useful information out of the user client
const storeUser = (client) => ({
  client,
  identity: client.identity,
  isNotifiable: client.isNotifiable,
  isOnline: client.isOnline,
  isSubscribed: client.isSubscribed,
  attributes: { ...client.attributes },
});
// Pull attributes and other useful information out of the user client
export const storeParticipant = (client) => ({
  client,
  identity: client.identity,
  attributes: { ...client.attributes },
});
export const storeMessage = ({
  sid,
  author,
  body,
  dateCreated,
  dateUpdated,
  participantSid,
  subject,
  type,
  media,
}) => ({
  sid,
  author,
  body,
  dateCreated,
  dateUpdated,
  participantSid,
  subject,
  type,
  media,
});
export const storeMessages = (messages) => ({
  client: messages,
  hasNextPage: messages.hasNextPage,
  hasPrevPage: messages.hasPrevPage,
  messages: [...messages.items],
});

// Pull attributes and other useful information out of the user client
export const storeConversation = (client, user, lastMessage) => {
  // return client;
  // console.log(
  //   '🚀 ~ file: twilio.js ~ line 30 ~ storeConversation ~ client.lastMessage',
  //   client.lastMessage
  // );
  // console.log(
  //   '🚀 ~ file: twilio.js ~ line 32 ~ storeConversation ~ client.lastReadMessageIndex',
  //   client.lastReadMessageIndex
  // );
  const conv = {
    sid: client.sid,
    client,
    friendlyName: `${client.friendlyName}`,
    uniqueName: `${client.uniqueName}`,
    unread: getUnreadCount(
      client?.lastMessage?.index,
      client.lastReadMessageIndex
    ),
    lastMessageCreated: client.lastMessage?.dateCreated,
    participantCount: client.participants.size,
    attributes: { ...client.attributes },
  };
  // add participants
  if (client?.participants) {
    conv.participants = {};
    client.participants.forEach((participant) => {
      conv.participants[participant.identity] = storeParticipant(participant);
      // Add my Particiapnt Attributes to the root
      if (user && participant.identity === user.identity) {
        conv.myAttributes = { ...participant.attributes };
      }
    });
  }
  if (lastMessage) {
    conv.lastMessage = lastMessage;
  }
  return conv;
};

export const twilioSlice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {
    setConversationsInitialized: (state, action) => {
      state.conversationsInitialized = action.payload;
    },
    setContactsInitialized: (state, action) => {
      state.contactsInitialized = action.payload;
    },
    setHereNowInitialized: (state, action) => {
      state.hereNowInitialized = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setConversationsClient: (state, action) => {
      state.conversationsClient = action.payload;
    },
    setSyncClient: (state, action) => {
      state.syncClient = action.payload;
    },
    setUser: (state, action) => {
      state.user = storeUser(action.payload);
    },
    addUser: (state, action) => {
      // console.log("🚀 ~ file: twilio.js ~ line 45 ~ action.payload.identity", action.payload.identity)
      // state.users = state.users[action.payload.identity] = storeUser(
      //   action.payload
      // );
      if (
        state.user?.client?.identity &&
        action.payload.identity === state.user.client.identity
      ) {
        // console.log('ADD USER ME');
        state.user = storeUser(action.payload);
      }
      // console.log('ADD USER', action.payload.identity);
      state.users[action.payload.identity] = storeUser(action.payload);
    },
    removeUser: (state, action) => {
      delete state.users[action.payload.identity];
    },
    updateUser: (state, action) => {
      if (
        state.user?.client?.identity &&
        action.payload.identity === state.user.client.identity
      ) {
        console.log('UPDATING ME');
        state.user = storeUser(action.payload);
      }
      // console.log(
      //   '🚀 ~ file: twilio.js ~ line 58 ~ action.payload.identity',
      //   action.payload.identity
      // );
      state.users[action.payload.identity] = storeUser(action.payload);
    },
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    setConversation: (state, action) => {
      state.conversations[action.payload.sid] = action.payload;
    },
    // addedConversation: (state, action) => {
    //   state.conversations[action.payload.sid] = storeConversation(
    //     action.payload.conversation,
    //     action.payload.user
    //   );
    // },
    removedConversation: (state, action) => {
      delete state.conversations[action.payload.sid];
    },
    joinedConversation: (state, action) => {
      // state.conversations[action.payload.sid] = action.payload;
      state.joinedConversations.push(action.payload.sid);
    },
    leftConversation: (state, action) => {
      // delete state.conversations[action.payload.sid];
      state.joinedConversations = state.joinedConversations.filter(
        (sid) => sid !== action.payload.sid
      );
    },
    addParticipantMessage: (state, action) => {
      if (
        state.participantsMessages[action.payload.conversation.sid]?.messages
      ) {
        state.participantsMessages[
          action.payload.conversation.sid
        ].messages.push(action.payload);
      }
    },

    setConversationsMessages: (state, action) => {
      state.conversationsMessages = action.payload;
    },
    setConversationMessages: (state, action) => {
      state.conversationsMessages[action.payload.sid] = action.payload.messages;
    },
    addConversationMessage: (state, action) => {
      if (
        state.conversationsMessages[action.payload.conversation.sid]?.messages
      ) {
        state.conversationsMessages[
          action.payload.conversation.sid
        ].messages.push(action.payload);
      }
    },

    setContactsClient: (state, action) => {
      state.contactsClient = action.payload;
    },
    // addHereNow: (state, action) => {
    //   state.herenow[action.payload.key] = { ...action.payload.data };
    // },
    // removeHereNow: (state, action) => {
    //   delete state.herenow[action.payload.key];
    // },
    // updateHereNow: (state, action) => {
    //   state.herenow[action.payload.key] = { ...action.payload.data };
    // },
    addContact: (state, action) => {
      state.contacts[action.payload.key] = { ...action.payload.data };
    },
    removeContact: (state, action) => {
      delete state.contacts[action.payload.key];
    },
    updateContact: (state, action) => {
      state.contacts[action.payload.key] = { ...action.payload.data };
    },
    setUnread: (state, action) => {
      state.unread[action.payload.sid] = action.payload.count;
    },
    setOpenConversationSid: (state, action) => {
      state.openConversationSid = action.payload;
    },
    setOpenConversation: (state, action) => {
      const { page, conversation } = action.payload;

      if (!state.openConversations) state.openConversations = {};
      if (conversation) {
        state.openConversations[page] = conversation;
      } else {
        delete state.openConversations[page];
      }
    },
    setOpenConversations: (state, action) => {
      state.openConversations = action.payload;
    },
    addOpenConversations: (state, action) => {
      const sid = action.payload;
      if (!state.openConversations.includes(sid)) {
        state.openConversations.push(sid);
      }
    },
    removeOpenConversations: (state, action) => {
      const sid = action.payload;
      var myIndex = state.openConversations.indexOf(sid);
      if (myIndex !== -1) {
        state.openConversations.splice(myIndex, 1);
      }
    },
    setOpenProfile: (state, action) => {
      state.openProfile = action.payload;
    },
    resetTwilio: () => initialState,
    setCreatingConversation: (state, action) => {
      state.creatingConversation = action.payload;
    },
    setChatWindow: (state, action) => {
      // console.log('🚀 ~ file: twilio.js ~ line 130 ~ action', action);

      state.chatWindow = action.payload;
    },
    setEngagement: (state, action) => {
      state.engagement = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setTriggerNotification: (state, action) => {
      state.triggerNotification = new Date().valueOf();
    },
  },
});

// Selectors
export const getToken = (state) => state.twilio.token;
export const getInitilized = (state) =>
  state.twilio.conversationsInitialized &&
  // state.twilio.contactsInitialized &&
  state.twilio.hereNowInitialized;
export const getConversationsClient = (state) =>
  state.twilio.conversationsClient;
export const getContactsClient = (state) => state.twilio.contactsClient;
export const getSyncClient = (state) => state.twilio.syncClient;
export const getUser = (state) => state.twilio.user;
export const getUsers = (state) => state.twilio.users;
export const getConversations = (state) => state.twilio.conversations;
export const getConversationsMessages = (state) =>
  state.twilio.conversationsMessages;
export const getOpenConversationSid = (state) =>
  state.twilio.openConversationSid;
export const getOpenConversations = (state) => state.twilio.openConversations;
export const getCreatingConversation = (state) =>
  state.twilio.creatingConversation;
export const getChatWindow = (state) => state.twilio.chatWindow;
export const getOpenProfile = (state) => state.twilio.openProfile;
export const getTriggerNotification = (state) =>
  state.twilio.triggerNotification;

// each case under reducers becomes an action
export const {
  setConversationsInitialized,
  setContactsInitialized,
  setHereNowInitialized,
  setToken,
  setConversationsClient,
  setSyncClient,
  setContactsClient,
  setUser,
  addUser,
  removeUser,
  updateUser,
  setConversations,
  setConversation,
  // addedConversation,
  removedConversation,
  joinedConversation,
  leftConversation,
  addParticipantMessage,
  setConversationsMessages,
  setConversationMessages,
  addConversationMessage,
  // addHereNow,
  // removeHereNow,
  // updateHereNow,
  addContact,
  removeContact,
  updateContact,
  setOpenConversationSid,
  setOpenConversation,
  setOpenConversations,
  addOpenConversations,
  removeOpenConversations,
  resetTwilio,
  setCreatingConversation,
  setChatWindow,
  setOpenProfile,
  setUnread,
  setEngagement,
  setNotification,
  setTriggerNotification,
} = twilioSlice.actions;

export default twilioSlice.reducer;
