import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import getValidUrl from './getValidUrl';

function descendingComparator(a, b, orderBy) {
  // toLowerCase is necessary when comparing capitalized string to lowercase string
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function DataTable({
  initialGridOptions,
  initialRowData,
  actionButtons,
  type,
  disableSort,
}) {
  const [
    gridOptions, // setGridOptions
  ] = useState(() => initialGridOptions);
  const [rowData, setRowData] = useState(() => initialRowData);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  // const [selected, setSelected] = React.useState([]);
  const [
    page, // setPage
  ] = React.useState(0);
  const [
    rowsPerPage, // setRowsPerPage
  ] = React.useState(1000);

  useEffect(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  const handleRequestSort = (event, property) => {
    // console.log(event, property);
    const isAsc = orderBy === property && order === 'asc';
    // console.log('isAsc', isAsc);
    // console.log('orderBy', orderBy);
    // console.log(orderBy);
    // console.log('order', order);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // console.log('isAsc', isAsc);
  };

  //   function handleEditClick(id, infoObject) {
  //     setEditing(true);
  //     setCurrentPage(infoObject);

  //     // return setCurrentPage(null);
  //   }

  //   function handleDeleteClick(id, infoObject) {
  //     setDeleting(true);
  //     setCurrentPage(infoObject);

  //     // return setCurrentPage(null);
  //   }

  function EnhancedTableHead(props) {
    const {
      // classes, onSelectAllClick,
      order,
      orderBy,
      // numSelected, rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {gridOptions.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={!disableSort && createSortHandler(headCell.id)}
                hideSortIcon={disableSort && true}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnchancedTableRows(props) {
    const { id, row, index, tabIndex, type } = props;
    let currentRowKeys = Object.keys(row);
    let currentRowValues = Object.values(row);
    let typeId = `${type}_id`;

    // let color;
    // let lightColor;

    // if (
    //   JSON.parse(this.props.event.event.rotating_sponsors).sponsors.length > 0
    // ) {
    //   let color;
    //   let lightColor;
    //   switch (level) {
    //     case 'Platinum':
    //       color = '#996ae6';
    //       lightColor = '#c2a7f0';
    //       // level = 'Platinum';
    //       break;
    //     case 'Gold':
    //       color = '#f2cd05';
    //       lightColor = '#f7e372';
    //       // level = 'Gold';
    //       break;
    //     default:
    //       color = '#9e9e9e';
    //       lightColor = '#c0c0c0';
    //       // level = 'Silver';
    //       break;
    //   }
    // }

    if (row?.email?.includes('@planetconnect.com')) {
      // don't show us in attendee list
      return null;
    } else
      return (
        <TableRow hover tabIndex={tabIndex} key={`${type}.${id}`}>
          {gridOptions.map((gridOption) => {
            return (
              <TableCell
                key={`${id}.${gridOption.id}.${index}`}
                align="left"
                style={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'normal',
                }}
              >
                {currentRowKeys.map((key, index) => {
                  let keyId = `${key}.${id}`;
                  let value;
                  if (key === gridOption.id && key !== typeId) {
                    let url = getValidUrl(currentRowValues[index]);
                    switch (key) {
                      case 'website':
                        value = (
                          <a
                            href={url}
                            alt="Our Website"
                            key={keyId}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {url}
                          </a>
                        );
                        break;
                      case 'sponsorship_level':
                        let color;
                        let lightColor;
                        switch (currentRowValues[index]) {
                          case 'Signature':
                            color = '#0600b1';
                            lightColor = '#554fff';
                            break;
                          case 'Platinum':
                            color = '#996ae6';
                            lightColor = '#c2a7f0';
                            // level = 'Platinum';
                            break;
                          case 'Gold':
                            color = '#f2cd05';
                            lightColor = '#f7e372';
                            // level = 'Gold';
                            break;
                          case 'Silver':
                            color = '#9e9e9e';
                            lightColor = '#c0c0c0';
                            // level = 'Silver';
                            break;
                          case 'Booth':
                            color = '#b4d2e5';
                            lightColor = '#c9eaff';
                            // level = 'Booth';
                            break;
                          default:
                            color = '#b4e5ca';
                            lightColor = '#c9ffe2';
                            // level = 'Tabletop';
                            break;
                        }
                        value = (
                          <Typography
                            variant="h6"
                            align="center"
                            color="textPrimary"
                            style={{
                              width: '100%',
                              color: 'white',
                              position: 'relative',
                              textShadow: '0px 5px 20px #000000CC',
                              borderRadius: '5px',
                              backgroundImage: `linear-gradient(${color}, ${lightColor})`,
                            }}
                          >
                            {currentRowValues[index]}
                          </Typography>
                        );
                        break;
                      case 'account_name':
                        value = (
                          <Typography variant="body1" key={keyId}>
                            {currentRowValues[index]}
                          </Typography>
                        );
                        break;
                      case 'booth_number':
                        value = (
                          <Typography variant="body1" key={keyId}>
                            {currentRowValues[index]}
                          </Typography>
                        );
                        break;
                      // case 'count':
                      //   value = (
                      //     <Button
                      //       variant="contained"
                      //       disableElevation
                      //       alt="Count"
                      //       key={keyId}
                      //       onClick={() =>
                      //         handleEditClick(currentRowValues[index], row)
                      //       }
                      //       style={{ width: '80%' }}
                      //     >
                      //       {currentRowValues[index]}
                      //     </Button>
                      //   );
                      //   break;
                      default:
                        value = (
                          <Typography variant="body2" key={keyId}>
                            {currentRowValues[index]}
                          </Typography>
                        );
                        break;
                    }
                    return value;
                  } else if (key === gridOption.id && key === typeId) {
                    value = actionButtons(
                      currentRowValues[index],
                      keyId
                      // () => handleEditClick(currentRowValues[index], row),
                      // () => handleDeleteClick(currentRowValues[index], row)
                    );
                    return value;
                  } else {
                    return null;
                  }
                })}
              </TableCell>
            );
          })}
        </TableRow>
      );
  }

  function TableData(props) {
    return (
      <TableContainer>
        <Table size="medium">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rowData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const abstractId = row.abstract_id;
                const productId = row.product_id;
                const attendeeId = row.attendee_id;
                let finalId;
                switch (type) {
                  case 'abstract':
                    finalId = abstractId;
                    break;
                  case 'product':
                    finalId = productId;
                    break;
                  case 'attendee':
                    finalId = attendeeId;
                    break;
                  default:
                    finalId = 'NotFound';
                }
                return (
                  <EnchancedTableRows
                    key={`${index}.${finalId}`}
                    index={index}
                    row={row}
                    tabIndex={-1}
                    id={finalId}
                    type={type}
                    // handleEditClick={handleEditClick}
                    // handleDeleteClick={handleDeleteClick}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return <TableData />;
}
