import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import ImageMapper from 'react-image-mapper';
import { connect } from 'react-redux';
import AuditoriumImage from '../Assets/NetworkingSessions.jpg';
import Dialog from '../Components/DialogWrapper';
import trackAction from '../Store/Actions/tracker';
import moment from 'moment';
import { eventId } from '../config';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// config
import { drawerWidth } from '../config';
import { bindActionCreators } from 'redux';
import { queryApi } from '../Services/queryApi';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  gridContainer: {
    margin: '0px -24px -24px -24px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
    },
  },
  table: {},
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'fixed',
    zIndex: '2',
    paddingRight: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'fixed',
    zIndex: '2',
    backgroundColor: '#efefef',
    borderRadius: '8px',
  },
  backButton: {
    position: 'relative',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  preSelectedRow: {
    background: '#6f1a1d33',
  },
  mobileButtonsText: {
    padding: `${theme.spacing(8)}px 0px`,
  },
  mobileButtons: {
    width: '100%',
    height: '100%',
  },
  mobileGridForButton: {
    margin: `${theme.spacing(4)}px 0px 0px`,
  },
  mobileLineItemContainer: {
    backgroundColor: '#EFEFEF',
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    border: `solid ${theme.palette.primary.main}`,
  },
  mobileGridSpacing: {
    padding: theme.spacing(0.5),
    // marginBottom: theme.spacing(1),
  },
});

class Auditorium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      imageWidth: window.innerWidth * 0.955,
      modalOpen: true,
      upcomingWebinar: true,
      archivedWebinar: false,
      roomName: 'Discussion Forum',
      preSelectedPresentation: null,
    };
  }

  MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'UpcomingWebinars',
        shape: 'poly',
        coords: [
          478,
          630, //bottom left
          478,
          220, //top left
          1525,
          220, //top right
          1525,
          630, //bottom right
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/auditorium',
      },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCoordinates);

    Auth.currentSession()
      .then((data) => {
        this.props.getNetworking();
        if (this.props.location.search) {
          const query = new URLSearchParams(this.props.location.search);
          for (let param of query.entries()) {
            if (param[0] === 'presentation_id') {
              this.setState({
                modalOpen: true,
                upcomingWebinar: true,
                preSelectedPresentation: param[1],
              });
            }
          }
        }

        // axios({
        //   method: 'get',
        //   url: `/agenda/${eventId}`,
        //   headers: { idtoken: data.idToken.jwtToken },
        //   params: {
        //     order_by: 'start_time',
        //     order_dir: 'asc',
        //     filter_column: 'accepted_type',
        //     filter_value: 'Networking',
        //   },
        // })
        //   .then(
        //     (response) => {
        //       if (this.props.location.search) {
        //         const query = new URLSearchParams(this.props.location.search);
        //         for (let param of query.entries()) {
        //           if (param[0] === 'presentation_id') {
        //             this.setState({
        //               modalOpen: true,
        //               upcomingWebinar: true,
        //               preSelectedPresentation: param[1],
        //             });
        //           }
        //         }
        //       }

        //       let authorizedPresentations = [];

        //       for (let x = 0; x < response.data.data.length; x++) {
        //         let presentation = response.data.data[x];
        //         // this line of code is because I needed the presentation dates to match after formatting through moment.js
        //         presentation.date = moment(
        //           presentation.start_time,
        //           'YYYY-MM-DD'
        //         ).format();
        //         if (presentation.start_time) {
        //           authorizedPresentations.push(presentation);
        //         }
        //       }

        //       // Sort by month, sort by day, sort by time
        //       authorizedPresentations = authorizedPresentations
        //         .sort(
        //           (a, b) =>
        //             moment(a.date).format('MM') - moment(b.date).format('MM')
        //         ) // by month
        //         .sort(
        //           (a, b) =>
        //             moment(a.date).format('DD') - moment(b.date).format('DD')
        //         ) // by day
        //         .sort((a, b) => {
        //           if (a.date === b.date) {
        //             return (
        //               moment.duration(a.start_time).asMinutes() -
        //               moment.duration(b.start_time).asMinutes()
        //             );
        //           } else {
        //             return 1;
        //           }
        //         }); // by time

        //       this.setState({
        //         webinars: authorizedPresentations,
        //       });
        //     },
        //     (error) => {
        //       this.setState({
        //         webinars: [],
        //       });
        //       if (this.props.debug.debug) {
        //         alert(`Error: Could not display the Auditorium. ${error}`);
        //       }
        //     }
        //   )
        //   .catch((error) => {
        //     if (this.props.debug.debug) {
        //       alert(`Error: Could not display the Auditorium. ${error}`);
        //     }
        //   });
      })
      .catch((error) => {
        this.setState({
          webinars: [],
        });
        if (this.props.debug.debug) {
          alert(`Error: Could not display the Auditorium. ${error}`);
        }
      });

    const event = {
      action: 'Viewed',
      resource_type: 'Networking',
      resource_id: null,
      url_visited: '/networking',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event) {
    if (event.name === 'UpcomingWebinars') {
      this.setState({
        modalOpen: true,
        upcomingWebinar: true,
      });
    } else {
      this.setState({
        modalOpen: true,
        archivedWebinar: true,
      });
    }
  }

  handleClose() {
    console.log('on closing');
    this.setState({
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
    });
  }

  viewPresentation(webinar) {
    const event = {
      action: 'Clicked',
      resource_type: 'Discussion',
      resource_id: webinar.abstract_id,
      url_visited: '/discussionforum',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    };

    this.props.trackAction(event);

    if (webinar.presentation_link) {
      window.open(webinar.presentation_link, '_blank');
    }
  }

  viewArchive(webinar) {
    const event = {
      action: 'Clicked',
      resource_type: 'DicussionRecording',
      resource_id: webinar.abstract_id,
      url_visited: '/discussionforum',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    };

    this.props.trackAction(event);
    if (webinar.archive_link) {
      window.open(webinar.archive_link, '_blank');
    }
  }

  render() {
    const { classes } = this.props;

    const networking = this.props.networking?.data?.data?.map((networking) => ({
      ...networking,
    }));

    let authorizedPresentations = [];

    for (let x = 0; x < networking?.length; x++) {
      let presentation = networking[x];
      // this line of code is because I needed the presentation dates to match after formatting through moment.js
      presentation.date = moment(
        presentation.start_time,
        'YYYY-MM-DD'
      ).format();
      if (presentation.start_time) {
        authorizedPresentations.push(presentation);
      }
    }

    // Sort by month, sort by day, sort by time
    authorizedPresentations = authorizedPresentations
      .sort((a, b) => moment(a.date).format('MM') - moment(b.date).format('MM')) // by month
      .sort((a, b) => moment(a.date).format('DD') - moment(b.date).format('DD')) // by day
      .sort((a, b) => {
        if (a.date === b.date) {
          return (
            moment.duration(a.start_time).asMinutes() -
            moment.duration(b.start_time).asMinutes()
          );
        } else {
          return 1;
        }
      }); // by time

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/discussionforum') {
        pageTitle = route.title;
      }
    });

    let loadingGraphic = (
      <Grid container align="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <br />
          <br />
          <CircularProgress color="inherit" />
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );

    let webinars;
    if (this.state.modalOpen) {
      if (this.state.archivedWebinar) {
        webinars = (
          <React.Fragment>
            <Typography variant="h4" gutterBottom align="center">
              Recorded Presentations
            </Typography>
            {/* THIS IS THE DESTKOP SIZE CONTENT */}
            <Hidden xsDown>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {authorizedPresentations.map((webinar, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            className={
                              this.state.preSelectedPresentation ===
                              webinar.abstract_id
                                ? classes.preSelectedRow
                                : ''
                            }
                          >
                            <TableCell
                              align="left"
                              style={{ maxWidth: '40vw' }}
                            >
                              <b>Title: </b>
                              {webinar.title}
                              <br />
                              <br />
                              {webinar.presenters[0] ? (
                                <React.Fragment>
                                  <b>Presenter: </b>
                                  {webinar.presenters[0].first_name +
                                    ' ' +
                                    webinar.presenters[0].last_name +
                                    ' - ' +
                                    webinar.presenters[0].title}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <br />
                              {webinar.account_name ? (
                                <React.Fragment>
                                  <b>Company: </b>
                                  {webinar.account_name}{' '}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <br />
                              {webinar.cleared_for_public_view ===
                              'Yes - Approved' ? (
                                <React.Fragment>
                                  <b>Visibility: </b>Public
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <b>Visibility: </b>Private
                                </React.Fragment>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {webinar.start_time ? (
                                <React.Fragment>{`${moment(
                                  webinar.start_time,
                                  'HH:mm:ss'
                                ).format('hh:mm a')} `}</React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {webinar.date ? (
                                `${moment(webinar.date.substring(0, 10)).format(
                                  'dddd'
                                )}, ${moment(
                                  webinar.date.substring(0, 10)
                                ).format('MMM')} ${moment(
                                  webinar.date.substring(0, 10)
                                ).format('Do')}`
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {webinar.archive_link ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  target="#blank"
                                  onClick={() => this.viewArchive(webinar)}
                                >
                                  View Replay
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled
                                >
                                  Coming Soon
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {authorizedPresentations.length <= 0 ? loadingGraphic : null}
            </Hidden>
            {/* THIS IS THE MOBILE CONTENT */}
            <Hidden smUp>
              {authorizedPresentations.length <= 0 ? loadingGraphic : null}
              {authorizedPresentations.map((webinar, index) => {
                webinar.presenters = webinar.contacts
                  .filter(
                    (contact) => contact.role.toLowerCase() === 'presenter'
                  )
                  .sort((a, b) => {
                    if (a.order === null) {
                      a.order = 1000;
                    }

                    if (b.order === null) {
                      b.order = 1000;
                    }

                    return a.order - b.order;
                  });
                return (
                  <Grid
                    container
                    spacing={1}
                    justify="flex-start"
                    alignItems="stretch"
                    className={classes.mobileLineItemContainer}
                    key={index}
                  >
                    <Grid item container xs={8}>
                      <Grid item xs={12} className={classes.mobileGridSpacing}>
                        <Typography variant="body2">
                          <b>Title: </b>
                          {webinar.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.mobileGridSpacing}>
                        <Typography variant="body2">
                          {webinar.presenters[0] ? (
                            <React.Fragment>
                              <b>Presenter: </b>
                              {webinar.presenters[0].first_name +
                                ' ' +
                                webinar.presenters[0].last_name +
                                ' - ' +
                                webinar.presenters[0].title}
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          <br />
                          {webinar.account_name ? (
                            <React.Fragment>
                              <b>Company: </b>
                              {webinar.account_name}{' '}
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          <br />
                          {webinar.cleared_for_public_view ===
                          'Yes - Approved' ? (
                            <React.Fragment>
                              <b>Visibility: </b>Public
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <b>Visibility: </b>Private
                            </React.Fragment>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={4} justify="space-between">
                      <Grid
                        item
                        xs={12}
                        className={classes.mobileGridSpacing}
                        style={{ textAlign: 'end' }}
                      >
                        <Typography variant="body2">
                          {webinar.start_time ? (
                            moment(webinar.start_time).format('h:mm a')
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          <br />
                          <br />
                          {webinar.date ? (
                            moment(webinar.date).format('dddd, MMMM Do')
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.mobileGridSpacing}
                        style={{ alignSelf: 'flex-end' }}
                      >
                        {webinar.archive_link ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            target="#blank"
                            onClick={() => this.viewArchive(webinar)}
                          >
                            <Typography variant="body2">View Replay</Typography>
                          </Button>
                        ) : (
                          <Button variant="outlined" color="primary" disabled>
                            Coming Soon
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Hidden>
          </React.Fragment>
        );
      } else {
        webinars = (
          <React.Fragment>
            <Grid item xs={12} sm={2} align="left">
              <Button onClick={() => this.handleClose()}>
                <ChevronLeftIcon /> Back
              </Button>
            </Grid>
            <Typography variant="h4" gutterBottom align="center">
              Upcoming Discussions
            </Typography>
            {/* THIS IS THE DESTKOP SIZE CONTENT */}
            <Hidden xsDown>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {authorizedPresentations.map((webinar, index) => {
                      let date = webinar.date.split('T')[0];
                      let time = webinar.end_time;
                      let timeNow = moment().format('HH:mm:ss');
                      let dateNow = moment().format('YYYY-MM-DD');

                      if (webinar.contacts) {
                        webinar.presenters = webinar.contacts
                          .filter(
                            (contact) =>
                              contact.roles.includes('presenter') ||
                              contact.roles.includes('Presenter')
                          )
                          .sort((a, b) => {
                            if (a.order === null) {
                              a.order = 1000;
                            }

                            if (b.order === null) {
                              b.order = 1000;
                            }

                            return a.order - b.order;
                          });
                      } else {
                        webinar.presenters = [];
                      }

                      switch (true) {
                        case date < dateNow:
                          return null;
                        case date === dateNow && timeNow >= time:
                          return null;
                        default:
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                className={
                                  this.state.preSelectedPresentation ===
                                  webinar.abstract_id
                                    ? classes.preSelectedRow
                                    : ''
                                }
                              >
                                <TableCell
                                  align="left"
                                  style={{ maxWidth: '40vw' }}
                                >
                                  <b>Title: </b>
                                  {webinar.title}
                                  <br />
                                  <br />
                                  {webinar.presenters[0] ? (
                                    <React.Fragment>
                                      <b>Presenter: </b>
                                      {webinar.presenters[0].first_name +
                                        ' ' +
                                        webinar.presenters[0].last_name +
                                        ' - ' +
                                        webinar.presenters[0].title}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                  <br />
                                  {webinar.account_name ? (
                                    <React.Fragment>
                                      <b>Company: </b>
                                      {webinar.account_name}{' '}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                  <br />
                                  {webinar.cleared_for_public_view ===
                                  'Yes - Approved' ? (
                                    <React.Fragment>
                                      <b>Visibility: </b>Public
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <b>Visibility: </b>Private
                                    </React.Fragment>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {webinar.start_time ? (
                                    moment(webinar.start_time).format('h:mm a')
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {webinar.date ? (
                                    moment(webinar.date).format('dddd, MMMM Do')
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                </TableCell>

                                <TableCell align="center">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    target="#blank"
                                    onClick={() =>
                                      this.viewPresentation(webinar)
                                    }
                                  >
                                    Launch
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {authorizedPresentations.length <= 0 ? loadingGraphic : null}
            </Hidden>
            {/* THIS IS THE MOBILE CONTENT */}
            <Hidden smUp>
              {authorizedPresentations.length <= 0 ? loadingGraphic : null}
              {authorizedPresentations.map((webinar, index) => {
                let date = webinar.date.split('T')[0];
                let time = webinar.end_time;
                let timeNow = moment().format('HH:mm:ss');
                let dateNow = moment().format('YYYY-MM-DD');

                if (webinar.contacts) {
                  webinar.presenters = webinar.contacts
                    .filter(
                      (contact) =>
                        contact.roles.includes('presenter') ||
                        contact.roles.includes('Presenter')
                    )
                    .sort((a, b) => {
                      if (a.order === null) {
                        a.order = 1000;
                      }

                      if (b.order === null) {
                        b.order = 1000;
                      }

                      return a.order - b.order;
                    });
                } else {
                  webinar.presenters = [];
                }

                switch (true) {
                  case date < dateNow:
                    return null;
                  case date === dateNow && timeNow >= time:
                    return null;
                  default:
                    return (
                      <Grid
                        container
                        spacing={1}
                        justify="flex-start"
                        alignItems="stretch"
                        className={classes.mobileLineItemContainer}
                        key={index}
                      >
                        <Grid item container xs={8}>
                          <Grid
                            item
                            xs={12}
                            className={classes.mobileGridSpacing}
                          >
                            <Typography variant="body2">
                              <b>Title: </b>
                              {webinar.title}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.mobileGridSpacing}
                          >
                            <Typography variant="body2">
                              {webinar.presenters[0] ? (
                                <React.Fragment>
                                  <b>Presenter: </b>
                                  {webinar.presenters[0].first_name +
                                    ' ' +
                                    webinar.presenters[0].last_name +
                                    ' - ' +
                                    webinar.presenters[0].title}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <br />
                              {webinar.account_name ? (
                                <React.Fragment>
                                  <b>Company: </b>
                                  {webinar.account_name}{' '}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <br />
                              {webinar.cleared_for_public_view ===
                              'Yes - Approved' ? (
                                <React.Fragment>
                                  <b>Visibility: </b>Public
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <b>Visibility: </b>Private
                                </React.Fragment>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={4} justify="space-between">
                          <Grid
                            item
                            xs={12}
                            className={classes.mobileGridSpacing}
                            style={{ textAlign: 'end' }}
                          >
                            <Typography variant="body2">
                              {webinar.start_time ? (
                                moment(webinar.start_time).format('h:mm a')
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <br />
                              <br />
                              {webinar.date ? (
                                moment(webinar.date).format('dddd, MMMM Do')
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.mobileGridSpacing}
                            style={{ alignSelf: 'flex-end' }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              target="#blank"
                              onClick={() => this.viewPresentation(webinar)}
                            >
                              Launch
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                }
              })}
            </Hidden>
          </React.Fragment>
        );
      }
    }

    let pageWidth = !this.props.sidebar.open
      ? this.state.imageWidth
      : window.innerWidth - drawerWidth;

    console.log(
      '🚀 ~ file: DiscussionForum.js ~ line 404 ~ Auditorium ~ render ~ webinars',
      webinars
    );

    return (
      <Grid
        container
        className={classes.gridContainer}
        align="center"
        justify="center"
      >
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h4">{pageTitle}</Typography>
        </Grid>
        <Grid item className={classes.imageMapper} xs={12}>
          <Dialog
            open={this.state.modalOpen}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {webinars}
          </Dialog>
          <Hidden xsDown>
            <ImageMapper
              width={pageWidth}
              imgWidth={2000}
              src={AuditoriumImage}
              map={this.MAP}
              onClick={(event) => this.imageClick(event)}
            />
          </Hidden>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} style={{ marginTop: '80px' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.mobileButtons}
              onClick={() => this.imageClick({ name: 'UpcomingWebinars' })}
            >
              <Typography
                variant="h6"
                align="justify"
                className={classes.mobileButtonsText}
              >
                Upcoming
              </Typography>
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNetworking: () => queryApi.endpoints.getNetworking.initiate(eventId),
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    debug: state.debug,
    event: state.event,
    // networking: queryApi.endpoints.getNetworking.select(eventId)(state),
    networking: state.queryApi.queries?.[`getNetworking("${eventId}")`],
    // poster: state.queryApi.queries?.[`getPosters("${eventId}")`],
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(useStyles)(withRouter(Auditorium)));
