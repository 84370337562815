import React, { Component } from 'react';
import PubNubReact from 'pubnub';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';

import { eventId } from '../../config';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';
import addChatSubscription from '../../Store/Actions/addChatSubscription';
import setChatMembership from '../../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
import setChatbar from '../../Store/Actions/setChatbar';
import viewPrivateChat from '../../Store/Actions/viewPrivateChat';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  own: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '5px',
    marginBottom: '3px',
    borderRadius: '15px',
    maxWidth: '100%',
  },
  img: {
    borderRadius: '50%',
    maxWidth: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: '20vh',
  },
  other: {},
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  staffCard: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: this.props.event.event.publish_key,
      subscribeKey: this.props.event.event.subscribe_key,
      uuid: this.props.user.user.email,
      autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
      restore: true, // enable catchup on missed messages
    });
    this.state = {
      messages: [],
      input: '',
      channelOccupants: [],
      boothStaff: [],
    };
  }

  componentDidMount = async () => {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `show-attendees/staff/${eventId}/${this.props.exhibit.exhibition_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            // handle case to catch all virtual staff as well as attendance days set to 'all days'. no physical staff will be displayed here.
            response.data.data = response.data.data.filter(
              (contact) =>
                contact.days.toLowerCase().includes('all days') ||
                contact.days.toLowerCase().includes('virtual')
            );
            this.setState({
              boothStaff: [...this.state.boothStaff, ...response.data.data],
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Booth Chat. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Booth Chat. ${error}`);
        }
      });

    try {
      await this.pubnub.addListener({
        status: function (statusEvent) {},
        message: (response) => {
          const newMessage = {
            channel: response.channel,
            timetoken: response.timetoken,
            entry: {
              from: response.message.from,
              text: response.message.text,
              accountName: response.message.accountName,
              department: response.message.department,
              email: response.message.email,
              first_name: response.message.first_name,
              last_name: response.message.last_name,
              roles: response.message.roles,
              site: response.message.site,
              title: response.message.title,
            },
          };
          this.setState({
            messages: [...this.state.messages, newMessage],
          });
        },
        presence: (presenceEvent) => {
          if (presenceEvent.action === 'join') {
            this.pubnub.objects
              .getUUIDMetadata({
                uuid: presenceEvent.uuid,
              })
              .then((response) => {
                this.setState({
                  channelOccupants: [
                    ...this.state.channelOccupants,
                    response.data,
                  ],
                });
              });
          } else if (presenceEvent.action === 'leave') {
            const array = [...this.state.channelOccupants];

            var elementPos = array.map((x) => x.id).indexOf(presenceEvent.uuid);

            if (elementPos !== -1) {
              array.splice(elementPos, 1);
              this.setState({ channelOccupants: array });
            }
          }
        },
      });

      await this.pubnub.subscribe({
        channels: [this.props.channel],
        withPresence: true,
      });

      const currentUsers = await this.pubnub.hereNow({
        channels: [this.props.channel],
        includeUUIDs: true,
        includeState: true,
      });

      let newUsers = [];

      const detailedUsers = await currentUsers.channels[
        this.props.channel
      ].occupants.map((occupant) => {
        return this.pubnub.objects
          .getUUIDMetadata({
            uuid: occupant.uuid,
          })
          .then((response) => {
            newUsers.push(response.data);
          });
      });

      await Promise.all(detailedUsers).then((res) => {
        this.setState({
          channelOccupants: [...this.state.channelOccupants, ...newUsers],
        });
      });

      await this.pubnub.history(
        {
          channel: this.props.channel,
          count: 100, // how many items to fetch
          stringifiedTimeToken: true, // false is the default
        },
        (status, response) => {
          this.setState({
            messages: [...this.state.messages, ...response.messages],
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  async componentWillUnmount() {
    await this.pubnub.unsubscribe({
      channels: [this.props.channel],
    });

    await this.pubnub.removeListener();
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  inviteUser = async (user) => {
    let emailArray = [this.props.user.user.email, user].sort();
    let emails = `${emailArray[0]}${emailArray[1]}${'planetconnect'}`;

    const hashCode = (s) =>
      s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

    const hashedEmails = hashCode(emails);

    await this.pubnub.objects.setChannelMembers({
      channel: hashedEmails,
      uuids: [
        this.props.user.user.email,
        {
          id: this.props.user.user.email,
          custom: { trialPeriod: false },
        },
        user,
        {
          id: user,
          custom: { trialPeriod: false },
        },
      ],
    });

    await this.pubnub.publish({
      channel: user,
      message: {
        text: `${this.props.user.user.first_name} ${this.props.user.user.last_name} has invited you to chat!`,
        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
        accountName: this.props.user.user.account_name,
        department: this.props.user.user.department,
        email: this.props.user.user.email,
        first_name: this.props.user.user.first_name,
        last_name: this.props.user.user.last_name,
        roles: this.props.user.user.roles,
        site: this.props.user.user.site,
        title: this.props.user.user.title,
        channel: hashedEmails,
      },
    });

    await this.pubnub.publish({
      channel: this.props.user.user.email,
      message: {
        text: `You're entering a new chat!`,
        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
        accountName: this.props.user.user.account_name,
        department: this.props.user.user.department,
        email: this.props.user.user.email,
        first_name: this.props.user.user.first_name,
        last_name: this.props.user.user.last_name,
        roles: this.props.user.user.roles,
        site: this.props.user.user.site,
        title: this.props.user.user.title,
        channel: hashedEmails,
      },
    });

    await this.props.setChatbar(true);
    await this.props.selectActivePrivateChat(hashedEmails, user);
  };

  sendMessage = async () => {
    if (this.state.input) {
      await this.pubnub.publish({
        channel: this.props.channel,
        message: {
          text: this.state.input,
          from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
          accountName: this.props.user.user.account_name,
          department: this.props.user.user.department,
          email: this.props.user.user.email,
          first_name: this.props.user.user.first_name,
          last_name: this.props.user.user.last_name,
          roles: this.props.user.user.roles,
          site: this.props.user.user.site,
          title: this.props.user.user.title,
        },
      });

      await this.setState({
        input: '',
      });
    }
  };

  async selectPrivateChat(member) {
    await this.props.setChatbar(true);

    const newChannel = this.props.chat.users.find(
      (user) => user.uuid.id === member
    );

    await this.props.selectActivePrivateChat(newChannel.channel, member);
  }

  render() {
    const { classes } = this.props;

    const truncateAfterXWords = (str) => {
      let x = 30;
      return str.split(' ').splice(0, x).join(' ');
    };

    const boothStaff = this.state.boothStaff.map((staffer, index) => {
      let communicationButtons;

      if (this.props.exhibit.exhibition_id !== '1691992000053684042') {
        if (
          this.props.chat.users.find((user) => user.uuid.id === staffer.email)
        ) {
          communicationButtons = (
            <FormControl className={classes.formControlButton}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => this.selectPrivateChat(staffer.email)}
              >
                View Private Chat
              </Button>
            </FormControl>
          );
        } else if (this.props.user.user.email !== staffer.email) {
          communicationButtons = (
            <FormControl className={classes.formControlButton}>
              <Button
                variant="contained"
                onClick={() => this.inviteUser(staffer.email)}
                className={classes.button}
              >
                Chat
              </Button>
            </FormControl>
          );
        } else {
          communicationButtons = (
            <FormControl className={classes.formControlButton}>
              <Button
                variant="contained"
                onClick={() =>
                  alert(
                    'You cannot chat with yourself but others will be able to chat with you.'
                  )
                }
                className={classes.button}
              >
                Chat
              </Button>
            </FormControl>
          );
        }
      }

      return (
        <Grid item xs={6} key={index} className={classes.staffCard}>
          <Typography
            className={classes.name}
            variant="h6"
            color="textPrimary"
            align="center"
          >
            {staffer.first_name + ' ' + staffer.last_name}
          </Typography>
          {staffer.photo_url ? (
            <img src={staffer.photo_url} className={classes.img} alt="staff" />
          ) : (
            <PersonIcon
              className={classes.img}
              style={{ width: '3rem', height: '3rem', background: '#CCCCCC' }}
            />
          )}
          <Divider className={classes.divider} variant="middle" />
          <Typography variant="body1" align="center">
            {staffer.title ? (
              <React.Fragment>
                <b>Title:</b> {staffer.title}{' '}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Typography>
          <Typography variant="body1" align="center">
            {staffer.expertise_area ? (
              <React.Fragment>
                <b>Expertise:</b> {truncateAfterXWords(staffer.expertise_area)}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Typography>
          {staffer.expertise_area ? (
            <Divider className={classes.divider} variant="middle" />
          ) : (
            <React.Fragment />
          )}
          <FormControl className={classes.formControlButton}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${staffer.email}`}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained" className={classes.button}>
                Email
              </Button>
            </a>
          </FormControl>
          {communicationButtons}
        </Grid>
      );
    });

    let occupants = this.state.channelOccupants.map((occupant, index) => {
      if (occupant.id === this.props.user.user.email) {
        return <React.Fragment key={occupant.email}></React.Fragment>;
      }

      let color = 'other';

      return (
        <Accordion key={occupant.email} className={classes.root}>
          <AccordionSummary
            className={classes.expansionPanelMain + ' ' + classes[color]}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" component="div">
              {occupant.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography variant="body1" align="left">
                  {occupant.custom.accountName ? (
                    <React.Fragment>
                      <b>Company:</b> {occupant.custom.accountName}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {occupant.custom.title ? (
                    <React.Fragment>
                      <b>Title:</b> {occupant.custom.title}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {occupant.custom.department ? (
                    <React.Fragment>
                      <b>Department:</b> {occupant.custom.department}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {occupant.custom.site ? (
                    <React.Fragment>
                      <b>Site:</b> {occupant.custom.site}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                {this.props.user.user.email !== occupant.email &&
                !this.props.chat.users.find(
                  (user) => user.uuid.id === occupant.email
                ) ? ( //|| this.props.chat.indexOf(occupant.email)
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.inviteUser(occupant.id)}
                    >
                      Chat
                    </Button>
                  </FormControl>
                ) : (
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.selectPrivateChat(occupant.email)}
                    >
                      View Private Chat
                    </Button>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });

    let previousSender;
    let messages = this.state.messages.map((message) => {
      if (!message.entry.text) {
        return null;
      }

      let align = 'left';
      let color = 'other';

      if (message.entry.email === this.props.user.user.email) {
        align = 'right';
        color = 'own';
      }

      if (previousSender === message.entry.email) {
        return (
          <React.Fragment key={message.timetoken}>
            <Typography variant="body1" component="div" align={align}>
              <div className={classes[color]}>{message.entry.text}</div>
            </Typography>
          </React.Fragment>
        );
      } else {
        previousSender = message.entry.email;
        return (
          <React.Fragment key={message.timetoken}>
            <br />
            <Typography variant="h6" component="div" align={align}>
              {message.entry.from ? message.entry.from : 'Anonymous'}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              align={align}
              className={classes[color]}
            >
              {message.entry.text}
            </Typography>
          </React.Fragment>
        );
      }
    });

    return (
      <Grid container layout={'row'} justify="center">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="div"
            align="center"
            color="textPrimary"
          >
            Booth Staff
          </Typography>
          <Divider
            className={classes.divider}
            variant="fullWidth"
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
          />
        </Grid>
        <Grid
          container
          layout={'column'}
          justify="center"
          style={{ maxHeight: '50vh', overflowY: 'scroll' }}
        >
          {boothStaff}
        </Grid>
        {this.props.user.user.account_id === this.props.exhibit.account_id ? (
          <Grid
            item
            xs={12}
            style={{
              maxHeight: '80vw',
              overflowY: 'scroll',
              overflowX: 'hidden',
              margin: '2vw',
            }}
          >
            <Typography variant="h5" component="div" align="center">
              Here Now
            </Typography>
            {occupants}
          </Grid>
        ) : (
          <React.Fragment />
        )}
        <Grid
          item
          xs={12}
          style={{
            maxHeight: '80vw',
            overflowY: 'scroll',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <Divider
            className={classes.divider}
            variant="fullWidth"
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
          />
          <Typography
            variant="h5"
            component="div"
            align="center"
            color="textPrimary"
          >
            Group Conversation
          </Typography>
          {messages}
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              onChange={(event) => this.handleChange(event, 'input')}
              value={this.state.input}
            />
          </FormControl>
          <FormControl className={classes.formControlButton}>
            <Button
              variant="contained"
              onClick={this.sendMessage}
              className={classes.button}
            >
              Send
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getChatMessages: getChatMessages,
      addChatSubscription: addChatSubscription,
      setChatMembership: setChatMembership,
      selectActivePrivateChat: selectActivePrivateChat,
      setChatbar: setChatbar,
      viewPrivateChat: viewPrivateChat,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chat: state.chat,
    debug: state.debug,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(Chat)));
