import axios from 'axios';
import { eventId } from '../../config';
import { setStrapi } from '../Reducers/strapi';

export default function setStrapiElements() {
  return (dispatch, getState) => {
    axios({
      method: 'get',
      url: `https://cms.planetconnect.com/shows?show_id=${eventId}`,
      headers: { Authorization: '' },
    }).then((response) => {
      dispatch(setStrapi(response.data[0].menu));
    });
  };
}
