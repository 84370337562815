import {
  setConversationsInitialized,
  addUser,
  joinedConversation,
  leftConversation,
  removedConversation,
  removeUser,
  resetTwilio,
  setHereNowInitialized,
  setConversationsClient,
  setSyncClient,
  setUser,
  updateUser,
  addConversationMessage,
} from '../Reducers/twilio';
import twilioTypingStarted from '../../Store/Actions/twilioTypingStarted';
import twilioTypingEnded from '../../Store/Actions/twilioTypingEnded';
import twilioShouldPlayNotification from '../../Store/Actions/twilioShouldPlayNotification';
import twilioParticipantUpdated from '../../Store/Actions/twilioParticipantUpdated';
import twilioPreloadConversation from '../../Store/Actions/twilioPreloadConversation';
import twilioSetConversation from '../../Store/Actions/twilioSetConversation';
import getTwilioConversationsClient from '../../Utilities/getTwilioConversationsClient';
import getTwilioSyncClient from '../../Utilities/getTwilioSyncClient';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import getTwilioToken from '../../Utilities/getTwilioToken';
import twilioParticipantJoined from './twilioParticipantJoined';
import twilioParticipantLeft from './twilioParticipantLeft';
import { queryApi } from '../../Services/queryApi';
import { eventId } from '../../config';
import arrayComparison from '../../Utilities/arrayComparison';

export const TWILIO_HERE_NOW_MAP_NAME = 'HERE_NOW';

export default function twilioInitialize(user) {
  return async (dispatch, getState) => {
    // const items = getState().debug;
    const event = await dispatch(queryApi.endpoints.getEvent.initiate(eventId));
    if (!event.data?.available_routes) return;

    let engagementZoneEnabled = false;

    let availableRoutes = JSON.parse(event.data.available_routes).routes;

    availableRoutes.forEach((route) => {
      if (route.container === 'EngagementZone') {
        const includesRole = arrayComparison(
          route.allowedRoles,
          user?.roles?.split(', ') || []
        );

        if (includesRole) {
          if (route.container === 'EngagementZone') {
            engagementZoneEnabled = true;
          }
        }
      }
    });

    if (!engagementZoneEnabled) return;

    if (!user) {
      // No user, reset everything
      return dispatch(resetTwilio());
    }

    const token = await getTwilioToken();
    // user,
    // conversationsServiceSid,
    // syncServiceSid,
    // eventId
    // console.log(
    //   '🚀 ~ file: twilioInitialize.js ~ line 34 ~ return ~ token',
    //   token
    // );
    const conversationsClient = await getTwilioConversationsClient(token);
    const syncClient = await getTwilioSyncClient(token);

    // console.log(
    //   '🚀 ~ file: twilioInitialize.js ~ line 27 ~ return ~ client',
    //   client
    // );
    // if (client.connectionState !== 'connected')
    //   throw new Error('Twilio Conversation client could not be initilized');
    dispatch(setConversationsClient(conversationsClient));
    dispatch(setSyncClient(syncClient));

    // Before you use the client, subscribe to the `'stateChanged'` event and wait
    // for the `'initialized'` state to be reported.
    conversationsClient.on('stateChanged', async (state) => {
      // console.log(state);

      if (state === 'initialized') {
        // Use the client
        console.log('twilio client initialized');

        const twilioUser = await conversationsClient.getUser(user.email);
        // console.log(
        //   '🚀 ~ file: twilioInitialize.js ~ line 48 ~ conversationsClient.on ~ client',
        //   client
        // );
        // console.log(
        //   '🚀 ~ file: twilioInitialize.js ~ line 37 ~ return ~ twilioUser',
        //   twilioUser
        // );
        dispatch(setUser(twilioUser));

        let loadedConversations = [];
        const pageHandler = (paginator) => {
          paginator.items.forEach(async (conversation) => {
            loadedConversations.push(conversation);
          });
          return paginator.hasNextPage
            ? paginator.nextPage().then(pageHandler)
            : loadedConversations;
        };
        conversationsClient
          .getSubscribedConversations()
          .then(pageHandler)
          .then(async (loadedConversations) => {
            // console.log(
            //   '🚀 ~ file: twilioInitialize.js ~ line 260 ~ .then ~ loadedConversations',
            //   loadedConversations
            // );
            const preloadConversationPromises = loadedConversations.map(
              (conversation) =>
                dispatch(twilioPreloadConversation(conversation))
            );
            return Promise.all(preloadConversationPromises);
          })
          .then(() => {
            // Done with Conversations
            dispatch(setConversationsInitialized(true));
          })
          .catch((error) => {
            console.error('getSubscribedConversations() failed', error.message);
          });
      }
    });
    conversationsClient.on('connectionError', (state) => {
      console.log('connectionError', state);
    });
    conversationsClient.on('tokenAboutToExpire', async () => {
      const token = await getTwilioToken();
      conversationsClient.updateToken(token);
    });

    conversationsClient.on('tokenExpired', async () => {
      const token = await getTwilioToken();
      conversationsClient.updateToken(token);
    });

    // Fired when a conversation becomes visible to the client. The event is also triggered when the client creates a new conversation. Fired for all conversations client has joined.
    // This will contain conversation that are visible but have been left.
    conversationsClient.on('conversationAdded', (conversation) => {
      // console.log('conversationAdded: ', conversation);
      dispatch(twilioSetConversation(conversation));
    });
    // Fired when a conversation is no longer visible to the client.
    conversationsClient.on('conversationRemoved', (conversation) => {
      // console.log('conversationRemoved: ', conversation);
      dispatch(removedConversation(conversation));
    });
    // Fired when the client joins a conversation.
    conversationsClient.on('conversationJoined', (conversation) => {
      // console.log('conversationJoined: ', conversation);
      dispatch(twilioSetConversation(conversation));
      dispatch(joinedConversation(conversation));
    });
    conversationsClient.on('conversationLeft', (conversation) => {
      // console.log('conversationLeft: ', conversation);
      dispatch(leftConversation(conversation));
    });
    // Fired when the attributes or the metadata of a conversation have been updated. During conversation's creation and initialization, this event might be fired multiple times for same joined or created conversation as new data is arriving from different sources.
    conversationsClient.on('conversationUpdated', (data) => {
      // console.log(
      //   'conversationUpdated: ',
      //   data.updateReasons,
      //   data.conversation
      // );
      // console.log(
      //   'data.conversation.lastMessage?.dateCreated',
      //   data.conversation.lastMessage?.dateCreated
      // );
      // if (data.updateReasons.length ===1 && data.updateReasons.includes('lastMessage'));
      dispatch(twilioSetConversation(data.conversation));

      // dispatch(setConversation(conversation.conversation));
    });

    // // Fired when a new message has been added to the conversation on the server.
    conversationsClient.on('messageAdded', (message) => {
      // console.log('messageAdded: ', message);
      // dispatch(twilioMessageAdded(message));
      dispatch(addConversationMessage(message));
      dispatch(twilioShouldPlayNotification(message));
    });
    // // Fired when a message is removed from the message list of a conversation.
    // conversationsClient.on('messageRemoved', (state) => {
    //   // console.log('messageRemoved: ', state);
    // });
    // // Fired when the fields of an existing message are updated with new values.
    // conversationsClient.on('messageUpdated', (state) => {
    //   // console.log('messageUpdated: ', state);
    // });

    // Fired when a participant has started typing.
    conversationsClient.on('typingStarted', (participant) => {
      // console.log('typingStarted: ', participant);
      dispatch(twilioTypingStarted(participant));
    });
    // Fired when a participant has stopped typing.
    conversationsClient.on('typingEnded', (participant) => {
      // console.log('typingEnded: ', participant);
      dispatch(twilioTypingEnded(participant));
    });

    // // Fired when the fields of an existing message are updated with new values.
    conversationsClient.on('participantJoined', (participant) => {
      // console.log('init participantJoined: ', participant);
      dispatch(twilioParticipantJoined(participant));
    });
    // // Fired when a participant has left a conversation.
    conversationsClient.on('participantLeft', (participant) => {
      // console.log('init participantLeft: ', participant);
      dispatch(twilioParticipantLeft(participant));
    });
    // // Fired when a participant's fields have been updated.
    conversationsClient.on(
      'participantUpdated',
      ({ participant, updateReasons }) => {
        // console.log(
        //   '🚀 ~ file: twilioInitialize.js ~ line 188 ~ conversationsClient.on ~ participant, updateReasons',
        //   participant,
        //   updateReasons
        // );
        if (updateReasons.includes('attributes')) {
          // dispatch(setConversation(participant.conversation));
          dispatch(twilioParticipantUpdated(participant));

          // setParticipant(participant);
          // setAttributes({ ...participant.attributes });
        }

        // dispatch(setConversation(conversation));
      }
    );

    // Fired when the client has subscribed to a user.
    conversationsClient.on('userSubscribed', (user) => {
      // console.log('userSubscribed: ', user);
      dispatch(addUser(user));
    });
    // Fired when the client has unsubscribed from a user.
    conversationsClient.on('userUnsubscribed', (user) => {
      // console.log('userUnsubscribed: ', user);
      dispatch(removeUser(user));
    });
    // Fired when the properties or the reachability status of a user have been updated.
    conversationsClient.on('userUpdated', (data) => {
      // console.log('userUpdated: ', data.user);
      dispatch(updateUser(data.user));
    });
    // Fired when the client has received (and parsed) a push notification via one of the push channels (apn or fcm).
    conversationsClient.on('pushNotification', (state) => {
      // console.log('pushNotification: ', state);
    });

    // /** SyncMap Contacts */
    // const contactsMapName = getTwilioSyncContactsMapName(
    //   getTwilioUserIdentity(user)
    // );
    // // console.log(
    // //   '🚀 ~ file: twilioInitialize.js ~ line 198 ~ return ~ contactsMap',
    // //   contactsMap
    // // );

    // const contactsClient = await syncClient.map(contactsMapName);

    // dispatch(setContactsClient(contactsClient));

    // // const allContacts = await contactsClient.getItems()
    // // savedList = contactsClient;
    // // Listen to updates on the Document
    // contactsClient.on('itemAdded', function (args) {
    //   // console.log('contacts itemAdded', args);
    //   dispatch(addContact(args.item));
    // });
    // contactsClient.on('itemRemoved', function (args) {
    //   // console.log('contacts itemRemoved', args);
    //   dispatch(removeContact(args));
    // });
    // contactsClient.on('itemUpdated', function (args) {
    //   // console.log('contacts itemUpdated', args);
    //   dispatch(updateContact(args.item));
    // });

    // // Load all contacts
    // const contactsHandler = (paginator) => {
    //   paginator.items.forEach((item) => {
    //     // console.log(`SyncMapItem ${item.key}: `, item.data);
    //     dispatch(addContact(item));
    //   });
    //   return paginator.hasNextPage
    //     ? paginator.nextPage().then(contactsHandler)
    //     : null;
    // };
    // contactsClient
    //   .getItems({ from: contactsMapName, order: 'asc' })
    //   .then(contactsHandler)
    //   .then((results) => {
    //     console.log('contacts initilized');
    //     // console.log('results', results);
    //     dispatch(setContactsInitialized(true));
    //     // contactsClient.removeMap();
    //   })
    //   .catch((error) => {
    //     console.error('Map getItems() failed', error);
    //   });

    dispatch(setHereNowInitialized(true));
  };
}
