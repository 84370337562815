import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConversations,
  getOpenProfile,
  getInitilized,
  setEngagement,
  getCreatingConversation,
  setOpenConversation,
} from '../Store/Reducers/twilio';
import SidebarConversation from '../Components/SidebarConversation';
import ChatConversation from '../Components/ChatConversation';
import ChatDirectory from '../Components/ChatDirectory';
import SidebarProfile from '../Components/SidebarProfile';
import SidebarNewMessage from '../Components/SidebarNewMessage';
import MyEngagementZoneProfile from '../Components/MyEngagementZoneProfile';
import { colors } from '../config';
import SmsIcon from '@material-ui/icons/Sms';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import { Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import isOnline from '../Utilities/isOnline';

import ChatSidebarMessages from '../Components/ChatSidebarMessages';

export default function EngagementZone() {
  const dispatch = useDispatch();

  const page = 'sidebar';

  const user = useSelector((state) => state.user.user);
  const online = useSelector(isOnline);

  // const contacts = useSelector((state) => state.twilio?.contacts);
  // const users = useSelector((state) => state.twilio?.users);
  const unread = useSelector((state) => state.twilio?.unread);
  const openConversation = useSelector(
    (state) => state.twilio.openConversations?.[page]
  );
  const engagement = useSelector((state) => state.twilio?.engagement);
  const creatingConversation = useSelector(getCreatingConversation);
  const conversations = useSelector(getConversations);
  // const online = useSelector(isOnline);

  // if (!online) {
  //   return (
  //     <div>
  //       <div style={styles.banner}>Chat</div>

  //       <Grid style={styles.container}>
  //         <Typography> User is Offline. Cannot perform action.</Typography>
  //       </Grid>
  //     </div>
  //   );
  // }

  // console.log(
  //   '🚀 ~ file: EngagementZone.js ~ line 45 ~ EngagementZone ~ keys',
  //   Object.keys(contacts)
  // );

  const [openTab, setOpenTab] = useState(0);
  const [isMessage, setIsMessage] = useState(true);
  const [isOpenRooms, setIsOpenRooms] = useState(true);
  const [isDirectMessages, setIsDirectMessages] = useState(true);
  const [isCreateNewMessage, setIsCreateNewMessage] = useState(false);
  const [isContacts, setIsContacts] = useState(false);

  let contactsUnreadCount = 0;
  let followingUnreadCount = 0;

  Object.keys(conversations).forEach((key) => {
    if (conversations[key]?.uniqueName?.startsWith('GROUP_')) {
      if (conversations[key]?.myAttributes?.following) {
        followingUnreadCount += conversations[key].unread || 0;
      }
    } else {
      contactsUnreadCount += conversations[key].unread || 0;
    }
  });

  // const [sortedContacts, setSortedContacts] = useState([]);

  // function compare(a, b) {
  //   if (b?.attributes?.last_name > a?.attributes?.last_name) {
  //     return -1;
  //   } else if (a?.attributes?.last_name > b?.attributes?.last_name) {
  //     return 1;
  //   } else {
  //     if (b?.attributes?.first_name > a?.attributes?.first_name) {
  //       return -1;
  //     } else if (a?.attributes?.first_name > b?.attributes?.first_name) {
  //       return 1;
  //     }
  //     return 0;
  //   }
  // }

  // const sortingContacts = () => {
  //   if (contacts && users) {
  //     let sortContacts = [];
  //     Object.keys(contacts).forEach((key) => {
  //       const tmpUser = users?.[key];
  //       if (tmpUser) sortContacts.push(tmpUser);
  //     });

  //     sortContacts.sort(compare);
  //     setSortedContacts(sortContacts);
  //   }
  // };
  // // sortingContacts();
  // useEffect(() => {
  //   sortingContacts();
  // }, [contacts, users]);
  useEffect(() => {
    return () => {
      // close sidebar conversation
      dispatch(setOpenConversation({ page, conversation: null }));
    };
  }, []);

  let directMessageUnreadCount = 0;
  let openRoomUnreadCount = 0;
  const openedRooms = [];
  let directMessages = [];
  Object.keys(conversations).forEach((key) => {
    if (conversations[key]?.client?.uniqueName?.startsWith('GROUP_')) {
      openRoomUnreadCount += unread[conversations[key]?.client?.sid] || 0;
      openedRooms.push(conversations[key]);
    } else {
      directMessageUnreadCount += unread[conversations[key]?.client?.sid] || 0;
      directMessages.push(conversations[key]);
    }
  });
  openedRooms.sort((a, b) => {
    if (!a.lastMessageCreated) return 1;
    if (!b.lastMessageCreated) return -1;
    return a.lastMessageCreated > b.lastMessageCreated ? -1 : 1;
  });

  // const openedRooms = conversations?.filter((conversation) =>
  //   conversation?.client?.uniqueName?.startsWith('GROUP_')
  // );
  // console.log(
  //   '🚀 ~ file: ChatSidebarMessages.js ~ line 75 ~ ChatSidebarMessages ~ conversations',
  //   conversations
  // );

  directMessages = directMessages
    // Show Non Group Conversations with at least 1 message in it or that I own
    ?.filter((conversation) => {
      // hide conversations that I am the only particiant
      if (conversation?.client?.participants.size === 1) return false;
      // hide conversations with 2 particiants WITH no message
      // and I am not the owner

      if (
        conversation?.client?.participants.size === 2 &&
        !conversation.client?.lastMessage &&
        conversation.client.createdBy !== getTwilioUserIdentity(user)
      )
        return false;
      return true;
    });
  directMessages.sort((a, b) => {
    if (!a.lastMessageCreated) return 1;
    if (!b.lastMessageCreated) return -1;
    return a.lastMessageCreated > b.lastMessageCreated ? -1 : 1;
  }); // console.log(
  //   '🚀 ~ file: ChatSidebarMessages.js ~ line 83 ~ ChatSidebarMessages ~ directMessages',
  //   directMessages
  // );
  const openProfile = useSelector(getOpenProfile);
  const twilioInitialized = useSelector(getInitilized);
  const isAttendee = () => {
    let attendee = false;
    // console.log(
    //   '🚀 ~ file: EngagementZone.js ~ line 107 ~ isAttendee ~ user',
    //   user
    // );

    let roles = user?.roles
      ?.split(',')
      ?.map((role) => role.toLowerCase().trim());

    if (roles) {
      if (roles.includes('attendee')) {
        attendee = true;
      }
    }
    return attendee;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!online) {
    return (
      <div>
        <div style={styles.banner}>Chat</div>
        <div style={styles.container}>
          <div style={styles.chatRoot}>
            <div style={{ ...styles.header, justifyContent: 'space-between' }}>
              <div
                style={{
                  position: 'absolute',
                  left: -25,
                  top: -10,
                }}
              >
                {/* <ChatProfile identity={getTwilioUserIdentity(user)} size={60} /> */}
                <MyEngagementZoneProfile />
              </div>

              <div
                style={{
                  marginLeft: 30,
                  fontSize: 16,
                  fontWeight: 'bold',
                  marginRight: 8,
                }}
              >
                Chat
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Tooltip title="New Chat" placement="right">
                  <div
                    style={{
                      margin: 8,
                      cursor: 'pointer',
                      width: 50,
                      height: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      dispatch(setEngagement('newMessage'));
                    }}
                  >
                    <SmsIcon
                      style={{
                        width: 30,
                        height: 30,
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>User is Offline</div>
          </div>
          {!isMobile && <div style={styles.chatContainer} />}
        </div>
      </div>
    );
  }
  if (!twilioInitialized || !user) {
    return null;
  }
  if (!isMobile) {
    return (
      <div>
        <div style={styles.banner}>Chat</div>

        <Grid style={styles.container}>
          <div style={styles.chatRoot}>
            <div style={{ ...styles.header, justifyContent: 'space-between' }}>
              <div
                style={{
                  position: 'absolute',
                  left: -25,
                  top: -10,
                }}
              >
                {/* <ChatProfile identity={getTwilioUserIdentity(user)} size={60} /> */}
                <MyEngagementZoneProfile />
              </div>

              <div
                style={{
                  marginLeft: 30,
                  fontSize: 16,
                  fontWeight: 'bold',
                  marginRight: 8,
                }}
              >
                Chat
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                {/* <div
            style={{ margin: 8 }}
            onClick={() => {
              // dispatch(setChatWindow(false));
            }}
          >
            <img src={setting} style={{ width: 18, height: 18 }} />
          </div> */}
                <Tooltip title="New Chat" placement="right">
                  <div
                    style={{
                      margin: 8,
                      cursor: 'pointer',
                      width: 50,
                      height: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      dispatch(setEngagement('newMessage'));
                    }}
                  >
                    <SmsIcon
                      style={{
                        width: 30,
                        height: 30,
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
            {/* <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          boxShadow: '0 0 15px -7px #000',
          zIndex: 1200,
        }}
      >
        <div
          style={{
            ...styles.tabs,
            borderBottom: isMessage ? '2px solid' : '0px solid',
            color: isMessage ? '#122857' : 'black',
            fontWeight: isMessage ? 'bold' : 'normal',
          }}
          onClick={() => {
            setIsMessage(true);
          }}
        >
          Chat
        </div>
         <div
          style={{
            ...styles.tabs,
            borderBottom: !isMessage ? '2px solid' : '0px solid',
            color: !isMessage ? '#122857' : 'black',
            fontWeight: !isMessage ? 'bold' : 'normal',
          }}
          onClick={() => {
            setIsMessage(false);
          }}
        >
          Calendar
        </div> 
      </div> */}
            <div
              style={{
                // overflowY: 'hidden',
                overflowX: 'hidden',
                width: '100%',
                height: '100%',
                overflowY: 'auto',

                flex: 1,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    boxShadow: '0 0 15px -7px #000',

                    // zIndex: 100000,
                  }}
                >
                  <div
                    style={{
                      ...styles.tabs,
                      borderBottom: openTab === 0 ? '2px solid' : '0px solid',
                      color: openTab === 0 ? '#122857' : 'black',
                      fontWeight: openTab === 0 ? 'bold' : 'normal',
                      position: 'relative',
                    }}
                    onClick={() => {
                      setOpenTab(0);
                    }}
                  >
                    Contacts
                    {contactsUnreadCount > 0 && (
                      <div style={styles.redCircle} />
                    )}
                  </div>
                  <div
                    style={{
                      ...styles.tabs,
                      borderBottom: openTab === 1 ? '2px solid' : '0px solid',
                      color: openTab === 1 ? '#122857' : 'black',
                      fontWeight: openTab === 1 ? 'bold' : 'normal',
                      position: 'relative',
                    }}
                    onClick={() => {
                      setOpenTab(1);
                    }}
                  >
                    Following
                    {followingUnreadCount > 0 && (
                      <div style={styles.redCircle} />
                    )}
                  </div>
                  {isAttendee() && (
                    <div
                      style={{
                        ...styles.tabs,
                        borderBottom: openTab === 2 ? '2px solid' : '0px solid',
                        color: openTab === 2 ? '#122857' : 'black',
                        fontWeight: openTab === 2 ? 'bold' : 'normal',
                      }}
                      onClick={() => {
                        setOpenTab(2);
                      }}
                    >
                      Directory
                    </div>
                  )}
                </div>

                {openTab === 0 &&
                  (directMessages?.length > 0 ? (
                    <div style={styles.chatRoom}>
                      {conversations &&
                        directMessages?.map((conversation) => (
                          <SidebarConversation
                            key={`conversation_sidebar_${conversation?.client?.sid}`}
                            conversation={conversation}
                            page={page}
                            isDirectMessage={true}
                          />
                        ))}
                    </div>
                  ) : (
                    <div style={styles.emptyRooms}>
                      You do not have any 1 to 1 connections yet. Reach out to
                      other users to start a conversation!
                    </div>
                  ))}
                {openTab === 1 &&
                  (openedRooms?.length > 0 ? (
                    <div style={styles.chatRoom}>
                      {conversations &&
                        openedRooms?.map((conversation, idx) => (
                          <SidebarConversation
                            key={`group_conversation_sidebar_${conversation?.client?.sid}`}
                            conversation={conversation}
                            page={page}
                            isDirectMessage={false}
                          />
                        ))}
                    </div>
                  ) : (
                    <div style={styles.emptyRooms}>
                      You have not joined any ongoing discussions. Visit rooms
                      and join the conversation!
                    </div>
                  ))}
                {openTab === 2 && (
                  <div style={styles.chatRoom}>
                    <div style={{ height: '100%', width: '100%' }}>
                      <ChatDirectory page={page} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {creatingConversation ? (
            <div
              style={{
                ...styles.chatContainer,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
            >
              <CircularProgress />
            </div>
          ) : engagement === 'newMessage' ? (
            <div style={styles.chatContainer}>
              <SidebarNewMessage
                setIsCreateNewMessage={setIsCreateNewMessage}
                page={page}
              />
            </div>
          ) : openProfile ? (
            <div style={styles.chatContainer}>
              <SidebarProfile openProfile={openProfile} page={page} />
            </div>
          ) : openConversation?.sid ? (
            <div style={styles.chatContainer}>
              <ChatConversation
                conversationSid={openConversation.sid}
                page={page}
              />
            </div>
          ) : (
            <div style={styles.chatContainer} />
          )}
          {/* {openProfile ? (
      <div style={styles.chatContainer}>
        {openProfile?.identity && (
          <SidebarProfile openProfile={openProfile} />
        )}
      </div>
    ) : (
      <div style={styles.chatContainer}>
        {openConversation?.sid && (
          <ChatConversation
            conversation={openConversation}
            sideBar={false}
          />
        )}
      </div>
    )} */}
          {/* <div style={styles.chatContainer}>
      
      {openConversation?.sid && (
        <ChatConversation conversation={openConversation} sideBar={false} />
       )}
     </div> */}
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid style={styles.container}>
        <div style={styles.mobileChatRoot}>
          <div style={{ ...styles.header, justifyContent: 'space-between' }}>
            <div
              style={{
                position: 'absolute',
                left: -25,
                top: -10,
              }}
            >
              {/* <ChatProfile identity={getTwilioUserIdentity(user)} size={60} /> */}
              <MyEngagementZoneProfile />
            </div>

            <div
              style={{
                marginLeft: 30,
                fontSize: 16,
                fontWeight: 'bold',
                marginRight: 8,
              }}
            >
              Chat
            </div>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              {/* <div
                style={{ margin: 8 }}
                onClick={() => {
                  // dispatch(setChatWindow(false));
                }}
              >
                <img src={setting} style={{ width: 18, height: 18 }} />
              </div> */}
              <Tooltip title="New Chat" placement="right">
                <div
                  style={{
                    margin: 8,
                    cursor: 'pointer',
                    width: 50,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    dispatch(setEngagement('newMessage'));
                  }}
                >
                  <SmsIcon
                    style={{
                      width: 25,
                      height: 25,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>

          {creatingConversation ? (
            <div
              style={{
                ...styles.mobileChatContainer,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
            >
              <CircularProgress />
            </div>
          ) : engagement === 'newMessage' ? (
            <div style={styles.mobileChatContainer}>
              <SidebarNewMessage
                setIsCreateNewMessage={setIsCreateNewMessage}
                page={page}
              />
            </div>
          ) : openProfile ? (
            <div style={styles.mobileChatContainer}>
              <SidebarProfile openProfile={openProfile} page={page} />
            </div>
          ) : openConversation?.sid ? (
            <div style={styles.chatContainer}>
              <ChatConversation
                conversationSid={openConversation.sid}
                page={page}
              />
            </div>
          ) : (
            <div style={styles.mobileChatContainer}>
              <div
                style={{
                  // overflowY: 'hidden',
                  // overflowX: 'hidden',
                  width: '100%',
                  height: '100%',

                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      boxShadow: '0 0 15px -7px #000',

                      // zIndex: 100000,
                    }}
                  >
                    <div
                      style={{
                        ...styles.tabs,
                        borderBottom: openTab === 0 ? '2px solid' : '0px solid',
                        color: openTab === 0 ? '#122857' : 'black',
                        fontWeight: openTab === 0 ? 'bold' : 'normal',
                        position: 'relative',
                      }}
                      onClick={() => {
                        setOpenTab(0);
                      }}
                    >
                      Contacts
                      {contactsUnreadCount > 0 && (
                        <div style={styles.redCircle} />
                      )}
                    </div>
                    <div
                      style={{
                        ...styles.tabs,
                        borderBottom: openTab === 1 ? '2px solid' : '0px solid',
                        color: openTab === 1 ? '#122857' : 'black',
                        fontWeight: openTab === 1 ? 'bold' : 'normal',
                        position: 'relative',
                      }}
                      onClick={() => {
                        setOpenTab(1);
                      }}
                    >
                      Following
                      {followingUnreadCount > 0 && (
                        <div style={styles.redCircle} />
                      )}
                    </div>
                    {isAttendee() && (
                      <div
                        style={{
                          ...styles.tabs,
                          borderBottom:
                            openTab === 2 ? '2px solid' : '0px solid',
                          color: openTab === 2 ? '#122857' : 'black',
                          fontWeight: openTab === 2 ? 'bold' : 'normal',
                        }}
                        onClick={() => {
                          setOpenTab(2);
                        }}
                      >
                        Directory
                      </div>
                    )}
                  </div>

                  {openTab === 0 &&
                    (directMessages?.length > 0 ? (
                      <div style={styles.chatRoom}>
                        {conversations &&
                          directMessages?.map((conversation) => (
                            <SidebarConversation
                              key={`conversation_sidebar_${conversation?.client?.sid}`}
                              conversation={conversation}
                              page={page}
                              isDirectMessage={true}
                            />
                          ))}
                      </div>
                    ) : (
                      <div style={styles.emptyRooms}>
                        You do not have any 1 to 1 connections yet. Reach out to
                        other users to start a conversation!
                      </div>
                    ))}
                  {openTab === 1 &&
                    (openedRooms?.length > 0 ? (
                      <div style={styles.chatRoom}>
                        {conversations &&
                          openedRooms?.map((conversation, idx) => (
                            <SidebarConversation
                              key={`group_conversation_sidebar_${conversation?.client?.sid}`}
                              conversation={conversation}
                              page={page}
                              isDirectMessage={false}
                            />
                          ))}
                      </div>
                    ) : (
                      <div style={styles.emptyRooms}>
                        You have not joined any ongoing discussions. Visit rooms
                        and join the conversation!
                      </div>
                    ))}
                  {openTab === 2 && (
                    <div style={styles.chatRoom}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <ChatDirectory page={page} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
}

const styles = {
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '2px solid green',
    width: '100%',
    backgroundColor: 'white',
    padding: 15,
    fontSize: 32,
    boxShadow: '0 0 15px -7px #000',
    // zIndex: 100000,
  },
  container: {
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // width: 600,
    // height: 600,
    // borderRadius: 50,
    // justifyContent: 'space-around',
    alignItems: 'center',
    // backgroundColor: 'red',
    // color: 'white',
    width: '100%',
    padding: 10,
    marginTop: 30,
    // border: '2px solid red',

    // boxShadow: '0 0 15px -7px #000',
    // zIndex: 100000,
    flexDirection: 'row',
    display: 'flex',

    // overflow: 'auto',
  },
  chatRoot: {
    display: 'flex',
    // position: 'fixed',
    // right: 10,
    // bottom: 60,
    // top: 150,
    height: 600,

    width: 300,
    minWidth: 300,

    backgroundColor: 'white',
    borderBottomLeftRadius: 5,
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    // border: '1px solid pink',

    boxShadow: '0 0 15px -7px #000',
    // zIndex: 100000,
  },
  mobileChatRoot: {
    display: 'flex',
    // height: 600,
    width: '100%',
    minWidth: 300,
    height: '75vh',
    backgroundColor: 'white',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,

    alignItems: 'center',
    flexDirection: 'column',

    boxShadow: '0 0 15px -7px #000',
  },
  header: {
    width: '100%',
    height: 50,
    backgroundColor: '#122857',
    color: 'white',
    display: 'flex',
    // justifyContent: 'center',
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    // borderTopLeftRadius: 20,
    // borderTopRightRadius: 20,
    position: 'relative',
  },
  tabs: {
    margin: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginRight: 15,
    marginBottom: 0,
    borderColor: '#122857',
    cursor: 'pointer',
    // height: 48,
  },
  roomBanner: {
    width: '100%',
    // backgroundColor: 'gray',
    // color: 'white',
    padding: 5,
    paddingLeft: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatRoom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
    // paddingLeft: 15,
    // paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    // border: '1px solid black',

    // borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0 0 15px -7px #000',
  },
  circle: {
    borderRadius: 50,
    width: 40,
    height: 40,
    backgroundColor: '#122857',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  chatContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    minWidth: 300,
    // maxWidth: window.innerWidth - 800,
    height: 600,
    backgroundColor: colors.backgroundGray,
    boxShadow: '0 0 15px -7px #000',
  },
  mobileChatContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '75vh',

    minWidth: 300,
    maxWidth: window.innerWidth - 800,
    // maxHeight: '65vh',
    backgroundColor: colors.backgroundGray,
    boxShadow: '0 0 15px -7px #000',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    overflow: 'hidden',
  },
  emptyRooms: {
    padding: 20,
  },
  redCircle: {
    width: 6,
    height: 6,
    borderRadius: 5,
    backgroundColor: 'red',
    position: 'absolute',
    top: 1,
    right: -1,
  },
};
