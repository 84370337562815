import React, { Component } from 'react';
import NavBar from '../Components/Navbar';
import Grid from '@material-ui/core/Grid';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReactMarkdown from 'react-markdown';
import DialogWrapper from '../Components/DialogWrapper';
import AttendeeList from './ListPages/AttendeeList';
import ExhibitorList from './ListPages/ExhibitorList';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Privacy from './Privacy';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  landingPage,
  // attendeeGuide,
  eventId,
  landingpageOnly,
  MerckSSOEnabled,
} from '../config';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  button: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    fontSize: '1rem',
  },
  tabs: {
    // display: 'inline-flex',
    display: 'flex',
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
      },
    },
  },
  tabSingle: {
    minWidth: 'auto',
  },
  mobileTabButton: {
    width: '100%',
    height: '100%',
  },
  mobileTabButtonSelected: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  gridBody: {
    marginTop: '60px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyOpen: false,
      pages: null,
      slug: null,
      pageContent: null,
      tabValue: 0,
      anchorEl: null,
      open: false,
      tabIndex: 0,
      homePage: false,
    };
  }

  async initialize(strapiProps, slug) {
    let pages = strapiProps;
    if (!strapiProps) {
      pages = this.props.strapi.strapi;
    }
    this.setPageContent(pages, slug);
  }

  setPageContent(pages, slug) {
    let convertedPathname = slug;
    // let convertedPathname = this.props.location.pathname.substring(1);
    let pageContent;
    let tabValue;
    let homePage = false;
    let userRoles = '';
    if (this?.props?.user?.user?.roles) {
      userRoles = this?.props?.user?.user?.roles.toLowerCase();
      userRoles = userRoles + ', authenticated';
    }
    if (this?.props?.user?.user) {
      // handle sso logged in but no show attendee
      userRoles = userRoles + ', authenticated';
    }
    if (pages) {
      pages.menu_items.forEach((item, index) => {
        if (index === 0) {
          // landing page // always set pageContent to this initially, will be overwritten below if conditions are met
          pageContent = item.pages[0].content;
          tabValue = index;
          homePage = true;
        }
        if (item.pages.length === 1) {
          // single item
          if (item.pages[0].slug === convertedPathname) {
            item.pages[0].visitor_roles.forEach((role) => {
              if (
                role.Role.toLowerCase() === 'public' ||
                userRoles.includes(role.Role.toLowerCase())
              ) {
                pageContent = item.pages[0].content;
                tabValue = index;
                homePage = false;
              }
            });
          }
        } else {
          // multiple items
          item.pages.forEach((page) => {
            if (page.slug === convertedPathname) {
              page.visitor_roles.forEach((role) => {
                if (
                  role.Role.toLowerCase() === 'public' ||
                  userRoles.includes(role.Role.toLowerCase())
                ) {
                  pageContent = page.content;
                  tabValue = index;
                  homePage = false;
                }
              });
            }
          });
        }
      });
    }

    let supplementalContent;
    if (slug.includes('attendee-list')) {
      supplementalContent = (
        <>
          <AttendeeList />
          <br />
          <br />
          <br />
        </>
      );
    }
    if (slug.includes('exhibitor-list')) {
      supplementalContent = (
        <>
          <ExhibitorList />
          <br />
          <br />
          <br />
        </>
      );
    }

    this.setState({
      pages,
      slug: convertedPathname,
      pageContent,
      tabValue,
      homePage,
      supplementalContent,
    });
  }

  async componentDidMount() {
    await axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    })
      .then((response) => {
        let formObject = response.data.data;
        let abstractSubmission = formObject.filter(
          (item) => item.type === 'abstract_submission'
        )[0];
        this.setState({ abstractAvailable: abstractSubmission.is_enabled });
      })
      .catch((error) => console.log(error));
    await this.initialize(null, this.props.location.pathname.substring(1));
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props?.strapi.strapi !== nextProps?.strapi.strapi) {
      this.initialize(
        nextProps?.strapi.strapi,
        nextProps.location.pathname.substring(1)
      );
      return true;
    }
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.initialize(
        nextProps?.strapi.strapi,
        nextProps.location.pathname.substring(1)
      );
      return true;
    } else {
      return false;
    }
  }

  handleChange(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  setTabIndex = (index) => {
    // used specifically with the dropdown menus. Is not the same as tabValue.
    this.setState({ tabIndex: index });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { pages } = this.state;

    let pageTabs = (
      <Tabs
        className={classes.tabs}
        value={this.state.tabValue}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        <Tab
          className={classes.tabSingle}
          label={'Event'}
          component={Link}
          to="/event"
        />
        {pages
          ? pages.menu_items.map((item, index) => {
              if (item.pages.length === 1) {
                // single item
                let isPublic = false; // public, no loggin necessary to view
                let isAuthenticated = false; // loggin necessary, any role can view
                let hasNecessaryRole = false; // role specific routes based on show attendee role
                item.pages[0].visitor_roles.forEach((role) => {
                  if (role.Role.toLowerCase() === 'public') {
                    isPublic = true;
                  }
                  if (role.Role.toLowerCase() === 'authenticated') {
                    isAuthenticated = true;
                  }
                  if (
                    this.props?.user?.user?.roles
                      ?.toLowerCase()
                      ?.includes(role.Role.toLowerCase())
                  ) {
                    hasNecessaryRole = true;
                  }
                });
                if (index === 0) {
                  // first item is landing page and that is handled elsewhere
                  return null;
                }
                if (isPublic || isAuthenticated || hasNecessaryRole) {
                  let linkToPage = `/${item.pages[0].slug}`;
                  if (item.pages[0].slug.includes('redirect')) {
                    // link to outside website
                    linkToPage = item.pages[0].content;
                    return (
                      <Tab
                        className={classes.tabSingle}
                        label={
                          item.pages[0].label
                            ? item.pages[0].label
                            : item.pages[0].title
                        }
                        href={linkToPage}
                      />
                    );
                  }
                  return (
                    <Tab
                      className={classes.tabSingle}
                      label={
                        item.pages[0].label
                          ? item.pages[0].label
                          : item.pages[0].title
                      }
                      component={Link}
                      to={`/${item.pages[0].slug}`}
                    />
                  );
                } else {
                  return (
                    <Tab
                      className={classes.tabSingle}
                      label=""
                      // component={Link}
                      // to={`/${item.pages[0].slug}`}
                      style={{ display: 'none' }}
                    />
                  );
                }
              } else {
                // multiple items
                let dropdownMenuItems = item.pages.map((page) => {
                  let isPublic = false; // public, no loggin necessary to view
                  let isAuthenticated = false; // loggin necessary, any role can view
                  let hasNecessaryRole = false; // role specific routes based on show attendee role
                  page.visitor_roles.forEach((role) => {
                    if (role.Role.toLowerCase() === 'public') {
                      isPublic = true;
                    }
                    if (role.Role.toLowerCase() === 'authenticated') {
                      isAuthenticated = true;
                    }
                    if (
                      this.props?.user?.user?.roles
                        ?.toLowerCase()
                        ?.includes(role.Role.toLowerCase())
                    ) {
                      hasNecessaryRole = true;
                    }
                  });
                  if (isPublic || isAuthenticated || hasNecessaryRole) {
                    return (
                      <MenuItem
                        onClick={this.handleClose}
                        component={Link}
                        to={`/${page.slug}`}
                      >
                        {page.label ? page.label : page.title}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                });
                return (
                  <div onClick={() => this.setTabIndex(index)}>
                    <Tab
                      className={classes.tabSingle}
                      label={item.label}
                      onClick={this.handleClick}
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open && this.state.tabIndex === index}
                      onClose={this.handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {dropdownMenuItems}
                    </Menu>
                  </div>
                );
              }
            })
          : null}
      </Tabs>
    );

    let mobilePageTabs = (
      // <Hidden smUp>
      <Grid container spacing={1}>
        {pages && (
          <Grid item xs={6}>
            <Button
              color={
                this.props.location.pathname === `/` ||
                this.props.location.pathname === `/event`
                  ? 'primary'
                  : 'default'
              }
              className={classes.mobileTabButton}
              variant="contained"
              disableElevation
              component={Link}
              to="/event"
              onClick={() => this.setState({ tabIndex: 0 })}
            >
              Event
            </Button>
          </Grid>
        )}
        {/* // iterate through pages
          // code */}
        {pages &&
          pages.menu_items.map((item, index) => {
            if (item.pages.length === 1) {
              // single item
              let isPublic = false; // public, no loggin necessary to view
              let isAuthenticated = false; // loggin necessary, any role can view
              let hasNecessaryRole = false; // role specific routes based on show attendee role
              item.pages[0].visitor_roles.forEach((role) => {
                if (role.Role.toLowerCase() === 'public') {
                  isPublic = true;
                }
                if (
                  role.Role.toLowerCase() === 'authenticated' &&
                  this.props.user?.user
                ) {
                  isAuthenticated = true;
                }
                if (
                  this.props?.user?.user?.roles
                    ?.toLowerCase()
                    ?.includes(role.Role.toLowerCase())
                ) {
                  hasNecessaryRole = true;
                }
              });
              if (index === 0) {
                // first item is landing page and that is handled elsewhere
                return null;
              }

              if (isPublic || isAuthenticated || hasNecessaryRole) {
                return (
                  <Grid item xs={6}>
                    <Button
                      color={
                        this.props.location.pathname ===
                        `/${item.pages[0].slug}`
                          ? 'primary'
                          : 'default'
                      }
                      className={classes.mobileTabButton}
                      variant="contained"
                      disableElevation
                      // onClick={() => this.handleTabChange(null, 1)}
                      component={Link}
                      to={`/${item.pages[0].slug}`}
                      onClick={() => this.setState({ tabIndex: 0 })}
                    >
                      {item.pages[0].label
                        ? item.pages[0].label
                        : item.pages[0].title}
                    </Button>
                  </Grid>
                );
              } else {
                return (
                  <Button
                    className={classes.mobileTabButton}
                    label=""
                    // component={Link}
                    // to={`/${item.pages[0].slug}`}
                    style={{ display: 'none' }}
                  />
                );
              }
            } else {
              // multiple items
              // const arrayOfSlugs = [];
              let dropdownMenuItems = item.pages.map((page) => {
                let isPublic = false; // public, no loggin necessary to view
                let isAuthenticated = false; // loggin necessary, any role can view
                let hasNecessaryRole = false; // role specific routes based on show attendee role
                page.visitor_roles.forEach((role) => {
                  if (role.Role.toLowerCase() === 'public') {
                    isPublic = true;
                  }
                  if (role.Role.toLowerCase() === 'authenticated') {
                    isAuthenticated = true;
                  }
                  if (
                    this.props?.user?.user?.roles
                      ?.toLowerCase()
                      ?.includes(role.Role.toLowerCase())
                  ) {
                    hasNecessaryRole = true;
                  }
                });
                if (isPublic || isAuthenticated || hasNecessaryRole) {
                  // arrayOfSlugs.push(`/${page.slug}`);
                  return (
                    <MenuItem
                      onClick={this.handleClose}
                      component={Link}
                      to={`/${page.slug}`}
                    >
                      {page.label ? page.label : page.title}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              });
              return (
                <Grid item xs={6}>
                  <Button
                    color={
                      this.state.tabIndex === index ? 'primary' : 'default'
                    }
                    className={classes.mobileTabButton}
                    variant="contained"
                    disableElevation
                    onClick={(event) => {
                      this.setTabIndex(index);
                      this.handleClick(event);
                    }}
                  >
                    {item.label}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open && this.state.tabIndex === index}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {dropdownMenuItems}
                  </Menu>
                </Grid>
              );
            }
          })}
        {/* // end */}
      </Grid>
      // </Hidden>
    );

    let defaultWelcome = (
      <>
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          style={{ margin: '16px 16px 0px 16px' }}
        >
          Welcome to the {this.props.event.event.title}.
        </Typography>
        <br />
        <br />
      </>
    );
    let additionalButtons;
    if (landingpageOnly) {
      if (this.props.user.user) {
        if (this.props.user.user === 'unregistered') {
          additionalButtons = (
            <>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={'/registration'}
                size="large"
              >
                {this.props.event.event.under_construction
                  ? 'Register for this Event'
                  : 'Enter the Virtual Environment'}
              </Button>
              <br />
              <br />
            </>
          );
        } else if (this.state.abstractAvailable) {
          additionalButtons = (
            <>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={'/abstractsubmission'}
                size="large"
              >
                Abstract Submission
              </Button>
              <br />
              <br />
            </>
          );
        }
      }
    } else {
      additionalButtons = (
        <>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={'/registration'}
            size="large"
          >
            {this.props.event.event.under_construction
              ? 'Register for this Event'
              : 'Enter the Virtual Environment'}
          </Button>
          <br />
          <br />
        </>
      );
    }

    let SSObutton = null;
    if (MerckSSOEnabled) {
      SSObutton = (
        <>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={'/authentication'}
            size="large"
          >
            Secure Login
          </Button>
          <br />
          <br />
        </>
      );
    }

    let howToVideoButton = (
      <>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="https://player.vimeo.com/video/715667005?h=e7e18c6349"
          target="_blank"
        >
          Engagement How-To
        </Button>
        <br />
        <br />
      </>
    );
    return (
      <div className={classes.root}>
        <NavBar />
        {/* {this.props.user?.user?.attendee_id && (
          <div style={{ marginTop: '-40px' }} />
        )} */}
        {/* revisit the above, has to do with spacing on 
        landing page when logged in*/}
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          className={classes.gridBody}
        >
          <Grid item xs={12}>
            <img
              src={
                this.props.event
                  ? this.props.event.event.banner_location
                  : landingPage.banner
              }
              alt="Banner Loading..."
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                position: 'relative',
                padding: 0,
                margin: 0,
              }}
            />
          </Grid>
          <Grid sm={10} xs={12}>
            <Hidden xsDown>
              {pages && this.props.user.user && !landingpageOnly && (
                <>{pageTabs}</>
              )}
            </Hidden>
            <Hidden smUp>{mobilePageTabs}</Hidden>
            <br />
            {!this.state.pages ? defaultWelcome : null}
            <Typography variant="body1">
              <ReactMarkdown>{this.state.pageContent}</ReactMarkdown>
            </Typography>
            {!this.state.homePage ? this.state.supplementalContent : null}
            {this.state.homePage & (this.state.slug !== 'event')
              ? SSObutton
              : null}
            {this.state.homePage ? additionalButtons : null}
            {/* {this.state.homePage && !this.props.user.user ? SSObutton : null} */}
            {!landingpageOnly & this.state.homePage ? howToVideoButton : null}
          </Grid>
        </Grid>
        <DialogWrapper
          open={this.state.privacyOpen}
          handleClose={(e) => this.setState({ privacyOpen: false })}
        >
          <Privacy />
          <Button
            className={classes.closeButton}
            variant="contained"
            onClick={(e) => this.handleChange(false, 'privacyOpen')}
          >
            Close
          </Button>
        </DialogWrapper>
        {!this.props.user.user && this.state.homePage ? (
          <Button
            className={classes.button}
            variant="contained"
            onClick={(e) => this.handleChange(true, 'privacyOpen')}
          >
            View{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            Privacy{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            Policy
          </Button>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    strapi: state.strapi,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(useStyles)(LandingPage)));
