export const attendeeGridOptions = [
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
  //   { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Organization',
  },
  { id: 'modality', numeric: false, disablePadding: false, label: 'Modality' },
];
