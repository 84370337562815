import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import ExhibitCard from './ExhibitCard';
import ExhibitBooth from './ExhibitBooth';
import { withRouter } from 'react-router';
import ExhibitListItem from './ExhibitListItem';
import Filter from './ExhibitFilter';
import Dialog from '../../Components/DialogWrapper';
import { eventId, aiEnabled } from '../../config';
import trackAction from '../../Store/Actions/tracker';
import isOnline from '../../Utilities/isOnline';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';
import { queryApi } from '../../Services/queryApi';
import { setSelectedExhibit } from '../../Store/Reducers/exhibit';

const useStyles = (theme) => ({
  gridContainer: {},
  gridCard: {
    maxWidth: '50%',
    width: '100%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class ExhibitionHall extends Component {
  // briefcaseSubscription;
  exhibitsSubscription;
  constructor(props) {
    super(props);
    this.state = {
      exhibitors: [],
      boothSelected: false,
      boothId: null,
      listFormat: false,
      filterString: '',
      filterCategory: 'account_name',
      filtering: false,
      pageCountStart: 1,
      perPage: 10,
      totalPages: 1,
      page: 1,
      aiFiltering: false,
      aiSuggestedExhibits: [],
    };
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.locationCheck();

    if (aiEnabled) {
      this.getAIExhibits();
    }

    this.exhibitsSubscription = this.props.getExhibits(eventId);
    if (this.props.event?.cacheTTL) {
      this.exhibitsSubscription.updateSubscriptionOptions({
        refetchOnMountOrArgChange: this.props.event.cacheTTL,
      });
    }

    // this.briefcaseSubscription = this.props.getBriefcase(eventId);

    const event = {
      action: 'Viewed',
      resource_type: 'ExhibitHall',
      resource_id: null,
      url_visited: '/exhibithall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
    this.scrollToTop();
  }

  getAIExhibits() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/suggestions/exhibits/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            this.setState({
              aiSuggestedExhibits: response.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleAIFilter = () => {
    this.setState({
      aiFiltering: !this.state.aiFiltering,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props?.location?.search !== nextProps?.location?.search) {
      this.locationCheck(nextProps);

      return true;
    }
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    }
    return true;
  }

  componentWillUnmount() {
    // if (this.briefcaseSubscription) this.briefcaseSubscription.unsubscribe();
    if (this.exhibitsSubscription) this.exhibitsSubscription.unsubscribe();
  }

  locationCheck(optionalProps) {
    let myProps = optionalProps ? optionalProps : this.props;
    if (myProps.location.search) {
      const query = new URLSearchParams(myProps.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'exhibition_id') {
          this.setState({
            boothSelected: true,
            boothId: param[1],
          });
        }
        if (param[0] === 'product_id') {
          this.props
            .getProductBoothId(param[1])
            .then((response) => {
              this.setState({
                boothSelected: true,
                boothId: response.data[0].exhibition_id,
              });
            })
            .catch((error) => {
              if (myProps.debug.debug) {
                alert(`Error: Could not display Exhibit Hall. ${error}`);
              }
            });
        }
      }
    }
  }

  toggleBoothFullscreenOn = (event, id, company) => {
    this.setState({
      boothSelected: true,
      boothId: id,
    });
    this.props.setSelectedExhibit(id);
  };

  toggleBoothFullscreenOff = () => {
    this.setState({
      boothSelected: false,
      boothId: null,
    });
    this.props.setSelectedExhibit(null);
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - 9,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (string, category) => {
    this.setState({
      filterString: string,
      filterCategory: category,
      filtering: true,
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
      filtering: false,
    });
  };

  render() {
    console.log('rendering exhibit hall');

    const { classes, exhibits } = this.props;

    const allExhibits = exhibits?.data?.data?.map((exhibit) => ({
      ...exhibit,
    }));

    let exhibitor = allExhibits?.filter(
      (exhibit) => exhibit.type !== 'Digital Lab'
    );

    let authorizedExhibitors = [];

    for (let i = 0; i < exhibitor?.length; i++) {
      if (exhibitor[i]?.is_public) {
        authorizedExhibitors.push(exhibitor[i]);
      } else {
        if (
          this.props.user.user.roles.includes('Attendee') ||
          this.props.user.user.roles.includes('Pharma Attendee')
        ) {
          authorizedExhibitors.push(exhibitor[i]);
        }
      }
    }

    authorizedExhibitors.sort(function (a, b) {
      let levelA = a.level_requested;
      let levelB = b.level_requested;
      if (levelA === 'Event Host' && levelB !== 'Event Host') {
        return 1;
      }
      if (levelA !== 'Event Host' && levelB === 'Event Host') {
        return -1;
      }
      return 0;
    });

    authorizedExhibitors.forEach((exhibit) => {
      if (exhibit.type === 'Internal') {
        exhibit.sortByNumber = 2.5;
      } else {
        exhibit.sortByNumber = parseInt(exhibit.booth_type);
      }
    });

    authorizedExhibitors.sort(function (a, b) {
      return a.sortByNumber - b.sortByNumber;
    });

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/exhibithall') {
        pageTitle = route.title;
      }
    });

    let pageBody = null;
    let exhibitors;

    if (this.state.aiFiltering) {
      exhibitors = authorizedExhibitors.filter((exhibit) => {
        let exhibitionId = exhibit.exhibition_id.toString();

        if (this.state.aiSuggestedExhibits.includes(exhibitionId)) {
          return true;
        } else {
          return false;
        }
      });
    } else if (this.state.filterString && this.state.filterCategory) {
      exhibitors = authorizedExhibitors.filter((exhibit) => {
        if (exhibit[this.state.filterCategory]) {
          return exhibit[this.state.filterCategory]
            .toLowerCase()
            .includes(this.state.filterString.toLowerCase());
        } else {
          return false;
        }
      });
    } else {
      exhibitors = authorizedExhibitors;
    }

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Filter
          handleGridListToggle={this.handleGridListToggle.bind(this)}
          handleRemoveFilter={this.handleRemoveFilter.bind(this)}
          handleFilterClick={this.handleFilterClick.bind(this)}
          listFormat={this.state.listFormat}
          filterString={this.state.filterString}
          filterCategory={this.state.filterCategory}
          filtering={this.state.filtering}
          handleAIFilter={this.handleAIFilter.bind(this)}
          aiFiltering={this.state.aiFiltering}
          aiSuggestedExhibits={this.state.aiSuggestedExhibits}
        />
        {this.state.filtering && exhibitors.length === 0 && noResultsFound}
      </Grid>
    );

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
          style={{ marginBottom: '40px' }}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
            handleAIFilter={this.handleAIFilter.bind(this)}
            aiFiltering={this.state.aiFiltering}
            aiSuggestedExhibits={this.state.aiSuggestedExhibits}
          />
          <Grid item xs={12}>
            {exhibitors.map((exhibit, index) => {
              return (
                <ExhibitListItem
                  key={index}
                  index={index}
                  exhibit={exhibit}
                  toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                    this
                  )}
                />
              );
            })}
            {this.state.filtering && exhibitors.length === 0 && noResultsFound}
          </Grid>
          <Dialog
            open={this.state.boothSelected}
            handleClose={this.toggleBoothFullscreenOff.bind(this)}
            scroll="body"
          >
            <ExhibitBooth
              boothId={this.state.boothId}
              closeBooth={this.toggleBoothFullscreenOff.bind(this)}
            />
          </Dialog>
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          alignItems="stretch"
          spacing={2}
          style={{ marginBottom: '40px' }}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
            handleAIFilter={this.handleAIFilter.bind(this)}
            aiFiltering={this.state.aiFiltering}
            aiSuggestedExhibits={this.state.aiSuggestedExhibits}
          />
          {exhibitors.map((exhibit, index) => {
            switch (exhibit.booth_type) {
              case '1':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                    style={{ width: '50%' }}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              case '2':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                    style={{ width: '50%' }}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              case '3':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                    style={{ width: '50%' }}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                    style={{ width: '50%' }}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
            }
          })}
          {this.state.filtering && exhibitors.length === 0 && noResultsFound}
          <Dialog
            open={this.state.boothSelected}
            handleClose={this.toggleBoothFullscreenOff.bind(this)}
            scroll="body"
          >
            {this.props.online ? (
              <ExhibitBooth
                boothId={this.state.boothId}
                closeBooth={this.toggleBoothFullscreenOff.bind(this)}
              />
            ) : (
              <Grid style={{ height: '100%' }}>
                <Grid item xs={12} sm={2} align="left">
                  <Button onClick={this.toggleBoothFullscreenOff.bind(this)}>
                    <ChevronLeftIcon /> Back
                  </Button>
                </Grid>
                <Grid
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '90%', display: 'flex' }}
                >
                  <Typography>
                    User is Offline. Full detail will be available online.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Dialog>
        </Grid>
      );
    }

    let page = authorizedExhibitors.length > 0 ? pageBody : contentLoading;

    return page;
  }
}

function mapStateToProps(state, props) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    // briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
    // exhibits: queryApi.endpoints.getExhibits.select(eventId)(state),
    exhibits: state.queryApi.queries?.[`getExhibits("${eventId}")`],
    online: isOnline(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      getExhibits: (eventId) =>
        queryApi.endpoints.getExhibits.initiate(eventId),
      setSelectedExhibit: (bootId) => dispatch(setSelectedExhibit(bootId)),
      getProductBoothId: (productId) =>
        queryApi.endpoints.getProductBoothId.initiate(productId),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ExhibitionHall)));
