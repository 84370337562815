import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// Configure Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './Store/store';

import EventLoader from './Components/EventLoader';

// Configure Amplify
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

// Main configuration
import { endpoint } from './config';
import ReloadPrompt from './ReloadPrompt';

aws_exports.oauth.redirectSignIn =
  typeof window !== 'undefined'
    ? window.location.origin + '/registration'
    : 'http://localhost:3000/';
aws_exports.oauth.redirectSignOut =
  typeof window !== 'undefined'
    ? window.location.origin + '/registration'
    : 'http://localhost:3000/';

Amplify.configure(aws_exports);

// Configure Axios
if (import.meta.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:5000';
} else if (import.meta.env.REACT_APP_ENVIRONMENT === 'dev') {
  axios.defaults.baseURL = 'http://dev.planetconnect.com';
} else {
  axios.defaults.baseURL = endpoint;
}

axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// GLobal Catch for All Outgoing Requests
axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log('interceptor error: OUTGOING', error);
    return Promise.reject(error);
  }
);

// Global Catch for All Incoming Responses
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('interceptor error: INCOMING', error);
    return Promise.reject(error);
  }
);

// TEMP: Remove Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      console.log('SW: removing');
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}

const providerProps = { store };

ReactDOM.render(
  <>
    <Provider {...providerProps}>
      <PersistGate loading={null} persistor={persistor}>
        <EventLoader />
      </PersistGate>
    </Provider>
    <ReloadPrompt />
  </>,
  document.getElementById('root')
);
// serviceWorker.unregister();
