import { queryApi } from '../Services/queryApi';
import { eventId, CLEAR_CACHE_AFTER } from '../config';
import {
  getClearCacheAfter,
  getUser,
  setClearCacheAfter,
} from '../Store/Reducers/user';
import setEventElements from '../Store/Actions/setEventElements';
import { useGetAgendasQuery } from '../Services/queryApi';
import Event from '../Types/Event';
import moment from 'moment';

export default function startupAsync(event: Event) {
  return async (dispatch, getState) => {
    console.log('startup async');
    const user = getUser(getState());
    const clearCacheAfter = getClearCacheAfter(getState());

    if (!clearCacheAfter || CLEAR_CACHE_AFTER > clearCacheAfter) {
      console.log(
        `forcing a cache clear for ${moment(CLEAR_CACHE_AFTER).format(
          'YYYY-MM-DD HH:mm:ss'
        )}`
      );
      await dispatch(
        queryApi.util.invalidateTags('Event', 'Briefcase', 'Exhibit', 'Poster')
      );
      dispatch(setClearCacheAfter(CLEAR_CACHE_AFTER));
    }

    if (user) {
      console.log('user exist');
      try {
        dispatch(
          queryApi.endpoints.getAgendas.initiate({
            eventId: eventId,
          })
        );

        dispatch(queryApi.endpoints.getPosters.initiate(eventId));

        dispatch(queryApi.endpoints.getExhibits.initiate(eventId));

        dispatch(queryApi.endpoints.getBriefcase.initiate(eventId));

        dispatch(queryApi.endpoints.getNetworking.initiate(eventId));
      } catch (e) {
        console.log('🚀 ~ file: StartupAsync.js ~ line 24 ~ return ~ e', e);
      }
    }
  };
}
